import { render, staticRenderFns } from "./reception-list-page.vue?vue&type=template&id=71fd7f58&"
import script from "./reception-list-page.vue?vue&type=script&lang=ts&"
export * from "./reception-list-page.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
installComponents(component, {VBreadcrumbs})
