












import { Component, Prop, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { ColorChipInfo } from "@/utils/grid/grid-helper";
import { ReceptionStatus } from "@/interfaces/reception";
import { ColorReceptionStatus } from "@/utils/color/reception-status";
import { ColorOrderStatus } from "@/utils/color/order-status";
import { ColorShippingOrderStatus } from "@/utils/color/shipping-order-status";

@Component({
  name: "grid-status-chip-component",
  components: { AgGridVue },
})
export default class GridStatusChipComponent extends Vue {
  @Prop() status: ReceptionStatus;

  statusInfo: ColorChipInfo | null = null;

  beforeMount() {
    if (this.$data.params.type) {
      switch (this.$data.params.type) {
        case "reception":
          this.statusInfo = ColorReceptionStatus.getColorFromCode(this.$data.params.value);
          break;
        case "order":
          this.statusInfo = ColorOrderStatus.getColorFromCode(this.$data.params.value);
          break;
        case "shipping-order":
          this.statusInfo = ColorShippingOrderStatus.getColorFromCode(this.$data.params.value);
          break;
        default:
          throw "GridStatusChipComponent - type params is incorrect";
      }
    } else {
      throw "GridStatusChipComponent - type params is required.";
    }
  }
}
