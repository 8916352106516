import { authApiProvider, InitPasswordBody } from "@/providers/auth-api-provider";
import router from "../router/router";
import store from "../stores/store";
import { trackingApiProvider, TrackingEvent } from "@/providers/tracking-api-provider";

export default abstract class AuthHelper {
  public static async connection(body: { email: string; password: string }) {
    const response: string = await authApiProvider.connection(body);
    if (response != null) {
      await this.launchApp(response);
      await trackingApiProvider.registerEvent({ type: "login" });
      document.location.reload();
    }
  }

  public static async loginAs(userId: string) {
    const response: string = await authApiProvider.loginAs(userId);
    if (response != null) {
      await router.replace("/dashboard").catch(() => {});
      await this.launchAppImpersonate(response);
      document.location.reload();
    }
  }

  public static async revertImpersonate() {
    await store.commit("auth/setApiTokenImpersonate", null);
    await store.dispatch("user/fetchUser");
    await store.dispatch("attribute/fetchAllAttribute");
    await store.dispatch("productFamily/fetchAllFamilies");
    document.location.reload();
  }

  public static async initPassword(body: {
    password: string;
    password_confirmation: string;
    email: string | (string | null)[];
    token: string | (string | null)[];
  }) {
    const response: string = await authApiProvider.setPassword(body);
    if (response != null) {
      await router.replace("/login");
    }
  }

  public static async resetPassword(body: InitPasswordBody) {
    const response: string = await authApiProvider.setPassword(body);
    if (response) {
      await router.replace("/login");
    }
  }

  private static async launchApp(token: string) {
    await store.dispatch("reset");
    await store.commit("auth/setApiToken", token);
    await store.dispatch("user/fetchUser");
    await store.dispatch("attribute/fetchAllAttribute");
    await store.dispatch("productFamily/fetchAllFamilies");
  }

  private static async launchAppImpersonate(tokenImpersonate: string) {
    await store.commit("auth/setApiTokenImpersonate", tokenImpersonate);
    await store.dispatch("user/fetchUser");
    await store.dispatch("attribute/fetchAllAttribute");
    await store.dispatch("productFamily/fetchAllFamilies");
  }

  public static async logout() {
    await store.dispatch("reset");
    await router.replace("/login");
  }
}
