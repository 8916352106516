import { ApiProvider, PaginatedResult } from "@/providers/api-provider";
import { StockMovement } from "@/interfaces/stock-movement";
import { ResourceProvider } from "@/interfaces/resource-provider";

class StockMovementApiProvider extends ApiProvider implements ResourceProvider {
  async exportAll(params: URLSearchParams, columnList: Array<string> = []) {
    params.append("output", "csv");

    // Bidouille pour mapper les colonnes du front sur les champs en back.
    if (columnList.indexOf("warehouse.name") != -1)
      columnList[columnList.indexOf("warehouse.name")] = "warehouse.code";

    await this.downloadFile(`/stock-movements?${params}&columns=${columnList.toString()}`);
  }

  async getAll(params: URLSearchParams): Promise<PaginatedResult<StockMovement>> {
    params.append(
      "include",
      "warehouse,stock,stock.product,stock.owner,segment,order,order.owner,reception,reception.supplier, order.recipient"
    );
    const response = await this.get(`/stock-movements?` + params);
    return new PaginatedResult<StockMovement>(response, StockMovement);
  }
}

export const stockMovementApiProvider = new StockMovementApiProvider();
