















import { Component, Vue } from "vue-property-decorator";

@Component({ name: "import-grid-footer" })
export default class ImportGridFooter extends Vue {}
