import { ApiProvider, PaginatedResult } from "@/providers/api-provider";
import { ShippingOrder } from "@/interfaces/shipping-order";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";
import { ResourceProvider } from "@/interfaces/resource-provider";
import store from "@/stores/store";
import { UserRole } from "@/interfaces/user";
import { i18n } from "@/main";

class ShippingOrderApiProvider extends ApiProvider implements ResourceProvider {
  defaults = "deliveryAddress,pickupAddress,warehouse";
  adminDefaults = "deliveryAddress,pickupAddress,warehouse,owner";

  async exportAll(params: URLSearchParams, columnList: Array<string> = []) {
    params.append("output", "csv");

    // Bidouille pour mapper les colonnes du front sur les champs en back.
    if (columnList.indexOf("warehouse.name") != -1)
      columnList[columnList.indexOf("warehouse.name")] = "warehouse.code";

    await this.downloadFile(`/shipping-orders?${params}&columns=${columnList.toString()}`);
  }

  async getAll(params: URLSearchParams): Promise<PaginatedResult<ShippingOrder>> {
    if (store.getters["user/getUser"].role == UserRole.superadmin) {
      params.append("include", this.adminDefaults);
    } else {
      params.append("include", this.defaults);
    }
    const response = await this.get(`/shipping-orders?` + params);
    return new PaginatedResult<ShippingOrder>(response, ShippingOrder);
  }

  async getShippingOrder(id: string): Promise<ShippingOrder | null> {
    const response = await this.get(`/shipping-orders/${id}?include=${this.defaults}`);
    return response ? new ShippingOrder(response.data) : null;
  }

  async updateShippingOrder(shipping: ShippingOrder): Promise<ShippingOrder | null> {
    const response = await this.put(`/shipping-orders/${shipping.id}`, shipping.encode());
    return response ? new ShippingOrder(response.data) : null;
  }

  async updateShippingOrderStatus(shipping: ShippingOrder): Promise<ShippingOrder | null> {
    const response = await this.put(`/shipping-orders/${shipping.id}`, shipping.encode(), [
      { text: i18n.t("message.status_updated").toString(), type: MessageType.success },
    ]);
    return response ? new ShippingOrder(response.data) : null;
  }

  async createShippingOrder(shipping: ShippingOrder): Promise<ShippingOrder | null> {
    const response = await this.post(`/shipping-orders?${this.defaults}`, shipping.encode());
    return response ? new ShippingOrder(response.data) : null;
  }
}

export const shippingOrderApiProvider = new ShippingOrderApiProvider();
