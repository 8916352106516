























import { Component, Vue } from "vue-property-decorator";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { productApiProvider } from "@/providers/product-api-provider";
import ProductDetailModal from "@/components/_shared/modals/product-detail-modal.vue";
import { DataSource } from "@/utils/grid/datasource";
import ProductGrid from "@/components/product/product-grid.vue";
import ConfirmModal from "@/components/_shared/modals/confirm-modal.vue";
import { Product } from "@/interfaces/product";

@Component({
  name: "product-list-page",
  components: { ConfirmModal, ProductGrid, AppIconTextButton, ProductDetailModal },
})
export default class ProductListPage extends Vue {
  datasource: DataSource;

  $refs: {
    productDetailModal: HTMLFormElement;
    productGrid: HTMLFormElement;
  };

  breadcrumbs = [{ text: this.$t("app.title") }, { text: this.$t("product.title") }];

  async beforeMount() {
    this.$route.meta.title = this.$t("product.list_page_title");
    this.datasource = DataSource.from(productApiProvider);
  }

  mounted() {
    this.$nextTick(() => {
      // Autocomplete search field on new creation.
      if (this.$route.params.search_filter && this.$refs.productGrid) {
        this.$refs.productGrid.$refs.searchComponent.search = this.$route.params.search_filter;
      }
    });
  }

  // Update product with hidden = true
  async hideProduct(product: Product) {
    product.hidden = true;
    await productApiProvider.updateProduct(product);
    this.$refs.productGrid.onFilterChange();
  }

  // Update product with hidden = false
  async showProduct(product: Product) {
    product.hidden = false;
    await productApiProvider.updateProduct(product);
    this.$refs.productGrid.onFilterChange();
  }
}
