import { CompanyCarrier } from "@/interfaces/company-carrier";
import { AppScope } from "@/utils/app-scope";
import { Address } from "./address";
import { Permissions } from "./permissions";
import { Segment } from "./segment";
import { Warehouse } from "./warehouse";

export interface Company {
  id: string;
  code: string;
  name: string;
  isPooledActivity: boolean;
  isTopAgent: boolean;
  isCertifiedOrganic: boolean;
  segments: Array<Segment>;
  address: Address;
  scope: Array<string>;

  carriers: Array<CompanyCarrier>;

  // Champ sur la table de permissions.
  warehouses: Warehouse[]; // Is same as permissions.warehouses: string<uuid>[]
  permissions: Permissions | undefined;
}

export class Company {
  constructor(data: Partial<Company> = {}) {
    Object.assign(this, data);
    this.address = data.address ? new Address(data.address) : new Address();
    this.warehouses = data.warehouses ? data.warehouses.map((w: object) => new Warehouse(w)) : [];
    this.segments = data.segments ? data.segments.map((w: object) => new Segment(w)) : [];
    this.carriers = data.carriers ? data.carriers.map((w: object) => new CompanyCarrier(w)) : [];
    this.permissions = data.permissions ? new Permissions(data.permissions) : undefined;
    if (data.scope && data.scope.findIndex((v: string) => v == "*") != -1) {
      this.scope = AppScope.values;
    }
  }

  encode(): Company {
    const data: any = { ...this };
    data.warehouses = Warehouse.listToArrayId(data.warehouses);
    data.carriers = data.carriers.map((c) => c.encode());
    data.segments = data.segments.map((s) => s.id);
    return data;
  }
}
