






















import { Component, Vue } from "vue-property-decorator";
import AppHeader from "@/components/_shared/template/app-header.vue";
import AppDrawer from "@/components/_shared/template/app-drawer.vue";
import AppFooter from "@/components/_shared/template/app-footer.vue";
import AppSnackbar, { ShowSnackbarParams } from "@/components/_shared/template/app-snackbar.vue";

@Component({
  name: "app",
  components: { AppHeader, AppDrawer, AppSnackbar, AppFooter },
})
export default class App extends Vue {
  isMenu = false;
  isDivider = true;

  $refs: {
    snackbar: HTMLFormElement;
    fullscreen: HTMLFormElement;
  };

  onShowSnackbar(params: ShowSnackbarParams) {
    if (this.$refs.snackbar) {
      this.$refs.snackbar.showSnackbar(params);
    }
  }

  mounted() {
    this.$eventHub.$on("toggle-menu", (value: boolean) => (this.isMenu = value));
    this.$eventHub.$on("toggle-fullscreen", () => this.$refs.fullscreen.toggle());
    this.$eventHub.$on("show-snackbar", (params: ShowSnackbarParams) =>
      this.onShowSnackbar(params)
    );
    this.$eventHub.$on("toggle-title-divider", (value: boolean) => (this.isDivider = value));
  }

  beforeDestroy() {
    this.$eventHub.$off("toggle-menu");
    this.$eventHub.$off("toggle-fullscreen");
    this.$eventHub.$off("show-snackbar");
    this.$eventHub.$off("toggle-title-divider");
  }
}
