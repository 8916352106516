









import { Component, Vue } from "vue-property-decorator";

@Component({ name: "privacy-policy-page" })
export default class PrivacyPolicyPage extends Vue {
  async beforeMount() {
    this.$route.meta.title = this.$t("privacy.title");
  }
}
