























































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import { Dashboard } from "@/interfaces/dashboard";
import { dashboardApiProvider } from "@/providers/dashboard-api-provider";
import KpiCard from "@/components/_shared/cards/kpi-card.vue";
import store from "../stores/store";
import { ColorChipInfo } from "@/utils/grid/grid-helper";
import { ColorShippingOrderStatus } from "@/utils/color/shipping-order-status";
import { ColorOrderStatus } from "@/utils/color/order-status";
import { Order, OrderStatus } from "@/interfaces/order";
import { Reception, ReceptionStatus } from "@/interfaces/reception";
import { ColorReceptionStatus } from "@/utils/color/reception-status";
import { ShippingOrder } from "@/interfaces/shipping-order";
import { StockMovement, StockMovementType } from "@/interfaces/stock-movement";
import { User } from "@/interfaces/user";

@Component({
  name: "dashboard-page",
  computed: {
    OrderStatus() {
      return OrderStatus;
    },
  },
  components: { KpiCard },
})
export default class DashboardPage extends Vue {
  i: Dashboard | null = null;

  private year = moment().year();
  private date = moment().format("L");
  private locale = store.getters["user/getLocale"];
  private isLoading = true;

  user = Object.assign(new User({}), store.getters["user/getUser"]);

  async beforeMount() {
    this.$route.meta.title = this.$t("dashboard.title");
    this.i = await dashboardApiProvider.getDashboardInformations();
    this.isLoading = false;
  }

  get dateFilterParams() {
    return [
      moment().startOf("year").format("YYYY-MM-DD"),
      moment().endOf("year").format("YYYY-MM-DD"),
    ];
  }

  get receptionStatusFilterParams() {
    return [ReceptionStatus.closed, ReceptionStatus.created, ReceptionStatus.inProgress];
  }

  orderColorStatus(order: Order): ColorChipInfo | undefined {
    if (order.statusCode) {
      return ColorOrderStatus.getColorFromCode(order.statusCode);
    }
  }

  receptionColorStatus(reception: Reception): ColorChipInfo | undefined {
    if (reception.statusCode) {
      return ColorReceptionStatus.getColorFromCode(reception.statusCode);
    }
  }

  shippingOrderColorStatus(shippingOrder: ShippingOrder): ColorChipInfo | undefined {
    if (shippingOrder.statusCode) {
      return ColorShippingOrderStatus.getColorFromCode(shippingOrder.statusCode);
    }
  }

  orderIsEditable(order: Order): boolean | undefined {
    if (order.statusCode) {
      return order.statusCode == OrderStatus.draft || order.statusCode == OrderStatus.entry;
    }
  }

  receptionIsEditable(reception: Reception): boolean | undefined {
    if (reception.statusCode) {
      return reception.statusCode == ReceptionStatus.draft;
    }
  }

  movementIsOutput(movement: StockMovement): boolean | undefined {
    return movement.direction == StockMovementType.output;
  }
}
