import { ApiProvider } from "@/providers/api-provider";

class ActivityLogApiProvider extends ApiProvider {
  async getData(): Promise<[] | null> {
    const response = await this.get(`/activity-logs`);
    return response.data;
  }
}

export const activityLogApiProvider = new ActivityLogApiProvider();
