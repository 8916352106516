






import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";

@Component({
  name: "grid-movement-icon-component",
  components: { AgGridVue },
})
export default class GridMovementIconComponent extends Vue {
  value: boolean;

  beforeMount() {
    this.value = this.$data.params.value;
  }
}
