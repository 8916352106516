import { Product } from "@/interfaces/product";
import { ApiProvider, PaginatedResult } from "@/providers/api-provider";
import { ResourceProvider } from "@/interfaces/resource-provider";

class ProductApiProvider extends ApiProvider implements ResourceProvider {
  defaults =
    "supplier.address, attributes,family,warehouse,stocks,stocks.warehouse,stocks.segment,owner";

  async exportAll(params: URLSearchParams, columnList: Array<string> = []) {
    params.append("output", "csv");

    // Bidouille pour mapper les colonnes du front sur les champs en back.
    if (columnList.indexOf("warehouse.name") != -1)
      columnList[columnList.indexOf("warehouse.name")] = "warehouse.code";
    if (columnList.indexOf("family.uuid") != -1)
      columnList[columnList.indexOf("family.uuid")] = "family.name";
    if (columnList.indexOf("family.family.uuid") != -1)
      columnList[columnList.indexOf("family.family.uuid")] = "family.family.name";

    await this.downloadFile(`/products?${params}&columns=${columnList.toString()}`);
  }

  async getAll(params: URLSearchParams): Promise<PaginatedResult<Product>> {
    params.append("include", this.defaults);
    const response = await this.get(`/products?${params}`);
    return new PaginatedResult<Product>(response, Product);
  }

  async getProductById(productId: string): Promise<Product | null> {
    const params = new URLSearchParams();
    params.append("include", this.defaults);
    const response = await this.get(`/products/${productId}?${params}`);
    return response ? new Product(response.data) : null;
  }

  async getAllProduct(q?: string): Promise<Array<Product>> {
    let query = "";
    if (q) {
      query += `&q=${q}`;
    }
    const response = await this.get(`/products?include=${this.defaults}${query}`);
    return response ? response.data.map((e: object) => new Product(e)) : [];
  }

  async getProductByWarehouse(uuid: string): Promise<Array<Product>> {
    const response = await this.get(
      `/products?include=${this.defaults}&filter[warehouse.uuid]=${uuid}`
    );
    return response ? response.data.map((e: object) => new Product(e)) : [];
  }

  async createProduct(newProduct: Product): Promise<Product | null> {
    const response = await this.post(`/products?include=${this.defaults}`, newProduct.encode());
    return response ? new Product(response.data) : null;
  }

  async updateProduct(newProduct: Product): Promise<Product | null> {
    const response = await this.put(
      `/products/${newProduct.id}?include=${this.defaults}`,
      newProduct.encode()
    );
    return response ? new Product(response.data) : null;
  }
}

export const productApiProvider = new ProductApiProvider();
