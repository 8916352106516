import { ApiProvider } from "@/providers/api-provider";
import { Family } from "@/interfaces/attributes/family";

class FamilyApiProvider extends ApiProvider {
  async getAllFamilies(): Promise<Array<Family>> {
    const response = await this.get(
      "/product-families?include=families&sort=name&filter[owner_id][eq]=NULL"
    );
    return response ? response.data.map((e: object) => new Family(e)) : [];
  }
}

export const familyApiProvider = new FamilyApiProvider();
