































import { Component, Prop, Vue } from "vue-property-decorator";
import { Product } from "@/interfaces/product";
import GridColorChipComponent from "@/components/_shared/grid-components/grid-cells/grid-color-chip-component.vue";

@Component({
  name: "product-card",
  components: { GridColorChipComponent },
})
export default class ProductCard extends Vue {
  @Prop() product: Product;
}
