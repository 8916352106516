




































import { Component, Mixins, Prop } from "vue-property-decorator";
import ModalComponent from "../_shared/modals/modal-component.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import ProductCard from "@/components/_shared/cards/product-card.vue";
import store from "../../stores/store";
import router from "@/router/router";
import { receptionApiProvider } from "@/providers/reception-api-provider";

@Component({
  name: "new-reception-modal",
  components: { ModalComponent, AppIconTextButton, ProductCard },
})
export default class NewReceptionModal extends Mixins(ModalComponent) {
  nbProducts = 0;

  async open() {
    const response = await receptionApiProvider.getReception(
      store.getters["createReception/getCurrentReceptionId"]
    );
    if (response) {
      this.nbProducts = response.stocks.length;
      this.toggleModal(true);
    } else {
      this.onClickNew();
    }
  }

  async onClickContinue() {
    this.toggleModal(false);
    await router.push("/reception/create");
  }

  async onClickNew() {
    this.toggleModal(false);
    await store.dispatch("createReception/reset");
    await router.push("/reception/create");
  }
}
