














import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AppScope } from "@/utils/app-scope";

@Component({
  name: "prestation-form-component",
})
export default class PrestationFormComponent extends Vue {
  @Prop() modules: Array<AppScope>;

  @Prop() value: Array<AppScope>;

  localValue: Array<AppScope>;

  beforeMount() {
    this.localValue = this.value;
  }

  @Watch("value")
  onValueChange(value: Array<AppScope>) {
    this.localValue = value;
  }

  /**
   * Update the parent.
   */
  onUpdate() {
    this.$emit("input", this.localValue);
  }

  /**
   * Un item est affichable si sa catégorie est dans les modules en entrée.
   */
  get items() {
    const result = [];
    this.itemsList.forEach((item: any) => {
      const newItem = Object.assign({}, item);
      // If the item have children, we filter them and add the item.
      if (newItem.children) {
        newItem.children = newItem.children.filter((child) => {
          return this.modules.includes(child.id);
        });
        if (newItem.children.length > 0) {
          result.push(newItem);
        }
      } else {
        if (this.modules.includes(newItem.id)) {
          result.push(newItem);
        }
      }
    });
    console.log(result);
    return result;
  }

  /**
   * L'ensemble des items qui sont affichable dans l'arbre.
   */
  itemsList = [
    {
      id: 1,
      name: this.$t("reception.title"),
      children: [
        { id: AppScope.product, name: this.$t("product.name") },
        { id: AppScope.supplier, name: this.$t("supplier.name") },
        { id: AppScope.reception, name: this.$t("reception.title") },
      ],
    },
    {
      id: 2,
      name: this.$t("order.title"),
      children: [
        { id: AppScope.recipient, name: this.$t("recipient.name") },
        { id: AppScope.order, name: this.$t("order.title") },
      ],
    },
    {
      id: 3,
      name: this.$t("shipping-order.title"),
      children: [
        { id: AppScope.transportation, name: this.$t("shipping-order.title") },
        { id: AppScope.carrier, name: this.$t("carrier.name") },
      ],
    },
    {
      id: 4,
      name: this.$t("stock.title"),
      children: [
        { id: AppScope.stock, name: this.$t("stock.title") },
        { id: AppScope.stockMovement, name: this.$t("stock-movement.title") },
      ],
    },
    {
      id: AppScope.production,
      name: this.$t("production.title"),
    },
  ];
}
