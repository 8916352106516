import { Address } from "@/interfaces/address";
import { i18n } from "@/main";
import { Contact } from "./contact";
import { Warehouse } from "./warehouse";
import { Company } from "@/interfaces/company";
import { Segment } from "@/interfaces/segment";

// @TODO : Change with the real status value.
export enum ShippingOrderStatus {
  draft = "000",
  created = "001",
  planified = "100",
  delivered = "200",
}

export interface ShippingOrder {
  id: string;
  code: string;
  reference: string;
  type: string;
  statusCode: ShippingOrderStatus | null;
  sender: Contact | null;
  owner: Company | null;
  pickupAddress: Address | null;
  recipient: Contact | null;
  deliveryAddress: Address | null;
  palletCount: number;
  parcelCount: number;
  unitCount: number;
  weight: number;
  pickupDate: string;
  deliveryDate: string;
  deliveredAt: string;
  pickedupAt: string;
  createdAt: string;
  tracking: string;

  warehouse: Warehouse;
  segment: Segment;
}

export class ShippingOrder {
  constructor(data: Partial<ShippingOrder> = {}) {
    Object.assign(this, data);
    this.statusCode = data.statusCode ? ShippingOrder.statusFromCode(data.statusCode) : null;
    this.pickupAddress = data.pickupAddress ? new Address(data.pickupAddress) : new Address();
    this.deliveryAddress = data.deliveryAddress ? new Address(data.deliveryAddress) : new Address();
    this.sender = data.sender ? new Contact(data.sender) : null;
    this.owner = data.owner ? new Company(data.owner) : null;
    this.recipient = data.recipient ? new Contact(data.recipient) : null;
    this.segment = data.segment ? new Segment(data.segment) : null;
    if (data.warehouse) {
      this.warehouse = new Warehouse(data.warehouse);
    }
  }

  encode(): ShippingOrder {
    const data: any = { ...this };
    data.weight = this.unitCount * 1.3;
    data.warehouse = this.warehouse?.id;
    data.sender = this.sender?.id;
    data.owner = this.owner?.id;
    data.segment = this.segment?.id;
    data.recipient = this.recipient?.id;
    return data;
  }

  /*
    @TODO: Change with the real value.
     */
  static statusFromCode(statusCode: string): ShippingOrderStatus | null {
    switch (statusCode) {
      case "000":
        return ShippingOrderStatus.draft;
      case "001":
        return ShippingOrderStatus.created;
      case "100":
        return ShippingOrderStatus.planified;
      case "200":
        return ShippingOrderStatus.delivered;
      default:
        return null;
    }
  }

  /*
    @TODO: Change with the real value.
     */
  static stringFromStatus(statusCode: ShippingOrderStatus): string {
    switch (statusCode) {
      case ShippingOrderStatus.draft:
        return i18n.t("shipping-order.status.000").toString();
      case ShippingOrderStatus.created:
        return i18n.t("shipping-order.status.001").toString();
      case ShippingOrderStatus.planified:
        return i18n.t("shipping-order.status.100").toString();
      case ShippingOrderStatus.delivered:
        return i18n.t("shipping-order.status.200").toString();
      default:
        return "";
    }
  }

  static getAllStatus() {
    return Object.values(ShippingOrderStatus);
  }
}
