








































































import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import { Grid } from "@/utils/grid/grid-helper";
import GridListComponent from "../_shared/grid-components/grid-cells/grid-list-component.vue";
import { PreferencesList } from "@/interfaces/preference";
import CommonSearchComponent from "../_shared/searchs/common-search-component.vue";
import GridRightIconsComponent from "../_shared/grid-components/grid-right-icons-component.vue";
import { Company } from "@/interfaces/company";
import { Warehouse } from "@/interfaces/warehouse";
import ImportCsvModal from "../_shared/modals/import-csv-modal.vue";

@Component({
  name: "client-company-grid",
  components: {
    AgGridVue,
    GridIconRowComponent,
    ImportCsvModal,
    GridListComponent,
    GridRightIconsComponent,
    CommonSearchComponent,
  },
})
export default class CompanyGrid extends Grid {
  @Prop() rowData: Array<Company>;

  async beforeMount() {
    super.beforeMount();
    this.gridOptions.getRowClass = function (params: any) {
      if (params.data && !params.data.active) {
        return "kDartessRed25";
      }
    };
  }

  defaultPreference = {
    slug: PreferencesList.company,
    columns: ["code", "name", "address.displayAddress", "warehouses"],
  };

  columns = [
    { headerName: this.$t("form.code"), field: "code", cellClass: "cell-align-left" },
    { headerName: this.$t("form.company"), field: "name", cellClass: "cell-align-left" },
    {
      headerName: this.$t("form.address"),
      field: "address.displayAddress",
      cellClass: "cell-align-left",
      width: 150,
      minWidth: 150,
      sortable: true,
      valueFormatter: function (params: any) {
        if (params.data) {
          const d = params.data.address;
          return `${d.street}, ${d.postcode} ${d.city}`;
        }
      },
    },
    {
      headerName: this.$tc("form.warehouse", 2),
      field: "warehouses",
      minWidth: 300,
      width: 300,
      sortable: false,
      autoHeight: true,
      cellClass: "cell-align-company",
      filterFramework: null,
      cellRendererFramework: "GridListComponent",
      valueGetter: function (params: any) {
        return {
          values: params.data?.warehouses
            ? params.data?.warehouses.map((e: Warehouse) => e.name)
            : [],
          icon: "mdi-warehouse",
          withIcon: true,
          justify: "start",
          maxShowed: 3,
        };
      },
    },
    /*{
            headerName: this.$tc('form.segment', 2), field: 'segments',
            maxWidth: 200, minWidth: 150, width: 150,
            sortable: false, autoHeight: true, cellClass: "cell-align-company",
            filterFramework: null,
            cellRendererFramework: 'GridListComponent',
            valueGetter: function (params: any) {
                return {
                    values: params.data?.segments ? params.data?.segments.map((e: Warehouse) => e.name) : [],
                    withIcon: true, justify: "start", maxShowed: 3, icon: 'mdi-label'
                };
            },
        },*/
    {
      cellRendererFramework: "GridIconRowComponent",
      pinned: "right",
      minWidth: 80,
      width: 80,
      maxWidth: 80,
      cellClass: "cell-align-company",
      sortable: false,
      filterFramework: null,
      suppressMovable: true,
      valueGetter: function (params: any) {
        return {
          id: params.data?.id,
          active: [true, true],
        };
      },
      cellRendererParams: {
        icons: [
          { icon: "mdi-account-multiple", to: "/client-user/?/list" },
          { icon: "mdi-pen", event: "open-company-detail-modal" },
        ],
      },
    },
  ];
}
