export interface Attribute {
  id: string;
  path: string;
  label: string;
  type: string;
  unit: string | null;
  default: string;
  regex: string | null;
  rules: any;
  required: boolean;
  dependencies: string | null;
  values: AttributeValues[] | null;
}

export class Attribute {
  constructor(data: Partial<Attribute>) {
    Object.assign(this, data);
  }

  get isSelect() {
    return this.type == "select" && this.values !== null && this.values.length > 0;
  }
}

export interface AttributeValues {
  label: string;
  code: string;
  rules: any;
}

export interface AttributeGroup {
  id: string;
  code: string;
  label: string;
  attributes: Array<Attribute>;
}

export enum AttributeGroupType {
  alcohol = "alcohol",
  contacts = "contacts",
  product = "products",
  user = "users",
  company = "companies",
}

export class AttributeGroup {
  constructor(data: Partial<AttributeGroup>) {
    Object.assign(this, data);
    this.attributes = data.attributes
      ? data.attributes.map((att: Attribute) => new Attribute(att))
      : new Array<Attribute>();
  }
}

export enum GoverningRightType {
  R = "R",
  A = "A",
}
