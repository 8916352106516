import CreateContactPage from "../pages/contact/create-contact-page.vue";
import { AttributeGroupType } from "@/interfaces/attributes/attribute";
import ContactListPage from "../pages/contact/contact-list-page.vue";
import { AppScope } from "@/utils/app-scope";
import ImportContactCsvPage from "@/pages/contact/import-contact-csv-page.vue";

const contactRoutes = [
  {
    path: "/supplier/list",
    alias: "/supplier",
    name: "supplier-list",
    component: ContactListPage,
    props: {
      type: "supplier",
    },
    meta: {
      access: 1,
      scope: AppScope.supplier,
    },
  },
  {
    path: "/supplier/create",
    name: "create-supplier",
    component: CreateContactPage,
    props: {
      type: "supplier",
    },
    meta: {
      access: 1,
      scope: AppScope.supplier,
    },
  },
  {
    path: "/supplier/import",
    name: "import-supplier",
    component: ImportContactCsvPage,
    props: {
      type: "supplier",
      attributeGroup: AttributeGroupType.contacts,
    },
    meta: {
      title: "Importer des fournisseurs",
      access: 1,
      scope: AppScope.supplier,
    },
  },
  {
    path: "/recipient/list",
    alias: "/recipient",
    name: "recipient-list",
    component: ContactListPage,
    props: {
      type: "recipient",
    },
    meta: {
      access: 1,
      scope: AppScope.recipient,
    },
  },
  {
    path: "/recipient/create",
    name: "create-recipient",
    component: CreateContactPage,
    props: {
      type: "recipient",
    },
    meta: {
      access: 1,
      scope: AppScope.recipient,
    },
  },
  {
    path: "/recipient/import",
    name: "import-recipient",
    component: ImportContactCsvPage,
    props: {
      type: "recipient",
      attributeGroup: AttributeGroupType.contacts,
    },
    meta: {
      access: 1,
      scope: AppScope.recipient,
    },
  },
  {
    path: "/carrier/list",
    alias: "/carrier",
    name: "carrier-list",
    component: ContactListPage,
    props: {
      type: "carrier",
    },
    meta: {
      access: 1,
      scope: AppScope.carrier,
    },
  },
  {
    path: "/carrier/create",
    name: "create-carrier",
    component: CreateContactPage,
    props: {
      type: "carrier",
    },
    meta: {
      access: 1,
      scope: AppScope.carrier,
    },
  },
  {
    path: "/carrier/import",
    name: "import-carrier",
    component: ImportContactCsvPage,
    props: {
      attributeGroup: AttributeGroupType.contacts,
    },
    meta: {
      access: 1,
      scope: AppScope.carrier,
    },
  },
];

export default contactRoutes;
