import { Reception } from "@/interfaces/reception";
import { Order } from "@/interfaces/order";
import { ShippingOrder } from "@/interfaces/shipping-order";
import { StockMovement } from "@/interfaces/stock-movement";
import { Stock } from "@/interfaces/stock";

export interface Dashboard {
  id: string;
  kpi: DashboardKpi;
  stocks: Array<Stock> | null;
  orders: Array<Order> | null;
  receptions: Array<Reception> | null;
  shippingOrders: Array<ShippingOrder> | null;
  stockMovements: Array<StockMovement> | null;
}

export class Dashboard {
  constructor(data: Partial<Dashboard> = {}) {
    Object.assign(this, data);
    this.kpi = data.kpi as DashboardKpi;
    this.stocks = data.stocks ? data.stocks.map((e: object) => new Stock(e)) : null;
    this.orders = data.orders ? data.orders.map((e: object) => new Order(e)) : null;
    this.receptions = data.receptions ? data.receptions.map((e: object) => new Reception(e)) : null;
    this.shippingOrders = data.shippingOrders
      ? data.shippingOrders.map((e: object) => new ShippingOrder(e))
      : null;
    this.stockMovements = data.stockMovements
      ? data.stockMovements.map((e: object) => new StockMovement(e))
      : null;
  }
}

export interface DashboardKpi {
  bottlesCount: number;
  referencesCount: number;
  yearBottlesCount: number;
  yearOrdersCount: number;
}
