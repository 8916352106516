




























import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import ImportGridFooter from "@/components/_shared/import-csv/import-grid-footer.vue";
import GridBoolComponent from "@/components/_shared/grid-components/grid-cells/grid-bool-component.vue";
import ImportGridHeader from "@/components/_shared/import-csv/import-grid-header.vue";
import { ImportGrid } from "@/utils/grid/import-grid-helper";

@Component({
  name: "common-import-csv-page",
  components: {
    ImportGridHeader,
    ImportGridFooter,
    AgGridVue,
    GridBoolComponent,
  },
})
export default class CommonImportCsvPage extends ImportGrid {
  @Prop() dataLabel: string;

  async beforeMount() {
    await super.beforeMount();
  }

  async onValidate() {
    await super.onValidate();
  }
}
