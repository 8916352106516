/** store/index.js **/
import Vuex from "vuex";
import modules from "./modules";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

function getPlugins(): any {
  const plugins = [createPersistedState()];
  return plugins;
}

export default new Vuex.Store({
  modules: modules,
  actions: {
    reset({ commit }) {
      // resets state of all the modules
      Object.keys(modules).forEach((moduleName) => {
        commit(`${moduleName}/reset`);
      });
    },
  },
  strict: debug,
  plugins: getPlugins(),
});
