export interface Address {
  id: string;
  code: string;
  name: string;
  isCompany: boolean;
  firstname: string;
  lastname: string;
  company: string;
  email: string;
  phone: string;
  mobile: string;
  street: string;
  details: string;
  postcode: string;
  city: string;
  state: string;
  country: string;
  comment: string;
  timeslots: Array<TimeSlot> | null;
  pickupCode: string | null;
  exciseNumber: string;
  exciseWarehouseNumber: string;
  siretNumber: string;
}

export class Address {
  isCompany = true;

  constructor(data: Partial<Address> = {}) {
    this.timeslots = data.timeslots ? data.timeslots.map((e: TimeSlot) => e as TimeSlot) : [];
    Object.assign(this, data);
    if (!this.phone) this.phone = "";
    if (!this.country) this.country = "FR";
  }

  encode(): object {
    const data: any = Object.assign({}, this);
    data.id = this.id ? this.id : "";
    data.code = this.code ? this.code : "";
    if (data.pickupCode == "") delete data.pickupCode;
    if (!data.timeslots || data.timeslots.length == 0) delete data.timeslots;
    return data;
  }
}

export interface TimeSlot {
  start: string;
  end: string;
}

export abstract class TimeSlot {
  static default() {
    return [{ start: "", end: "" } as TimeSlot, { start: "", end: "" } as TimeSlot];
  }
}
