



















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Company } from "@/interfaces/company";
import { Contact, ContactType } from "@/interfaces/contact";
import { Service } from "@/interfaces/service";
import ContactSearchComponent from "../searchs/contact-search-component.vue";
import { formValidation } from "@/utils/form-validation-helper";
import { contactApiProvider } from "@/providers/contact-api-provider";
import { QueryBuilder } from "@/providers/filter";
import ServiceSelectorComponent from "./service-selector-component.vue";
import { CompanyCarrier } from "@/interfaces/company-carrier";
import { Segment } from "@/interfaces/segment";

@Component({
  name: "company-carrier-component",
  components: {
    ContactSearchComponent,
    ServiceSelectorComponent,
  },
})
export default class CompanyCarrierComponent extends Vue {
  @Prop() company: Company;

  value: CompanyCarrier[] = [];

  allCarriers: Contact[];

  carriers: { B2B: Contact[]; B2C: Contact[] } = { B2B: [], B2C: [] };

  public valueBySegment(segment: Segment): CompanyCarrier[] {
    return this.value.filter((val) => val.segment.code == segment.code);
  }

  rules = formValidation.getRules();

  async beforeMount() {
    this.initialize();
    const params = QueryBuilder.whereEquals("type", ContactType.carrier).build();
    params.append("paginate", "500");
    const result = await contactApiProvider.getAll(params);
    this.allCarriers = result.data;
    this.onSegmentsChange(this.company.segments);
  }

  @Watch("company.segments")
  onSegmentsChange(value: Segment[]) {
    if (value) {
      value.forEach((segment) => {
        this.carriers[segment.id] = this.allCarriers.filter(
          (carrier: Contact) => carrier.segment == null || carrier.segment.id == segment.id
        );
      });
    } else {
      this.carriers = null;
    }
    this.$forceUpdate();
  }

  onInput(newService: { contact: Contact; service: Service; segment: Segment }) {
    const newCompanyCarrier = new CompanyCarrier({
      service: newService.service,
      carrier: newService.contact,
      segment: newService.segment,
      company: new Company({ id: this.company.id }),
    });

    // Avant de l'ajouter dans la liste, on vérifie que la ligne n'est pas déjà présente.
    const exist =
      this.value.find(
        (c: CompanyCarrier) =>
          c.segment.id == newCompanyCarrier.segment.id &&
          c.carrier.id == newCompanyCarrier.carrier.id &&
          newCompanyCarrier.service &&
          c.service.id == newCompanyCarrier.service.id // If the service exists.
      ) != null;

    if (!exist) {
      this.value.push(newCompanyCarrier);
      this.$emit("input", this.value);
    }
  }

  removeCompanyCarrier(old: CompanyCarrier) {
    const index = this.value.findIndex(
      (c: CompanyCarrier) =>
        c.segment.id == old.segment.id &&
        c.carrier.id == old.carrier.id &&
        old.service &&
        old.service.id == c.service.id // If the service exists.
    );
    this.value.splice(index, 1);
  }

  initialize() {
    this.value = this.company.carriers;
    this.value.forEach((v) => {
      if (v.company == null) {
        v.company = this.company;
      }
    });
  }
}
