import { ColorChipInfo } from "../grid/grid-helper";
import { Order, OrderStatus } from "@/interfaces/order";

export abstract class ColorOrderStatus {
  static getColorFromCode(code: OrderStatus): ColorChipInfo {
    const text = Order.stringFromStatus(code);
    switch (code) {
      case OrderStatus.draft:
        return { color: "kDartessNightBlue75", textColor: "white--text", text: text };
      case OrderStatus.entry:
      case OrderStatus.transmitting:
      case OrderStatus.inProgress:
      case OrderStatus.validated:
        return { color: "kDartessNightBlue", textColor: "white--text", text: text };
      case OrderStatus.sent:
        return { color: "kDartessGreen", textColor: "white--text", text: text };
      case OrderStatus.canceled:
        return { color: "kDartessRed", textColor: "white--text", text: text };
      default:
        return { color: "white", textColor: "black--text", text: code };
    }
  }
}
