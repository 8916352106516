










































































import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { Contact, ContactType } from "@/interfaces/contact";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import { PreferencesList } from "@/interfaces/preference";
import { Grid } from "@/utils/grid/grid-helper";
import GridFlagComponent from "../_shared/grid-components/grid-cells/grid-flag-component.vue";
import CommonSearchComponent from "../_shared/searchs/common-search-component.vue";
import GridRightIconsComponent from "../_shared/grid-components/grid-right-icons-component.vue";
import ImportCsvModal from "../_shared/modals/import-csv-modal.vue";
import GridListFilterComponent from "@/components/_shared/grid-components/grid-filters/grid-list-filter-component.vue";
import { Segment } from "@/interfaces/segment";
import store from "@/stores/store";
import { UserRole } from "@/interfaces/user";

@Component({
  name: "contact-grid",
  components: {
    AgGridVue,
    GridIconRowComponent,
    GridRightIconsComponent,
    GridFlagComponent,
    CommonSearchComponent,
    ImportCsvModal,
  },
})
export default class ContactGrid extends Grid {
  @Prop() rowData: Array<Contact>;
  @Prop() type: ContactType;

  typeText: any;

  superAdmin = store.getters["user/getUser"].role == UserRole.superadmin;

  async beforeMount() {
    this.typeText = this.$t(`${this.type}.name`);

    // Switch preferences
    switch (this.type) {
      case ContactType.recipient:
        this.defaultPreference = {
          slug: PreferencesList.recipient,
          columns: [
            "address.name",
            "address.street",
            "address.postcode",
            "address.city",
            "address.country",
            "warehouse.name",
          ],
        };
        break;
      case ContactType.supplier:
        this.defaultPreference = {
          slug: PreferencesList.supplier,
          columns: [
            "address.name",
            "address.street",
            "address.postcode",
            "address.city",
            "address.country",
            "warehouse.name",
          ],
        };
        break;
      case ContactType.carrier:
        this.defaultPreference = {
          slug: PreferencesList.carrier,
          columns: [
            /*"name",*/
            "address.name",
            "address.street",
            "address.postcode",
            "address.city",
            "address.country",
          ],
        };
        break;
    }

    // Specific superadmin columns.
    if (this.superAdmin) {
      this.defaultPreference.columns.unshift("owner.code", "owner.name");
      this.columns.push({
        headerName: this.$t("owner.code"),
        field: "owner.code",
      } as any);
      this.columns.push({
        headerName: this.$t("owner.name"),
        field: "owner.name",
      } as any);
    }

    // Specific recipient & supplier column.
    if (this.type !== ContactType.carrier) {
      this.columns.push({
        headerName: this.$t("warehouse.name"),
        field: "warehouse.name",
        filterFramework: null,
      } as any);
    }

    if (this.type === ContactType.recipient || this.type === ContactType.carrier) {
      this.columns.push({
        headerName: this.$tc("form.segment", 1),
        field: "segment",
        filterFramework: GridListFilterComponent,
        valueFormatter: function (params: any) {
          return params.data?.segment?.code ?? "-";
        },
        filterParams: {
          values: () => [
            ...Segment.list.map((s) => ({ key: s.code, value: s.code })),
            { key: "NULL", value: this.$t("form.no_segment") },
          ],
        },
      } as any);
    }

    super.beforeMount();
  }

  $refs: {
    config: HTMLFormElement;
    importCsvModal: HTMLFormElement;
  };

  columns = [
    { headerName: this.$t("form.code"), field: "code", sort: "asc", width: 100, minWidth: 100 },
    /*{ headerName: this.$t("form.name"), field: "name", width: 150, minWidth: 150 },*/
    {
      headerName: this.$t("address.contact"),
      field: "address.name",
      cellClass: "cell-align-left",
      width: 150,
      minWidth: 150,
      valueFormatter: (params: any) => params.data?.name ?? "-",
    },
    {
      headerName: this.$t("form.address"),
      field: "address.street",
      width: 150,
      minWidth: 150,
      cellClass: "cell-align-left",
    },
    { headerName: this.$t("form.postcode"), field: "address.postcode", width: 150, minWidth: 150 },
    { headerName: this.$t("form.city"), field: "address.city", width: 150, minWidth: 150 },
    {
      headerName: this.$t("form.country"),
      field: "address.country",
      cellRendererFramework: "GridFlagComponent",
    },
    {
      cellRendererFramework: "GridIconRowComponent",
      pinned: "right",
      width: 40,
      minWidth: 40,
      maxWidth: 40,
      cellClass: "cell-align-company",
      sortable: false,
      filterFramework: null,
      suppressMovable: true,
      resizable: false,
      valueGetter: function (params: any) {
        return { id: params.data?.id, active: [true] };
      },
      cellRendererParams: { icons: [{ icon: "mdi-pen", event: "open-contact-detail-modal" }] },
    },
  ];
}
