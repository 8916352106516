












import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "app-expansion-panel-header" })
export default class AppExpansionPanelHeader extends Vue {
  @Prop() title: string;

  @Prop() icon: string;

  @Prop({ default: "kDartessDarkGrey" }) color: string;
}
