




























import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { IFilterComp } from "ag-grid-community";
import { Condition } from "@/providers/filter";
import store from "@/stores/store";

@Component({
  name: "grid-date-filter-component",
  components: { AgGridVue },
})
export default class GridDateFilterComponent extends Vue implements IFilterComp {
  value = [];

  private locale = store.getters["user/getLocale"];

  updateFilter() {
    this.$data.params.filterChangedCallback();
  }

  init(params: any) {
    throw new Error("Method not implemented.");
  }

  getGui(): HTMLElement {
    throw new Error("Method not implemented.");
  }

  isFilterActive() {
    return this.value != null && this.value.length > 0;
  }

  doesFilterPass(params: any) {
    //TODO: implement date comparison
    return true;
  }

  getModel() {
    if (this.value.length > 1) {
      return Condition.whereBetween([this.value[0], this.value[1] + " 23:59:59"]);
    } else if (this.value.length > 0) {
      return Condition.whereBetween([this.value[0], this.value[0] + " 23:59:59"]);
    } else {
      return null;
    }
  }

  setModel(model: any) {
    this.value = model ? model.filter : null;
  }

  resetValue() {
    this.value = [];
    this.updateFilter();
  }
}
