
















































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import AppExpansionPanelHeader from "@/components/_shared/panels/app-expansion-panel-header.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { Product } from "@/interfaces/product";
import { Attribute } from "@/interfaces/attributes/attribute";
import store from "@/stores/store";
import { formValidation } from "@/utils/form-validation-helper";
import { Family } from "@/interfaces/attributes/family";
import { attributeApiProvider } from "@/providers/attribute-api-provider";
import { Warehouse } from "@/interfaces/warehouse";
import { productApiProvider } from "@/providers/product-api-provider";
import router from "@/router/router";
import AttributeFormComponent from "@/components/_shared/forms/attribute-form-component.vue";
import ContactSearchComponent from "@/components/_shared/searchs/contact-search-component.vue";
import { UserRole } from "@/interfaces/user";
import { Company } from "@/interfaces/company";
import { companyApiProvider } from "@/providers/company-api-provider";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";
import { AttributeHelper } from "@/utils/attribute-helper";

@Component({
  name: "create-product-page",
  components: {
    AppExpansionPanelHeader,
    AppIconTextButton,
    AttributeFormComponent,
    ContactSearchComponent,
  },
})
export default class CreateProductPage extends Vue {
  attributeStep = 0;
  formSending = false;
  isLoading = true;

  rules = formValidation.getRules();

  attributes = [] as Attribute[];

  newProduct: Product = new Product();

  superAdmin = store.getters["user/getUser"].role == UserRole.superadmin;

  companySelect: Company[] = [];
  warehouses = [] as Warehouse[];
  familySelect = [] as Family[];
  familyChildSelect: Family[] | undefined = [] as Family[];

  selectedFamily: Family | null = null;

  $refs: {
    productForm: HTMLFormElement;
    attributes: HTMLFormElement;
  };

  breadcrumbs = [
    { text: this.$t("app.title") },
    { text: this.$t("product.title"), href: "/product" },
    { text: this.$t("product.create_product") },
  ];

  /**
   * Action after specific attribute change
   */
  onAttributeChange(attributeId) {
    // If family change, empty inactive attributes
    if (attributeId === "family") {
      for (let i = 0; i < this.attributes.length; i++) {
        if (!this.isAttributeActive(i)) {
          attributeId = this.attributes[i].id;
          delete this.newProduct.attributes[attributeId];
        }
      }
    }
    // Update weight after capacity update
    if (attributeId === "capacity") {
      this.newProduct.attributes["weight"] = this.getWeightForCapacity(
        this.newProduct.attributes["capacity"]
      );
    }
  }

  /**
   * Return an estimated weight based on capacity
   */
  getWeightForCapacity(capacity) {
    let weight = null;
    switch (true) {
      case capacity <= 18.75:
        weight = 370;
        break;
      case capacity <= 37.5:
        weight = 833;
        break;
      case capacity <= 50:
        weight = 1000;
        break;
      case capacity <= 70:
        weight = 1400;
        break;
      case capacity <= 75:
        weight = 1500;
        break;
      case capacity <= 100:
        weight = 2000;
        break;
      case capacity <= 150:
        weight = 3000;
        break;
      case capacity <= 300:
        weight = 6000;
        break;
      case capacity <= 450:
        weight = 9000;
        break;
      case capacity <= 600:
        weight = 12000;
        break;
      case capacity <= 900:
        weight = 18000;
        break;
      case capacity <= 1200:
        weight = 24000;
        break;
      case capacity <= 1500:
        weight = 30000;
        break;
      case capacity <= 1800:
        weight = 36000;
        break;
      case capacity <= 4000:
        weight = 80000;
        break;
    }
    return weight.toString();
  }

  /**
   * Rules to display attributes only when previous attributes are filled
   * @param index
   */
  checkIfAttributeVisible(index) {
    // Always display first attribute
    if (index === 0) {
      return true;
    } else {
      let isVisible = true;
      for (let i = 0; i < index; i++) {
        let previousAttribute: Attribute = this.attributes[i];
        // If attribute is required, displayed and doesn't have a value, user can't access next field
        if (
          this.attributes[i].required &&
          this.isAttributeActive(i) &&
          !this.getAttributeValue(previousAttribute.id)
        ) {
          isVisible = false;
        }
      }
      return isVisible;
    }
  }

  /**
   * Check if attribute component is active
   * @param index
   */
  isAttributeActive(index): boolean {
    let helper = new AttributeHelper();
    if (this.attributes[index].rules != null) {
      return helper.validRules(this.attributes[index], this.newProduct.attributes);
    } else {
      return helper.validSpecificRules(this.attributes[index], this.newProduct);
    }
  }

  /**
   * Get attribute component value
   * @param id
   */
  getAttributeValue(id) {
    return this.newProduct.attributes[id];
  }

  /**
   * On vérifie si il existe un attribut s'appellant "nomenclature".
   */
  get isNomenclatureAttributes() {
    return this.attributes.find((att) => att.id == "nomenclature") != null;
  }

  /**
   * This methods was called when the page is open in modification mode.
   */
  async modifyPageConfiguration(product: Product) {
    let title = this.$t("product.update_product");
    if (product.code) {
      title = this.$t("product.update_product_no", { code: product.code });
      this.breadcrumbs = [
        { text: this.$t("app.title") },
        { text: this.$t("product.title"), href: "/product" },
        { text: this.$t("product.update_product") },
      ];
    }
    this.attributeStep = 2;
    this.$eventHub.$emit("update-title", title);
  }

  async beforeMount() {
    this.$route.meta.title = this.$t("product.create_product");
    await this.initializeSelect();

    /** Modification d'un produit */
    if (this.$route.params.product_id) {
      const response = await productApiProvider.getProductById(this.$route.params.product_id);
      if (response) {
        this.newProduct = response;
        this.selectedFamily = this.newProduct.family ? this.newProduct.family.family : null;
        this.onFamilySet();
        this.onChildFamilySet();
        this.modifyPageConfiguration(response);
      } else {
        await router.push(`/product/list`);
      }
      /** Ajout d'un produit */
    } else {
      const tempSelectedWarehouse = store.getters["user/getSelectedWarehouse"];
      if (this.$route.params.warehouseId) {
        this.newProduct.warehouse = this.warehouses.find(
          (value: Warehouse) => value.id == this.$route.params.warehouseId
        );
      } else if (tempSelectedWarehouse) {
        this.newProduct.warehouse = tempSelectedWarehouse;
      }
      // If we add a product from the reception page.
      if (this.$route.params.origin) {
        // The default generated breadcrumb is false when we are in admin. We have to set a new one manually.
        this.breadcrumbs = [
          { text: this.$t("app.title") },
          { text: `${this.$t("reception.list_page_title")}`, href: "/reception/list" },
          this.$route.params.origin.includes("modify")
            ? { text: `${this.$t("reception.modify_page_title")}` }
            : { text: `${this.$t("reception.create_page_title")}` },
          { text: this.$t("product.create_product") },
        ];
      }
    }

    this.setDefaultWarehouse();

    if (this.superAdmin) {
      this.companySelect = await companyApiProvider.getCompanies(true);
    } else if (!this.newProduct.owner) {
      this.newProduct.owner = store.getters["user/getUser"].company;
    }

    this.isLoading = false;
  }

  /**
   * Get company detail. (segment & permissions).
   * Only use in superadmin mode.
   */
  async onCompanyChange(id: string) {
    this.newProduct.owner = await companyApiProvider.getCompany(id);
    this.warehouses = this.newProduct.owner.warehouses;
    this.setDefaultWarehouse();
  }

  // S'il n'y a qu'un entrepôt, on le mets par défaut.
  setDefaultWarehouse() {
    // Si la personne est mono site on le complète par défaut.
    if (this.warehouses.length == 1) {
      this.newProduct.warehouse = this.warehouses[0];
    }
  }

  onFamilySet() {
    if (this.selectedFamily) {
      const index = this.familySelect.findIndex(
        (fam: Family) => this.selectedFamily && fam.id == this.selectedFamily.id
      );
      if (index != -1) {
        this.familyChildSelect = [...this.familySelect[index].families];
        this.familyChildSelect.sort((a, b) => a.name.localeCompare(b.name));
      }
    }
    this.attributes = [];
    this.attributeStep = 1;
  }

  async onChildFamilySet() {
    if (this.newProduct.family) {
      this.attributes = await attributeApiProvider.getAttributeForFamily(this.newProduct.family.id);
    }
    if (this.attributes.length == 0) {
      this.attributeStep = 2;
    }
  }

  async validateCreation() {
    let response: Product | null;
    if (this.$refs.productForm.validate()) {
      this.formSending = true;
      if (this.newProduct.id) {
        response = await productApiProvider.updateProduct(this.newProduct);
      } else {
        response = await productApiProvider.createProduct(this.newProduct);
      }
      if (response) {
        // If we create a product from the reception page.
        const nextRoute = this.$route.params.origin ?? "/product/list";
        await router.replace({ path: nextRoute, params: { search_filter: response.label } });
      } else {
        this.formSending = false;
      }
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.warning,
        mainText: this.$t("form-validation.error"),
      });
    }
  }

  async initializeSelect() {
    await store.dispatch("productFamily/reset");
    await store.dispatch("productFamily/fetchAllFamilies");
    this.familySelect = [...store.getters["productFamily/getAllFamilies"]];
    this.warehouses = [...store.getters["user/getUser"].warehouses]; // Liste de site par défaut.
    // Sort families.
    this.familySelect.sort((a, b) => a.name.localeCompare(b.name));
  }
}
