import ClientUserListPage from "../pages/client-user/client-user-list-page.vue";
import CreateClientUserPage from "../pages/client-user/create-client-user-page.vue";
import ImportUserCsvPage from "@/pages/user/import-user-csv-page.vue";
import { AttributeGroupType } from "@/interfaces/attributes/attribute";

const clientUserRoutes = [
  {
    path: "/client-user/list",
    alias: "/client-user",
    name: "client-user-list",
    component: ClientUserListPage,
    meta: {
      access: 10,
    },
  },
  {
    path: "/client-user/:companyId/list",
    name: "admin-client-user-list",
    component: ClientUserListPage,
    meta: {
      access: 100,
    },
  },
  {
    path: "/client-user/create",
    name: "create-client-user",
    component: CreateClientUserPage,
    meta: {
      access: 10,
    },
  },
  {
    path: "/client-user/:companyId/create",
    name: "admin-create-client-user",
    component: CreateClientUserPage,
    meta: {
      access: 100,
    },
  },
  {
    path: "/client-user/:companyId/import",
    name: "import-client-users",
    component: ImportUserCsvPage,
    props: {
      attributeGroup: AttributeGroupType.user,
    },
    meta: {
      title: "Importer des utilisateurs",
      access: 10,
    },
  },
];

export default clientUserRoutes;
