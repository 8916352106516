





















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "kpi-card",
})
export default class KpiCard extends Vue {
  @Prop() text: string;
  @Prop() value: number;
  @Prop() color: string;
  @Prop() icon: string;
  @Prop() secondText: string;
  @Prop() to: any;
}
