

























import { Component, Mixins, Prop } from "vue-property-decorator";
import ModalComponent from "./modal-component.vue";
import AppIconTextButton from "../buttons/app-icon-text-button.vue";
import ProductCard from "../cards/product-card.vue";

@Component({
  name: "confirm-modal",
  components: { ModalComponent, AppIconTextButton, ProductCard },
})
export default class ConfirmModal extends Mixins(ModalComponent) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() yesCallback: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() noCallback: Function;
  @Prop() event: string;
  @Prop() text: string;
  @Prop() title: string;

  data: any;

  onOpening(data: any) {
    this.data = data;
    this.toggleModal(true);
  }

  onClickYes() {
    if (this.yesCallback) this.yesCallback(this.data);
    this.toggleModal(false);
  }

  onClickNo() {
    if (this.noCallback) this.noCallback(this.data);
    this.toggleModal(false);
  }

  mounted() {
    this.$eventHub.$on(this.event, (data: any) => this.onOpening(data));
  }

  beforeDestroy() {
    this.$eventHub.$off(this.event);
  }
}
