












import { Component, Vue } from "vue-property-decorator";
import { DataSource } from "@/utils/grid/datasource";
import ShippingOrderGrid from "@/components/shipping-order/shipping-order-grid.vue";
import { shippingOrderApiProvider } from "@/providers/shipping-order-api-provider";
import ShippingOrderUpdateModal from "@/components/shipping-order/shipping-order-update-modal.vue";

@Component({
  name: "shipping-order-list-page",
  components: { ShippingOrderGrid, ShippingOrderUpdateModal },
})
export default class ShippingOrderListPage extends Vue {
  datasource: DataSource;

  breadcrumbs = [
    { text: this.$t("app.title") },
    { text: this.$t("shipping-order.list_page_title") },
  ];

  $refs: {
    shippingOrderGrid: HTMLFormElement;
    shippingOrderUpdateModal: HTMLFormElement;
  };

  async beforeMount() {
    this.$route.meta.title = this.$t("shipping-order.list_page_title");
    this.datasource = DataSource.from(shippingOrderApiProvider);
  }

  async onCloseStatusModal() {
    this.$refs.shippingOrderGrid.onFilterChange();
  }

  async mounted() {
    this.$nextTick(() => {
      // Autocomplete search field on new creation.
      if (this.$route.params.search_filter && this.$refs.shippingOrderGrid) {
        this.$refs.shippingOrderGrid.$refs.searchComponent.search =
          this.$route.params.search_filter;
      }
    });
  }
}
