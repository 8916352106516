export interface Permissions {
  contacts: Array<string>;
  services: Array<string>;
}

export class Permissions {
  constructor(data: Partial<Permissions> = {}) {
    this.contacts = data.contacts ?? [];
    this.services = data.services ?? [];
  }
}
