import { Contact } from "@/interfaces/contact";
import { Address } from "@/interfaces/address";
import { Warehouse } from "./warehouse";
import { Company } from "./company";
import { Stock } from "./stock";
import { i18n } from "@/main";
import { Segment } from "./segment";
import { Service } from "@/interfaces/service";
import store from "@/stores/store";
import { User, UserRole } from "@/interfaces/user";

export enum OrderStatus {
  draft = "000",
  transmitting = "005",
  entry = "010",
  inProgress = "020",
  validated = "040",
  canceled = "050",
  sent = "070",
}

export interface Order {
  id: number;
  date: string;
  code: string;
  reference: string;
  recipientReference: string;
  deliveryDate: string;
  preparationDate: string;
  preparedQuantity: number;
  quantity: number;
  type: string | null;
  comment: string;
  pickupManagedByDartess: number;
  shippingLabelRequired: number;
  shippingTrackingId: string;

  recipient: Address | null;
  statusCode: OrderStatus | null;
  stocks: Array<Stock>;
  blId: string;

  customer: Contact | null;
  carrier: Contact | null;
  warehouse: Warehouse | null;
  segment: Segment | null;
  owner: Company | null;
  service: Service | null;
  createdAt: string;
}

export class Order {
  constructor(data: Partial<Order> = {}) {
    // Default values
    this.pickupManagedByDartess = 1;
    this.shippingLabelRequired = 0;
    // Order data
    Object.assign(this, data);
    this.stocks = data.stocks ? data.stocks.map((e: Stock) => new Stock(e)) : [];
    this.recipient = data.recipient ? new Address(data.recipient) : null;
    this.customer = data.customer ? new Contact(data.customer) : null;
    this.carrier = data.carrier ? new Contact(data.carrier) : null;
    this.owner = data.owner ? new Company(data.owner) : null;
    this.warehouse = data.warehouse ? new Warehouse(data.warehouse) : null;
    this.segment = data.segment ? new Segment(data.segment) : null;
    this.service = data.service ? new Service(data.service) : null;
    this.statusCode = data.statusCode ? Order.statusFromCode(data.statusCode) : null;
  }

  static canModifyScope(status: OrderStatus) {
    return status == OrderStatus.draft || status == OrderStatus.entry;
  }

  static canDeleteScope(status: OrderStatus) {
    const isSuperAdmin = store.getters["user/getUser"].role == UserRole.superadmin;
    return status == OrderStatus.draft || isSuperAdmin;
  }

  static canShowScope(status: OrderStatus) {
    return status != OrderStatus.draft;
  }

  // Shortcut for object.
  canDeleteScope() {
    return Order.canDeleteScope(this.statusCode);
  }

  // Shortcut for object.
  canModifyScope() {
    return Order.canModifyScope(this.statusCode);
  }

  // Shortcut for object.
  canShowScope() {
    return Order.canShowScope(this.statusCode);
  }

  encode(): Order {
    const data: any = Object.assign({}, this);
    data.customer = this.customer?.id;
    data.carrier = this.carrier ? this.carrier.code : null; // @MOTRIEUX Thomas - On mets le code a la place de l'uuid pour correspondre aux données provenant des flux EDI.
    data.warehouse = this.warehouse?.id;
    data.segment = this.segment?.id;
    data.service = this.service?.id;
    data.owner = this.owner?.id;
    if (data.type == "") delete data.type;
    data.recipient = data.recipient ? data.recipient.encode() : new Address().encode();
    data.stocks = this.stocks?.map((stock: Stock) => {
      return {
        id: stock.id,
        ordered: stock.ordered,
      };
    });
    return data;
  }

  static statusFromCode(statusCode: string): OrderStatus | null {
    switch (statusCode) {
      case "000":
        return OrderStatus.draft;
      case "005":
        return OrderStatus.transmitting;
      case "010":
        return OrderStatus.entry;
      case "020":
        return OrderStatus.inProgress;
      case "030":
        return OrderStatus.inProgress;
      case "040":
        return OrderStatus.validated;
      case "050":
        return OrderStatus.canceled;
      case "070":
        return OrderStatus.sent;
      default:
        return null;
    }
  }

  static stringFromStatus(status: OrderStatus): string {
    switch (status) {
      case OrderStatus.draft:
        return i18n.t("order.status.000").toString();
      case OrderStatus.transmitting:
        return i18n.t("order.status.005").toString();
      case OrderStatus.entry:
        return i18n.t("order.status.010").toString();
      case OrderStatus.inProgress:
        return i18n.t("order.status.020").toString();
      case OrderStatus.validated:
        return i18n.t("order.status.040").toString();
      case OrderStatus.canceled:
        return i18n.t("order.status.050").toString();
      case OrderStatus.sent:
        return i18n.t("order.status.070").toString();
      default:
        return "";
    }
  }

  static getAllStatus() {
    return Object.values(OrderStatus);
  }
}
