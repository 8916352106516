














































import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import moment from "moment";
import { Order, OrderStatus } from "@/interfaces/order";
import { Grid } from "@/utils/grid/grid-helper";
import { PreferencesList } from "@/interfaces/preference";
import GridDateFilterComponent from "../_shared/grid-components/grid-filters/grid-date-filter-component.vue";
import GridFlagComponent from "../_shared/grid-components/grid-cells/grid-flag-component.vue";
import CommonSearchComponent from "../_shared/searchs/common-search-component.vue";
import GridRightIconsComponent from "../_shared/grid-components/grid-right-icons-component.vue";
import GridStatusChipComponent from "../_shared/grid-components/grid-cells/grid-status-chip-component.vue";
import { ShippingOrder, ShippingOrderStatus } from "@/interfaces/shipping-order";
import GridListFilterComponent from "../_shared/grid-components/grid-filters/grid-list-filter-component.vue";
import store from "../../stores/store";
import { UserRole } from "@/interfaces/user";
import GridNumericFilterComponent from "../_shared/grid-components/grid-filters/grid-numeric-filter-component.vue";
import NewShippingOrderModal from "./new-shipping-order-modal.vue";
import router from "@/router/router";

@Component({
  name: "shipping-order-grid",
  components: {
    AgGridVue,
    GridIconRowComponent,
    GridRightIconsComponent,
    CommonSearchComponent,
    GridFlagComponent,
    GridStatusChipComponent,
    NewShippingOrderModal,
  },
})
export default class ShippingOrderGrid extends Grid {
  @Prop() rowData: Array<Order>;

  $refs: {
    config: HTMLFormElement;
    commonGridFilter: HTMLFormElement;
    newShippingOrderModal: HTMLFormElement;
  };

  async beforeMount() {
    super.beforeMount();
    this.gridOptions.getRowClass = function (params: any) {
      if (params.data && params.data.statusCode == OrderStatus.draft) {
        return "kDartessNightBlue40";
      }
    };
    if (store.getters["user/getUser"].role == UserRole.superadmin) {
      this.columns.push({
        headerName: this.$t("owner.code"),
        field: "owner.code",
        width: 150,
        minWidth: 150,
      } as any);
      this.defaultPreference.columns.unshift("owner.name");
      this.columns.push({
        headerName: this.$t("owner.name"),
        field: "owner.name",
        width: 150,
        minWidth: 150,
      } as any);
    }
  }

  async onClickNewShippingOrder() {
    //store.getters['createShippingOrder/getShippingOrder']
    if (store.getters["createShippingOrder/getShippingOrder"]) {
      this.$refs.newShippingOrderModal.open();
    } else {
      await router.push("/shipping-order/create");
    }
  }

  defaultPreference = {
    slug: PreferencesList.shippingOrder,
    columns: [
      "reference",
      "createdAt",
      "pickupAddress.displayAddress",
      "pickupDate",
      "pickedupAt",
      "palletCount",
      "parcelCount",
      "unitCount",
      "deliveryAddress.displayAddress",
      "deliveryDate",
      "deliveredAt",
      "warehouse.name",
      "statusCode",
    ],
  };

  columns = [
    {
      headerName: this.$t("shipping-order.reference"),
      field: "reference",
      cellClass: "cell-align-center",
    },
    {
      headerName: this.$t("shipping-order.pickupAddress"),
      field: "pickupAddress.displayAddress",
      width: 150,
      minWidth: 150,
      valueFormatter: function (params: any) {
        if (params.data) {
          const d = params.data.pickupAddress;
          return `${d.street}, ${d.postcode} ${d.city}`;
        }
      },
    },
    {
      headerName: this.$t("shipping-order.deliveryAddress"),
      field: "deliveryAddress.displayAddress",
      width: 150,
      minWidth: 150,
      valueFormatter: function (params: any) {
        if (params.data) {
          const d = params.data.deliveryAddress;
          return `${d.street}, ${d.postcode} ${d.city}`;
        }
      },
    },
    {
      headerName: this.$t("shipping-order.palletCount"),
      field: "palletCount",
      cellClass: "cell-align-right",
      filterFramework: GridNumericFilterComponent,
    },
    {
      headerName: this.$t("shipping-order.parcelCount"),
      field: "parcelCount",
      cellClass: "cell-align-right",
      filterFramework: GridNumericFilterComponent,
    },
    {
      headerName: this.$t("shipping-order.unitCount"),
      field: "unitCount",
      cellClass: "cell-align-right",
      filterFramework: GridNumericFilterComponent,
    },
    {
      headerName: this.$t("shipping-order.weight"),
      field: "weight",
      cellClass: "cell-align-right",
      filterFramework: GridNumericFilterComponent,
    },
    {
      headerName: this.$t("shipping-order.createdAt"),
      field: "createdAt",
      cellClass: "cell-align-center",
      width: 150,
      minWidth: 100,
      filterFramework: GridDateFilterComponent,
      valueFormatter: function (params: any) {
        if (params.data && params.data.createdAt) {
          return moment(params.data?.createdAt).format("L");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: this.$t("shipping-order.pickupDate"),
      field: "pickupDate",
      cellClass: "cell-align-center",
      width: 150,
      minWidth: 150,
      filterFramework: GridDateFilterComponent,
      valueFormatter: function (params: any) {
        if (params.data && params.data.pickupDate) {
          return moment(params.data?.pickupDate).format("L");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: this.$t("shipping-order.pickedupAt"),
      field: "pickedupAt",
      cellClass: "cell-align-center",
      width: 100,
      minWidth: 100,
      filterFramework: GridDateFilterComponent,
      valueFormatter: function (params: any) {
        if (params.data && params.data.pickedupAt) {
          return moment(params.data?.pickedupAt).format("L LT");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: this.$t("shipping-order.deliveryDate"),
      field: "deliveryDate",
      cellClass: "cell-align-center",
      width: 150,
      minWidth: 150,
      filterFramework: GridDateFilterComponent,
      valueFormatter: function (params: any) {
        if (params.data && params.data.deliveryDate) {
          return moment(params.data?.deliveryDate).format("L");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: this.$t("shipping-order.deliveredAt"),
      field: "deliveredAt",
      cellClass: "cell-align-center",
      width: 100,
      minWidth: 100,
      filterFramework: GridDateFilterComponent,
      valueFormatter: function (params: any) {
        if (params.data && params.data.deliveredAt) {
          return moment(params.data?.deliveredAt).format("L LT");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: this.$t("shipping-order.statusCode"),
      field: "statusCode",
      cellRendererFramework: "GridStatusChipComponent",
      cellClass: "cell-align-center",
      width: 80,
      minWidth: 80,
      cellRendererParams: { type: "shipping-order" },
      filterFramework: GridListFilterComponent,
      filterParams: {
        values: Object.values(ShippingOrderStatus).map((e: ShippingOrderStatus) => {
          return { key: e, value: ShippingOrder.stringFromStatus(e) };
        }),
      },
    },
    {
      headerName: this.$t("warehouse.name"),
      field: "warehouse.name",
      flex: 1,
      cellClass: "cell-align-left",
      filterFramework: null,
    },
    {
      cellRendererFramework: "GridIconRowComponent",
      pinned: "right",
      cellClass: "cell-align-company",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
      sortable: false,
      filterFramework: null,
      suppressMovable: true,
      resizable: false,
      valueGetter: function (params: any) {
        return {
          id: params.data?.id,
          active: [store.getters["user/getUser"].role == UserRole.superadmin, true],
        };
      },
      cellRendererParams: {
        icons: [
          { icon: "mdi-pen", event: "open-shipping-order-update" },
          { icon: "mdi-magnify", to: "/shipping-order/detail/?" },
        ],
      },
    },
  ];
}
