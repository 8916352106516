














import { Component, Prop, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { ICellEditorComp } from "ag-grid-community";

@Component({
  name: "grid-import-text-editor-component",
  components: { AgGridVue },
})
export default class GridImportTextEditorComponent extends Vue implements ICellEditorComp {
  private type: string;

  beforeMount() {
    const type = this.$data.params.colDef.cellRendererParams.type;
    switch (type) {
      case "numeric":
        this.type = "number";
        break;
      case "date":
        this.type = "date";
        break;
      case "string":
      default:
        this.type = "text";
        break;
    }
  }

  getGui(): HTMLElement {
    throw new Error("Method not implemented.");
  }

  getValue(): any {
    return this.$data.params.value;
  }
}
