import { Route } from "vue-router";
import Vue from "vue";

export function setTitleFromMetadata(to: Route): void {
  if (!to.meta.withoutTitle) {
    // This goes through the matched router from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.title);
    if (nearestWithTitle) {
      document.title = `${nearestWithTitle.meta.title} | ${process.env.VUE_APP_NAME}`;
      new Vue().$eventHub.$emit("update-title", nearestWithTitle.meta.title);
    }
  } else if (!to.meta.withoutTitleRefresh) {
    new Vue().$eventHub.$emit("update-title", null);
  }
}

export function setIsMenuFromMetadata(to: Route) {
  if (to.meta.withoutMenu) {
    new Vue().$eventHub.$emit("toggle-menu", false);
  } else {
    new Vue().$eventHub.$emit("toggle-menu", true);
  }
}
