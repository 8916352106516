

























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Contact, ContactType } from "@/interfaces/contact";
import { contactApiProvider } from "@/providers/contact-api-provider";
import { Filter, QueryBuilder } from "@/providers/filter";
import { formValidation } from "@/utils/form-validation-helper";
import { Company } from "@/interfaces/company";
import { companyApiProvider } from "@/providers/company-api-provider";
import store from "@/stores/store";
import { UserRole } from "@/interfaces/user";

@Component({
  name: "contact-search-component",
})
export default class ContactSearchComponent extends Vue {
  @Prop({ default: false }) required: boolean;
  @Prop() value: Contact;
  @Prop() type: string;
  @Prop() segment: string;
  @Prop({ default: true }) isAutocomplete: boolean;
  @Prop({ default: true }) preload: boolean;
  @Prop({ default: false }) multiSupplierAvailable: boolean;
  @Prop({ default: 1 }) minSearch: number;
  @Prop({ default: 20 }) paginate: number;
  @Prop({ default: "Contact" }) label: string;
  @Prop({ default: "Rechercher un contact" }) placeholder: string;
  @Prop() company: Company;
  @Prop() owner: Company;

  rules = formValidation.getRules();
  noDataText = this.$t("contact.search_text");
  multiSupplierContact = null;

  search = "";
  loading = false;
  contacts: Array<Contact> = [];

  superAdmin = store.getters["user/getUser"].role == UserRole.superadmin;

  beforeMount() {
    if (this.value) {
      this.contacts.push(this.value);
    }

    if (this.preload && !this.value) {
      this.loadContact(this.search);
    }

    contactApiProvider
      .getContact(process.env.VUE_APP_MULTISUPPLIER_CONTACT_UUID)
      .then(async (result) => {
        this.multiSupplierContact = result;
      });
  }

  onContactChanged(value: Contact) {
    this.$emit("input", value);
  }

  @Watch("search")
  onPropertyChanged(value: string, oldValue: string) {
    // If the search field was used at least one time, we change the "no data" text.
    if (value) {
      this.noDataText = this.$t("common.loading");
    } else {
      this.noDataText = this.$t("contact.search_text", {
        type: this.$t(this.type + ".name")
          .toString()
          .toLowerCase(),
      });
    }

    if (this.contacts.length <= 1 && value == null) {
      this.loadContact(null);
    } else if (
      value == null ||
      value.length < this.minSearch ||
      (this.value != null && value == this.value.text)
    ) {
      return;
    }
    this.loadContact(value);
  }

  // It's a function that is called when the user types in the search bar.
  loadContact(value: string) {
    this.loading = true;
    // It's building the query for the API.
    const builder = QueryBuilder.whereEquals("type", this.type).whereEquals("search", value);
    if (this.isAutocomplete) {
      // It's filtering the contacts based on the search.
      builder.whereEquals("search", value);
    }
    if (this.segment) {
      // It's filtering the contacts based on the segment.
      builder.addFilter(Filter.whereEquals("owner.segments", [this.segment]));
    }
    if (this.superAdmin && this.owner) {
      builder.addFilter(Filter.whereEquals("owner.id", [this.owner.id]));
    }

    const params = builder.build();
    params.append("paginate", this.paginate.toString());

    // A function that is called when the user types in the search bar.
    contactApiProvider.getAll(params).then(async (result) => {
      // If it's carrier search
      const isCarrierFiltered = this.type == ContactType.carrier && this.owner;
      // If it's recipient search
      const isRecipientFiltered = this.type == ContactType.recipient;
      // Filtering the contacts based on the type of contact.
      if (isCarrierFiltered) {
        // It's getting the carriers from the company API.
        this.contacts = await companyApiProvider.getCarriers(
          this.owner ? this.owner.id : this.company.id,
          this.segment
        );
      } else if (isRecipientFiltered && this.owner) {
        // It's filtering the contacts based on the segment and the owner.
        this.contacts = result.data.filter((contact) => {
          return contact.segment.code === this.segment && contact.owner.id === this.owner.id;
        });
      } else if (!this.superAdmin) {
        // It's filtering the contacts based on the segment.
        this.contacts = result.data.filter(
          (contact) => (this.segment && contact.segment.code === this.segment) || !this.segment
        );
      } else {
        // It's getting the contacts from the API.
        this.contacts = result.data;
      }

      this.loading = false;

      // It's changing the "no data" text when there is no data.
      if (this.contacts.length === 0) {
        this.noDataText = this.$t("common.no_data_loading");
      }
      return Promise;
    });
  }
}
