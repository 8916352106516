import axios from "axios";
import { Vue } from "vue-property-decorator";
import store from "@/stores/store";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";

export abstract class ApiProvider {
  async getHeaders(): Promise<any> {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${await store.getters["auth/getApiToken"]}`,
      "Access-Control-Allow-Origin": "*",
    };
  }

  async patch(
    url: string,
    body: object,
    resultMsg: Array<{ text: string; type: string }> | null = null,
    allResponse = false
  ): Promise<any> {
    return await this.call("PATCH", url, body, resultMsg, allResponse);
  }

  async delete(
    url: string,
    resultMsg: Array<{ text: string; type: string }> | null = null,
    allResponse = false
  ): Promise<any> {
    return await this.call("DELETE", url, null, resultMsg, allResponse);
  }

  async put(
    url: string,
    body: object,
    resultMsg: Array<{ text: string; type: string }> | null = null,
    allResponse = false
  ): Promise<any> {
    return await this.call("PUT", url, body, resultMsg, allResponse);
  }

  async post(
    url: string,
    body: object,
    resultMsg: Array<{ text: string; type: string }> | null = null,
    allResponse = false
  ): Promise<any> {
    return await this.call("POST", url, body, resultMsg, allResponse);
  }

  async get(
    url: string,
    resultMsg: Array<{ text: string; type: string }> | null = null,
    allResponse = false
  ): Promise<any> {
    return await this.call("GET", url, null, resultMsg, allResponse);
  }

  async downloadFile(url: string, body: object | null = null, fileName: string | null = null) {
    await axios({
      url: `${process.env.VUE_APP_API_URL}${url}`,
      method: "GET",
      data: body,
      responseType: "blob",
      headers: await this.getHeaders(),
    })
      .then((response) => {
        const headerVal = response.request.getResponseHeader("Content-Disposition");
        if (headerVal) {
          try {
            fileName = headerVal.split(";")[1].split("=")[1].replace('"', "").replace('"', "");
          } catch (e) {
            fileName = "file.csv";
          }
        }
        const urlToDownload = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = urlToDownload;
        if (fileName) {
          link.setAttribute("download", fileName);
        }
        document.body.appendChild(link);
        link.click();
        // Emit event "downloaded" used to close snackbar modal
        new Vue().$eventHub.$root.$emit("downloaded", link);
      })
      .catch(async (error) => {
        error.response.data = JSON.parse(await error.response.data.text());
        await this.onError(error);
      });
  }

  async uploadFile(
    url: string,
    data: FormData,
    resultMsg: Array<{ text: string; type: string }> | null = null
  ): Promise<boolean> {
    return await axios
      .post(`${process.env.VUE_APP_API_URL}${url}`, data, {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${await store.getters["auth/getApiToken"]}`,
        },
      })
      .then((response) => {
        if (resultMsg) this.showMessage(resultMsg);
        return response.status == 200 || response.status == 204;
      })
      .catch(async (error) => {
        await this.onError(error);
        return false;
      });
  }

  private async call(
    method: any,
    url: string,
    body: object | null,
    resultMsg: Array<{ text: string; type: string }> | null = null,
    allResponse: boolean
  ): Promise<any> {
    const serverResponse = await axios
      .request({
        method,
        url: `${process.env.VUE_APP_API_URL}${url}`,
        data: body,
        headers: await this.getHeaders(),
      })
      .then((response) => response)
      .catch(async (error) => {
        await this.onError(error, resultMsg);
      });
    if (serverResponse) {
      if (resultMsg) this.showMessage(resultMsg);
      return allResponse ? serverResponse : serverResponse.data;
    }
  }

  private async onError(
    error: any,
    resultMsg: Array<{ text: string; type: string }> | null = null
  ) {
    // If the token is invalid.

    console.log(error.response);
    if (error.response) {
      if (
        error.response.status == 401 &&
        !document.location.href.endsWith("login") /* Avoid reload on bad credentials */
      ) {
        await store.dispatch("reset");
        new Vue().$eventHub.$emit("show-snackbar", {
          mainText: "Votre session est expirée.",
          type: MessageType.error,
        });
        document.location.reload();
      }
      const e = error.response.data;
      if (e.errors) {
        let secondText = "";
        for (error of Object.keys(e.errors)) {
          secondText += `${e.errors[error]}`;
          secondText += "<br>";
        }
        new Vue().$eventHub.$emit("show-snackbar", {
          mainText: e.message,
          secondText,
          type: MessageType.error,
          timeout: 10000,
        });
      } else if (e.message) {
        new Vue().$eventHub.$emit("show-snackbar", {
          mainText: e.message,
          type: MessageType.error,
          timeout: 10000,
        });
      } else if (e) {
        if (resultMsg) this.showErrorMessage(resultMsg);
      }
    } else {
      new Vue().$eventHub.$emit("show-snackbar", {
        mainText: error.message,
        type: MessageType.error,
        timeout: 10000,
      });
    }
  }

  showMessage(resultMsg: Array<{ text: string; type: string }>) {
    const info = resultMsg.find((msg) => msg.type == MessageType.information);
    const success = resultMsg.find((msg) => msg.type == MessageType.success);
    if (success) {
      new Vue().$eventHub.$emit("show-snackbar", { mainText: success.text, type: success.type });
    } else if (info) {
      new Vue().$eventHub.$emit("show-snackbar", { mainText: info.text, type: info.type });
    }
  }

  showErrorMessage(resultMsg: Array<{ text: string; type: string }>) {
    const error = resultMsg.find((msg) => msg.type == MessageType.error);
    const warning = resultMsg.find((msg) => msg.type == MessageType.warning);
    if (error || warning) {
      new Vue().$eventHub.$emit("show-snackbar", error || warning || "");
    }
  }
}

export class PaginatedResult<K> {
  data: Array<K>;

  from: number;

  to: number;

  currentPage: number;

  lastPage: number;

  perPage: number;

  total: number;

  constructor(response: any, resource: any) {
    this.data = response.data.map((e: object) => new resource(e));
    this.from = response.meta.from;
    this.to = response.meta.to;
    this.currentPage = response.meta.current_page;
    this.lastPage = response.meta.last_page;
    this.perPage = response.meta.pre_page;
    this.total = response.meta.total;
  }
}
