import { AttributeGroupType, AttributeValues } from "@/interfaces/attributes/attribute";
import store from "@/stores/store";
import { ColorChipInfo } from "@/utils/grid/grid-helper";

enum WineColor {
  red = "RG",
  blanc = "BL",
  pink = "RS",
  brune = "BR",
  amber = "AM",
  blanche = "BA",
  rousse = "RO",
  noire = "NO",
  blonde = "BO",
}

export abstract class ColorAttribute {
  static getColorFromCode(code: string): ColorChipInfo {
    const colors = store.getters["attribute/getAttribute"](
      AttributeGroupType.alcohol,
      "color"
    ).values;
    const label = colors.find((c: AttributeValues) => c.code == code)?.label ?? "?";
    switch (code) {
      case WineColor.pink:
        return { color: "kWinePink", textColor: "white--text", text: label };
      case WineColor.blanc:
        return { color: "kWineBlanche", textColor: "black--text", text: label };
      case WineColor.red:
        return { color: "kWineRed", textColor: "white--text", text: label };
      case WineColor.brune:
        return { color: "kWineBrune", textColor: "white--text", text: label };
      case WineColor.amber:
        return { color: "kWineAmber", textColor: "white--text", text: label };
      case WineColor.blanche:
        return { color: "kWineBlanche", textColor: "black--text", text: label };
      case WineColor.rousse:
        return { color: "kWineRousse", textColor: "white--text", text: label };
      case WineColor.noire:
        return { color: "black", textColor: "white--text", text: label };
      case WineColor.blonde:
        return { color: "kWineBlonde", textColor: "black--text", text: label };
      default:
        return { color: "white", textColor: "black--text", text: code };
    }
  }
}
