


































































import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import moment from "moment";
import { Order, OrderStatus } from "@/interfaces/order";
import { Grid } from "@/utils/grid/grid-helper";
import { PreferencesList } from "@/interfaces/preference";
import GridDateFilterComponent from "../_shared/grid-components/grid-filters/grid-date-filter-component.vue";
import GridFlagComponent from "../_shared/grid-components/grid-cells/grid-flag-component.vue";
import CommonSearchComponent from "../_shared/searchs/common-search-component.vue";
import GridListFilterComponent from "../_shared/grid-components/grid-filters/grid-list-filter-component.vue";
import GridRightIconsComponent from "../_shared/grid-components/grid-right-icons-component.vue";
import GridStatusChipComponent from "../_shared/grid-components/grid-cells/grid-status-chip-component.vue";
import store from "../../stores/store";
import router from "@/router/router";
import NewOrderModal from "./new-order-modal.vue";
import { Segment } from "@/interfaces/segment";
import { orderApiProvider } from "@/providers/order-api-provider";
import { User } from "@/interfaces/user";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";

@Component({
  name: "order-grid",
  components: {
    AgGridVue,
    GridIconRowComponent,
    GridRightIconsComponent,
    CommonSearchComponent,
    GridFlagComponent,
    GridStatusChipComponent,
    NewOrderModal,
  },
})
export default class OrderGrid extends Grid {
  @Prop() rowData: Array<Order>;

  user = Object.assign(new User({}), store.getters["user/getUser"]);

  $refs: {
    newOrderModal: HTMLFormElement;
    config: HTMLFormElement;
    commonGridFilter: HTMLFormElement;
  };

  async onClickNewOrder() {
    if (store.getters["createOrder/getCurrentOrderId"]) {
      this.$refs.newOrderModal.open();
    } else {
      await router.push("/order/create");
    }
  }

  generateItems() {
    const result: Array<object> = [];
    Order.getAllStatus().forEach((status) => {
      result.push({ code: status, name: Order.stringFromStatus(status) });
    });
    return result;
  }

  async beforeMount() {
    super.beforeMount();
    this.gridOptions.getRowClass = function (params: any) {
      if (params.data && params.data.statusCode == OrderStatus.draft) {
        return "kDartessNightBlue40";
      }
    };

    // Add superadmin specific columns.
    if (this.isSuperAdmin) {
      this.columns.push({
        headerName: this.$t("owner.code"),
        field: "owner.code",
        cellClass: "cell-align-center",
      } as any);
      this.defaultPreference.columns.unshift("owner.name");
      this.columns.push({
        headerName: this.$t("owner.name"),
        field: "owner.name",
        width: 150,
        minWidth: 150,
        cellClass: "cell-align-center",
      } as any);
    }
  }

  defaultPreference = {
    slug: PreferencesList.order,
    columns: [
      "reference",
      "createdAt",
      "recipient.displayName",
      "deliveryDate",
      "preparationDate",
      "preparedQuantity",
      "orderedQuantity",
      "carrier.name",
      "recipient.country",
      "warehouse.name",
      "statusCode",
    ],
  };

  columns = [
    {
      headerName: this.$t("order.reference"),
      field: "reference",
      width: 150,
      minWidth: 100,
      cellClass: "cell-align-left",
      valueFormatter: (params: any) => {
        return params.data?.reference ?? "-";
      },
    },
    {
      headerName: this.$tc("form.segment", 1),
      field: "segment",
      filterFramework: GridListFilterComponent,
      valueFormatter: function (params: any) {
        return params.data?.segment?.code ?? "-";
      },
      filterParams: {
        values: () => [...Segment.list.map((s) => ({ key: s.code, value: s.code }))],
      },
    },
    {
      headerName: this.$t("order.recipientReference"),
      field: "recipientReference",
      cellClass: "cell-align-left",
      width: 150,
      minWidth: 100,
      valueFormatter: (params: any) => {
        return params.data?.recipientReference ?? "-";
      },
    },
    {
      headerName: this.$t("recipient.name"),
      field: "recipient.displayName",
      cellClass: "cell-align-left",
      width: 150,
      minWidth: 150,
      valueFormatter: (params: any) => {
        return params.data?.recipient?.displayName ?? "-";
      },
    },
    {
      headerName: this.$t("order.orderedQuantity"),
      field: "orderedQuantity",
      filterFramework: null,
      cellClass: "cell-align-right",
      sortable: false,
      filterable: false,
    },
    {
      headerName: this.$t("order.preparedQuantity"),
      field: "preparedQuantity",
      filterFramework: null,
      cellClass: "cell-align-right",
      sortable: false,
      filterable: false,
    },
    {
      headerName: this.$t("carrier.code"),
      field: "carrier.code",
      width: 150,
      minWidth: 150,
    },
    {
      headerName: this.$t("carrier.name"),
      field: "carrier.name",
      width: 150,
      minWidth: 150,
      sortable: false,
      valueFormatter: (params: any) => {
        return params.data?.carrier?.name ?? "-";
      },
    },
    {
      headerName: this.$t("warehouse.name"),
      field: "warehouse.name",
      flex: 1,
      cellClass: "cell-align-left",
      filterFramework: null,
    },
    {
      headerName: this.$t("form.country"),
      field: "recipient.country",
      cellRendererFramework: "GridFlagComponent",
      cellClass: "cell-align-center",
    },
    {
      headerName: this.$t("form.created_date"),
      field: "createdAt",
      sort: "desc",
      filterFramework: GridDateFilterComponent,
      valueFormatter(params: any) {
        if (params.data && params.data.createdAt) {
          return moment(params.data?.createdAt).format("L");
        }
        return "-";
      },
    },
    {
      headerName: this.$t("order.deliveryDate"),
      field: "deliveryDate",
      filterFramework: GridDateFilterComponent,
      valueFormatter(params: any) {
        if (params.data && params.data.deliveryDate) {
          return moment(params.data?.deliveryDate).format("L");
        }
        return "-";
      },
    },
    {
      headerName: this.$t("order.preparationDate"),
      field: "preparationDate",
      filterFramework: GridDateFilterComponent,
      valueFormatter(params: any) {
        if (params.data && params.data.preparationDate) {
          return moment(params.data?.preparationDate).format("L");
        }
        return "-";
      },
    },
    {
      headerName: this.$t("order.statusCode"),
      field: "statusCode",
      cellRendererFramework: "GridStatusChipComponent",
      width: 165,
      minWidth: 165,
      cellRendererParams: { type: "order" },
      filterFramework: GridListFilterComponent,
      filterParams: {
        values: Object.values(OrderStatus).map((e: OrderStatus) => ({
          key: e,
          value: Order.stringFromStatus(e),
        })),
      },
    },
    {
      cellRendererFramework: "GridIconRowComponent",
      pinned: "right",
      cellClass: "cell-align-company",
      width: 110,
      minWidth: 110,
      maxWidth: 110,
      sortable: false,
      filterFramework: null,
      suppressMovable: true,
      resizable: false,
      valueGetter(params: any) {
        return {
          id: params.data?.id,
          active: [
            Order.canDeleteScope(params.data?.statusCode),
            Order.canModifyScope(params.data?.statusCode),
            Order.canShowScope(params.data?.statusCode),
          ],
        };
      },
      cellRendererParams: {
        icons: [
          { icon: "mdi-delete", event: "remove-order", color: "kDartessRed" },
          { icon: "mdi-pen", to: "/order/modify/?" },
          { icon: "mdi-magnify", to: "/order/detail/?" },
        ],
      },
    },
  ];

  sendTransmittingOrders() {
    const selectedOrders = this.gridOptions.api.getSelectedRows();
    orderApiProvider.sendTransmitingOrders(selectedOrders).then(() => {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.success,
        mainText: this.$t("order.send-transmitting-orders.success"),
      });

      this.gridOptions.api.onFilterChanged();
    });
  }
}
