






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { productApiProvider } from "@/providers/product-api-provider";
import { Product } from "@/interfaces/product";
import { User } from "@/interfaces/user";
import { Filter, Operator, QueryBuilder } from "@/providers/filter";

@Component({
  name: "product-search-component",
})
export default class ProductSearchComponent extends Vue {
  @Prop() value: QueryBuilder;

  builder = new QueryBuilder();

  debounceTimer: any;

  user = {} as User;

  loading = false;

  search = "";

  products: Array<Product> = [];

  // FILTER
  selectedProduct: Product | null = null;

  async beforeMount() {
    this.builder = this.value;
  }

  @Watch("selectedProduct")
  onProductChange(value: Product | null, oldValue: Product | null) {
    if (value == null) {
      this.builder.delete("uuid");
    } else {
      this.builder.addFilter(Filter.where("uuid", Operator.equals, value.id));
    }
    this.$emit("change", this.builder);
  }

  @Watch("search")
  onPropertyChanged(value: string, oldValue: string) {
    if (value == null || value.length < 3) {
      return;
    }
    this.loading = true;
    productApiProvider.getAllProduct(value).then((result) => {
      this.products = result;
      this.loading = false;
    });
  }

  productText(product: Product) {
    return `${product.code} - ${product.label}`;
  }
}
