import { CellEditingStoppedEvent, RowNode } from "ag-grid-community";
import { Prop, Vue } from "vue-property-decorator";
import store from "../../stores/store";
import { Attribute, AttributeValues } from "@/interfaces/attributes/attribute";
import { getLocaleText } from "./grid-fr";
import { parse } from "csv-parse/browser/esm";
import GridImportTextEditorComponent from "../../components/_shared/grid-components/grid-cell-editors/grid-import-text-editor-component.vue";
import GridImportSelectEditorComponent from "../../components/_shared/grid-components/grid-cell-editors/grid-import-select-editor-component.vue";
import router from "../../router/router";
import { AttributeHelper } from "../attribute-helper";
import GridImportBooleanEditorComponent from "../../components/_shared/grid-components/grid-cell-editors/grid-import-boolean-editor-component.vue";
import GridBoolComponent from "../../components/_shared/grid-components/grid-cells/grid-bool-component.vue";
import { MessageType } from "../../components/_shared/template/app-snackbar.vue";
import { formValidation } from "../form-validation-helper";
import GridImportMultiSelectEditorComponent from "../../components/_shared/grid-components/grid-cell-editors/grid-import-multiselect-editor-component.vue";

import "../../assets/css/ag-grid/import-grid.scss";
import "../../assets/css/ag-grid/ag-theme-dartess-import.scss";
import { csvImportApiProvider } from "@/providers/csv-import-api-provider";
import { userApiProvider } from "@/providers/user-api-provider";

export interface ImportGridData {
  value: string;
  validity: CellValidity;
}

export function formatImportGridDataToData(data: any): object {
  const result: any = {};
  for (const key in data) {
    const value: ImportGridData = data[key];
    result[key] = value.value;
  }
  return result;
}

enum CellValidity {
  "valid" = 1,
  "invalid" = 0,
  "unverified" = 2,
  "required" = 3,
}

export abstract class ImportGrid extends Vue {
  @Prop() attributeGroup: string;

  csv: string;
  attributes: Array<Attribute>;
  templateValidator: Array<string>;

  invalidRow: Map<string, RowNode> = new Map<string, RowNode>();

  attributeHelper = new AttributeHelper();

  defaultColDef: any = {
    sortable: false,
    filter: false,
    resizable: true,
    minWidth: 110,
    width: 110,
    lockPosition: true,
    editable: true,
    valueParser: (params: any) => {
      return { value: params.newValue, validity: CellValidity.unverified };
    },
    cellClass: (params: any) => {
      const cellClass: any = ["cell-align-center"];
      switch (params.value.validity) {
        case CellValidity.valid:
          cellClass.push("cell-valid");
          break;
        case CellValidity.unverified:
          cellClass.push("cell-unverified");
          break;
        case CellValidity.invalid:
          cellClass.push("cell-invalid");
          break;
        case CellValidity.required:
          cellClass.push("cell-required");
          break;
      }
      return cellClass;
    },
  };

  gridOptions: any = {
    rowHeight: 30,
    localeText: getLocaleText(),
    suppressCellSelection: true,
    suppressMenuHide: true,
    suppressDragLeaveHidesColumns: true,
    singleClickEdit: true,
    suppressFieldDotNotation: true,
  };

  frameworkComponents: object = {
    GridImportTextEditorComponent: GridImportTextEditorComponent,
    GridImportSelectEditorComponent: GridImportSelectEditorComponent,
    GridImportBooleanEditorComponent: GridImportBooleanEditorComponent,
    GridImportMultiSelectEditorComponent: GridImportMultiSelectEditorComponent,
    GridBoolComponent: GridBoolComponent,
  };

  rowData: [] = [];

  async beforeMount() {
    // Init page data.
    this.$route.meta.title = this.$t("import_csv.title");
    if (!this.$route.params.csv || !this.$route.params.template) {
      await router.push(this.previousRoute);
    } else {
      this.csv = this.$route.params.csv;
      // Get the CSV validator from the API.
      this.templateValidator = await csvImportApiProvider.getTemplate(
        this.$route.params.template,
        this.$route.params.type,
        false
      );
    }
    // Parse the CSV.
    // =======================================
    // await parse
    parse(
      this.csv,
      {
        comment: "#",
        delimiter: ";",
      },
      async (error, records: any) => {
        if (records) {
          // We generate the attribute list from the first csv line.
          await this.generateAttributeList(records[0]);
          // We delete the csv header.
          records.shift();
          // When the grid is ready, we start a global verification.
          this.gridOptions.onFirstDataRendered = (data) => {
            this.gridOptions.api.getModel().forEachLeafNode((node: RowNode, index: number) => {
              this.dataValidation(node);
            });
            this.$forceUpdate();
          };
          // Add on cell edition stopped callback.
          this.gridOptions.onCellEditingStopped = (event: CellEditingStoppedEvent) => {
            this.dataValidation(event.node);
            this.$forceUpdate();
          };
          // We convert the csv into our specific grid format.
          for (const index in records) {
            records[index] = this.convertToRowData(records[index]);
          }
          // We set the ag-grid data.
          this.rowData = records;
          // Check email not already used in DB
          const usersFound = [];
          for (const record of records) {
            if (record.email) {
              const emailFound = await userApiProvider.getUserEmail(record.email.value);
              if (emailFound !== "") {
                usersFound.push(record.email.value);
              }
            }
          }
          if (usersFound.length > 0) {
            this.$eventHub.$emit("email-used", usersFound);
            this.$eventHub.$emit("show-snackbar", {
              type: MessageType.warning,
              mainText: "Adresses emails déjà utilisées, veuillez vérifier votre fichier d'import",
              secondText: usersFound.join(" - "),
              // No timeout
              timeout: -1,
            });
          }
          // Generate the column name.
          this.gridOptions.api.setColumnDefs(this.getColumnFromCsvAttribute());
          this.gridOptions.api.sizeColumnsToFit();
        }
      }
    );
  }

  async onValidate(formData = new FormData()) {
    if (this.invalidRow.size > 0) {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.error,
        mainText: this.$tc("common.invalid_data", this.invalidRow.size),
      });
    } else {
      const params = {
        columnSeparator: ";",
        processHeaderCallback: (params: any) => params.column.colDef.field,
        processCellCallback: (params: any) => params.value.value,
      };
      const data = this.gridOptions.api.csvCreator.getDataAsCsv(params);
      const blob = new Blob([data], { type: "text/csv" });
      formData.append("file", blob, `${this.$route.params.template}.csv`);

      const response = await csvImportApiProvider.uploadFile(
        `/csv/${this.$route.params.template}`,
        formData,
        [{ type: MessageType.success, text: this.$t("import_csv.success_snackbar").toString() }]
      );
      if (response) {
        await router.push(this.previousRoute);
      }
    }
  }

  get previousRoute(): string {
    if (this.$route.name === "import-client-users") {
      return "/client-user/list";
    } else {
      const route = this.$route.path.split("/");
      route.splice(-1, 1);
      return route.join("/");
    }
  }

  /// This method generate the array of an ordered column from preferences.
  getColumnFromCsvAttribute(): Array<any> {
    const columnDefs: Array<any> = [];
    this.attributes.forEach((att: Attribute) => {
      if (att.isSelect) {
        columnDefs.push(this.getSelectColumn(att));
      } else {
        switch (att.type) {
          case "multiselect":
            columnDefs.push(this.getMultiSelectColumn(att));
            break;
          case "boolean":
            columnDefs.push(this.getBooleanColumn(att));
            break;
          default:
            columnDefs.push(this.getDefaultColumn(att));
            break;
        }
      }
    });
    return columnDefs;
  }

  // Return the ag-grid object corresponding to our column cell type.
  getSelectColumn(att: Attribute) {
    return {
      headerName: att.label,
      field: att.id,
      cellEditor: "GridImportSelectEditorComponent",
      cellRendererParams: { attribute: att },
      valueFormatter: (params: any) => {
        if (att.values) {
          const index = att.values?.findIndex(
            (val: AttributeValues) => val.code == params.value.value
          );
          if (index != -1) {
            return att.values[index].label;
          } else {
            return params.value.value;
          }
        }
      },
    };
  }

  // Return the ag-grid object corresponding to our column cell type.
  getMultiSelectColumn(att: Attribute) {
    return {
      headerName: att.label,
      field: att.id,
      cellEditor: "GridImportMultiSelectEditorComponent",
      cellRendererParams: { attribute: att },
      width: 300,
      valueFormatter: (params: any) => {
        if (att.values) {
          const values: AttributeValues[] = params.value.value.map((code: string) => {
            return att.values.find((val: AttributeValues) => val.code == code);
          });
          if (values.length > 0) {
            return values
              .map((val: AttributeValues) => {
                return val?.label ?? this.$t("import_csv.unknown");
              })
              .join(", ");
          } else {
            return params.value.value;
          }
        }
      },
    };
  }

  // Return the ag-grid object corresponding to our default column type (text).
  getDefaultColumn(att: Attribute) {
    return {
      headerName: att.label,
      field: att.id,
      cellEditor: "GridImportTextEditorComponent",
      cellRendererParams: { type: att.type },
      valueFormatter: (params: any) => {
        return params.value.value;
      },
    };
  }

  // Return the ag-grid object corresponding to our boolean column type.
  getBooleanColumn(att: Attribute) {
    return {
      headerName: att.label,
      field: att.id,
      cellRendererFramework: "GridBoolComponent",
      cellEditor: "GridImportBooleanEditorComponent",
      valueFormatter: (params: any) => {
        return params.value.value;
      },
      cellRendererParams: { isImport: true },
    };
  }

  // The csv parser return an array of data. We transform it as an object in order to send in the ag-grid.
  // We also add an attribute with the validity of the data.
  convertToRowData(data: []): object {
    const result: any = {};
    data.forEach((cell: any, index: number) => {
      const att = this.attributes[index];
      let value = cell;
      // Dans le cas d'un multiselect, on filtre les données par ne conserver que les codes qui existent.
      if (att.type == "multiselect") {
        value = [];
        cell.split(",").forEach((code: string) => {
          if (att.values.findIndex((val: AttributeValues) => val.code == code) != -1) {
            value.push(code);
          }
        });
      }
      result[att.id] = { value: value, validity: CellValidity.unverified };
    });
    return result;
  }

  async generateAttributeList(header: []) {
    this.attributes = [];
    for (let i = 0; i < header.length; i++) {
      const attribute = header[i];
      // On vérifie que la colonne match avec le template de l'api.
      if (attribute == this.templateValidator[i]) {
        const att = store.getters["attribute/getAttributeByLabel"](this.attributeGroup, attribute);
        if (att) {
          this.attributes.push(new Attribute(att));
        }
      } else {
        console.error("Invalid attribute field: ", attribute);
        const route = this.$route.path.split("/");
        route.splice(-1, 1);
        await router.push(route.join("/"));
        this.$eventHub.$emit("show-snackbar", {
          type: MessageType.error,
          mainText: "Le fichier CSV ne correspond pas avec la matrice attendue.",
        });
      }
    }
  }

  // Return a boolean "The row is valid or not"
  dataValidation(row: RowNode) {
    let rowIsValid = true; // La ligne est-elle valide ?
    Object.entries(row.data).forEach(async ([key, value], index: number) => {
      let validity = true; // L'attribut est valide.
      const v = (value as ImportGridData).value;
      const att = this.attributes[index];
      // Check if the cell is required or not.
      const noData = att.type == "multiselect" ? v.length == 0 : v === "";
      if (att.required && noData) {
        rowIsValid = false;
        this.setCellValidity(row, key, CellValidity.required);
      } else if (!noData) {
        // Check the attribute type.
        if (att.isSelect) {
          //We use the attribute rules to update the items list to draw.
          const items = this.attributeHelper.allActiveItem(
            att,
            formatImportGridDataToData(row.data)
          );
          validity = this.selectValidation(items, row, key, v);
        } else if (att.type == "boolean") {
          validity = GridBoolComponent.isBoolean(v);
        } else if (att.type == "numeric") {
          validity = !isNaN(Number(v));
        }
        // Special field verification.
        // @TODO: Passer dans une fonction spécifique ?
        // Numéro de SIRET
        if (key.includes("siret")) {
          validity = typeof formValidation.isValidSiret(v) != "string";
          // Numéro d'accise
        } else if (key.includes("excise")) {
          const countryKey = Object.entries(row.data).find(([key, index]) =>
            key.includes("country")
          )[0];
          validity = typeof formValidation.isExcise(v, row.data[countryKey].value) != "string";
        } else if (key.includes("email")) {
          // Search key named email
          const emailKey = Object.entries(row.data).find(([key, index]) =>
            key.includes("email")
          )[0];
          // Check if email form cell is already used in database
          const emailReturned = await userApiProvider.getUserEmail(row.data[emailKey].value);
          if (row.data[emailKey].value === emailReturned) {
            validity = false;
          }
        }
        // Set cell state.
        this.setCellValidity(row, key, validity ? CellValidity.valid : CellValidity.invalid);
        if (!validity) rowIsValid = false;
      }
    });

    this.gridOptions.api.redrawRows({ rowNodes: [row] });

    // We update the list of all invalid product.
    if (!rowIsValid && !this.invalidRow.has(row.id)) {
      this.invalidRow.set(row.id, row);
    }
    if (rowIsValid && this.invalidRow.has(row.id)) {
      this.invalidRow.delete(row.id);
    }
  }

  // This method validate or not a selected cell.
  selectValidation(att: AttributeValues[], node: RowNode, key: string, value: any): boolean {
    const valid = att.findIndex((val: AttributeValues) => val.code == value) != -1;
    if (valid) {
      this.setCellValidity(node, key, CellValidity.valid);
    } else {
      this.setCellValidity(node, key, CellValidity.invalid);
    }
    return valid;
  }

  setCellValidity(row: RowNode, key: string, validity: CellValidity) {
    row.data[key].validity = validity;
  }
}
