












































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { orderApiProvider } from "@/providers/order-api-provider";
import AddressFormComponent from "@/components/_shared/forms/address-form-component.vue";
import { Order, OrderStatus } from "@/interfaces/order";
import OrderSelectedStockGrid from "@/components/order/order-selected-stock-grid.vue";
import ContactSearchComponent from "@/components/_shared/searchs/contact-search-component.vue";
import router from "@/router/router";
import moment from "moment";

@Component({
  name: "order-list-page",
  computed: {
    OrderStatus() {
      return OrderStatus;
    },
  },
  components: {
    OrderSelectedStockGrid,
    AppIconTextButton,
    AddressFormComponent,
    ContactSearchComponent,
  },
})
export default class OrderDetailPage extends Vue {
  order: Order | null = null;
  loadingPdf = false;

  get deliveryDate() {
    return moment(this.order.deliveryDate).format("L");
  }

  async downloadPdf() {
    this.loadingPdf = true;
    const pdfContent = await orderApiProvider.getPdf(this.$route.params.order_id);

    const linkSource = `data:application/pdf;base64,${pdfContent}`;
    const downloadLink = document.createElement("a");
    const fileName = `Commande ${this.order.reference}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

    this.loadingPdf = false;
  }

  getBottleCount() {
    return this.order.stocks.reduce((accumulator, stock) => {
      return accumulator + stock.ordered;
    }, 0);
  }

  async beforeMount() {
    this.order = await orderApiProvider.getOrder(this.$route.params.order_id);
    if (this.order) {
      this.$eventHub.$emit(
        "update-title",
        this.$t("order.detail_page_title", { code: this.order.reference })
      );
      this.breadcrumbs.push({
        text: this.$t("order.detail_page_title", { code: this.order.reference }),
      });
    } else {
      await router.push("/404");
    }
    // Retrieve carrier and service already set
    const carrier = await orderApiProvider.getCarrier(this.$route.params.order_id);
    const service = await orderApiProvider.getService(this.$route.params.order_id);
    this.order.carrier = carrier;
    this.order.service = service;
  }

  breadcrumbs = [
    { text: this.$t("app.title") },
    { text: this.$t("order.list_page_title"), href: "/order/list" },
  ];

  $refs: {
    orderDetailModal: HTMLFormElement;
  };
}
