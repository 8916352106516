



































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import store from "../../../stores/store";
import { Warehouse } from "@/interfaces/warehouse";
import { Filter, QueryBuilder } from "@/providers/filter";

@Component({
  name: "common-search-component",
})
export default class CommonSearchComponent extends Vue {
  @Prop() value: QueryBuilder;

  @Prop({ default: true }) withWarehouse: boolean;

  @Prop({ default: "warehouse.uuid" }) warehouseField: string;

  @Prop({ default: true }) withSearch: boolean;

  builder = new QueryBuilder();

  debounceTimer: any;

  user = store.getters["user/getUser"];

  search = "";

  selectedWarehouses: Array<Warehouse> = [];

  async beforeMount() {
    this.builder = this.value;
    if (this.withWarehouse) {
      const cacheWarehouse: Array<Warehouse> = store.getters["user/getSelectedWarehouses"];
      if (cacheWarehouse.length > 0) {
        this.selectedWarehouses = cacheWarehouse;
      } else {
        this.selectedWarehouses = this.user.warehouses;
      }
    }
  }

  mounted() {
    // Event : search triggered by an other component
    this.$eventHub.$on("search", (value: string) => (this.search = value));
  }

  @Watch("selectedWarehouses")
  onWarehousesChange(value: Array<Warehouse> | null, oldValue: Array<Warehouse> | null) {
    // Disable filter when all selected or none selected
    if (value == null || value.length == 0 || value.length === this.user.warehouses.length) {
      this.builder.delete(this.warehouseField);
    } else {
      this.builder.addFilter(
        Filter.whereEquals(
          this.warehouseField,
          value.map((w) => w.id)
        )
      );
    }
    store.commit("user/setSelectedWarehouses", this.selectedWarehouses);
    this.$emit("change", this.builder);
  }

  @Watch("search")
  onSearchChange(value: string, oldValue: string) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    if (value == null) {
      this.builder.delete("search");
      this.$emit("change", this.builder);
    } else {
      this.builder.addFilter(Filter.whereEquals("search", value));
      this.debounceTimer = setTimeout(() => this.$emit("change", this.builder), 500);
    }
  }

  isAllWarehouseSelected(): boolean {
    return this.selectedWarehouses.length === this.user.warehouses.length;
  }

  isSomeWarehouseSelected(): boolean {
    return this.selectedWarehouses.length > 0 && !this.isAllWarehouseSelected();
  }

  toggle() {
    this.$nextTick(() => {
      if (this.isAllWarehouseSelected()) {
        this.selectedWarehouses = [];
      } else {
        this.selectedWarehouses = this.user.warehouses;
      }
    });
  }
}
