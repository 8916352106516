













































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import ModalComponent from "../_shared/modals/modal-component.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import ProductCard from "@/components/_shared/cards/product-card.vue";
import { userApiProvider } from "@/providers/user-api-provider";
import { formValidation } from "@/utils/form-validation-helper";
import { AppScope } from "@/utils/app-scope";
import { AttributeGroupType } from "@/interfaces/attributes/attribute";
import { MessageType } from "../_shared/template/app-snackbar.vue";
import store from "../../stores/store";
import { Warehouse } from "@/interfaces/warehouse";
import { User } from "@/interfaces/user";

@Component({
  name: "user-detail-modal",
  components: { ModalComponent, AppIconTextButton, ProductCard },
})
export default class UserDetailModal extends Mixins(ModalComponent) {
  formSending = false;

  user: User = new User({});

  warehouseSelect: Warehouse[] = store.getters["user/getUser"].warehouses;

  countrySelect = store.getters["attribute/getAttribute"](
    AttributeGroupType.contacts,
    "address.country"
  ).values;

  scopeSelect = AppScope.values.map((k: string) => ({ name: AppScope.getString(k), value: k }));

  rules = formValidation.getRules();

  $refs: {
    userForm: HTMLFormElement;
    modal: HTMLFormElement;
  };

  onOpening(value: User) {
    this.user = new User({ ...value }); // On réalise un deep-clone du user.
    this.toggleModal(true);
  }

  public onClose = function (this) {
    this.user = new User({});
  };

  async validateEdition() {
    if (this.$refs.userForm.validate()) {
      this.formSending = true;
      const response = await userApiProvider.updateUser(this.user);
      this.formSending = false;
      if (response) {
        this.user = response;
        this.toggleModal(false);
      }
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.warning,
        mainText: this.$t("form-validation.error"),
      });
    }
  }

  mounted() {
    this.$eventHub.$on("open-user-detail-modal", this.onOpening);
  }

  beforeDestroy() {
    this.$eventHub.$off("open-user-detail-modal");
  }
}
