import { ApiProvider } from "@/providers/api-provider";

class CsvImportApiProvider extends ApiProvider {
  async getTemplate(templateCode: string, type: string, returnFile = true) {
    const body = {
      type: type ?? null,
      returnFile: returnFile,
    };
    if (returnFile) {
      await this.downloadFile(`/csv/${templateCode}`, body);
    } else {
      return await this.post(`/csv/${templateCode}`, body);
    }
  }
}

export const csvImportApiProvider = new CsvImportApiProvider();
