









import { Component, Vue } from "vue-property-decorator";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import ProductDetailModal from "@/components/_shared/modals/product-detail-modal.vue";
import StockMovementGrid from "@/components/stock/stock-movement-grid.vue";
import { DataSource } from "@/utils/grid/datasource";
import { stockMovementApiProvider } from "@/providers/stock-movement-api-provider";

@Component({
  name: "stock-movement-list-page",
  components: { StockMovementGrid, AppIconTextButton, ProductDetailModal },
})
export default class StockMovementListPage extends Vue {
  datasource: DataSource;

  breadcrumbs = [{ text: this.$t("app.title") }, { text: "Mouvement de stocks" }];

  async beforeMount() {
    this.$route.meta.title = "Mouvement de stocks";
    this.datasource = DataSource.from(stockMovementApiProvider);
  }
}
