
































import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { IFilterComp } from "ag-grid-community";
import { Condition, Operator } from "@/providers/filter";

@Component({
  name: "grid-numeric-filter-component",
  components: { AgGridVue },
})
export default class GridNumericFilterComponent extends Vue implements IFilterComp {
  label: string;
  value: string | null;
  debounceTimer: any;
  operators = [
    {
      name: this.$t("grid.filter.greaterThan"),
      value: Operator.greaterThan,
    },
    {
      name: this.$t("grid.filter.lessThan"),
      value: Operator.lessThan,
    },
    {
      name: this.$t("grid.filter.equals"),
      value: Operator.equals,
    },
  ];
  operator = Operator.equals;

  beforeMount() {
    this.value = null;
    this.label = this.$data.params.colDef.headerName;
  }

  updateFilter() {
    if (this.value == "") {
      this.value = null;
    }
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    if (this.value == null) {
      this.$data.params.filterChangedCallback();
    } else {
      this.debounceTimer = setTimeout(() => this.$data.params.filterChangedCallback(), 500);
    }
  }

  init(params: any) {
    throw new Error("Method not implemented.");
  }

  getGui(): HTMLElement {
    throw new Error("Method not implemented.");
  }

  isFilterActive() {
    return this.value != null && this.value.length > 0;
  }

  doesFilterPass(params: any) {
    const value = this.$data.params.valueGetter(params.node);
    if (this.operator == Operator.greaterThan) {
      return value > this.value;
    } else if (this.operator == Operator.lessThan) {
      return value < this.value;
    } else {
      return value == this.value;
    }
  }

  getModel() {
    if (this.value != null) {
      return Condition.where(this.operator, this.value);
    } else {
      return null;
    }
  }

  setModel(model: any) {
    this.operator = model.filter.operator ?? null;
    this.value = model.filter.value ?? null;
  }
}
