























import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import GridListDetailModal from "@/components/_shared/modals/grid-list-detail-modal.vue";

@Component({
  name: "grid-list-component",
  components: { AgGridVue, GridListDetailModal },
})
export default class GridListComponent extends Vue {
  values: string[];
  icon = "";
  withIcon = true;
  justify = "";
  maxShowed: number | undefined;

  isDetailPanel = false;

  $refs: {
    listDetailModal: HTMLFormElement;
  };

  beforeMount() {
    const params = this.$data.params.value;
    this.values = params.values;
    this.icon = params.icon;
    this.withIcon = params.withIcon;
    this.justify = params.justify;
    this.maxShowed = params.maxShowed;
    if (this.maxShowed && this.maxShowed > this.values.length) {
      this.maxShowed = undefined;
    }
  }

  get getElements(): string[] {
    const result = [...this.values];
    return this.maxShowed ? result.splice(0, this.maxShowed) : result;
  }
}
