export interface Segment {
  id: string;
  code: string;
  name: string;
}

export class Segment {
  static list = [
    { id: "B2B", code: "B2B", name: "B2B" },
    { id: "B2C", code: "B2C", name: "B2C" },
  ]; // Could be retrieved at /api/segments
  constructor(data: Partial<Segment> = {}) {
    Object.assign(this, data);
  }
}
