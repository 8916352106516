

















































































































































import { Component, Vue } from "vue-property-decorator";
import AppExpansionPanelHeader from "@/components/_shared/panels/app-expansion-panel-header.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { formValidation } from "@/utils/form-validation-helper";
import { User, UserRole } from "@/interfaces/user";
import store from "../../stores/store";
import { Warehouse } from "@/interfaces/warehouse";
import { userApiProvider } from "@/providers/user-api-provider";
import router from "@/router/router";
import { Company } from "@/interfaces/company";
import { AppScope } from "@/utils/app-scope";
import { companyApiProvider } from "@/providers/company-api-provider";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";

@Component({
  name: "create-user-page",
  components: { AppExpansionPanelHeader, AppIconTextButton },
})
export default class CreateUserPage extends Vue {
  rules = formValidation.getRules();

  formSending = false;

  emailConfirmation = "";

  warehouseSelect: Warehouse[] = store.getters["user/getUser"].warehouses;

  companySelect: Company[] = [];

  scopeSelect = AppScope.values.map((k: string) => ({ name: AppScope.getString(k), value: k }));

  newUser = new User({ role: UserRole.admin, active: true });

  $refs: {
    userForm: HTMLFormElement;
  };

  breadcrumbs = [
    { text: this.$t("app.title") },
    { text: this.$t("client-user.customers"), href: "/user/list" },
    { text: this.$t("client-user.create_user") },
  ];

  async beforeMount() {
    this.$route.meta.title = this.$t("client-user.create_user");
    this.companySelect = await companyApiProvider.getCompanies();
  }

  async validateCreation() {
    if (this.$refs.userForm.validate()) {
      this.formSending = true;
      const response = await userApiProvider.createUser(this.newUser);
      if (response) {
        await router.push("/user/list");
      } else {
        this.formSending = false;
      }
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.warning,
        mainText: this.$t("form-validation.error"),
      });
    }
  }
}
