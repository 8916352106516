






















































































import { Component, Vue } from "vue-property-decorator";
import AppTitle from "./app-title.vue";
import { TranslateResult } from "vue-i18n";
import { AppScope } from "@/utils/app-scope";
import { User, UserRole } from "@/interfaces/user";
import store from "@/stores/store";
import StatsPage from "@/pages/stats-page.vue";

@Component({
  name: "app-header",
  components: { AppTitle },
})
export default class AppDrawer extends Vue {
  mounted() {
    this.$eventHub.$on("toggle-menu", (value: boolean) => (this.isVisible = value));
  }

  isVisible = this.$vuetify.breakpoint.mdAndUp;

  isMini = store.getters["user/getMenuIsVisible"];

  user = Object.assign(new User(store.getters["user/getUser"]));

  get currentHoverId() {
    return store.getters["user/getMenuCurrentHover"];
  }

  get selectedItemId() {
    return store.getters["user/getMenuCurrentSelected"];
  }

  async onItemClick(id: number, isParent: boolean, currentHoverId: number, parentId: number) {
    if (id === this.selectedItemId) {
      document.location.reload(true);
    }
    store.commit("user/setMenuCurrentHover", parentId === 0 ? id : currentHoverId);
    if (!isParent) {
      // Close mobile menu after click on item
      if (this.$vuetify.breakpoint.smAndDown) {
        this.isVisible = false;
      }
      store.commit("user/setMenuCurrentSelected", id);
    }
  }

  private generateMenuClass(id: number, parentId: number): string {
    let result = "px-2 pl-3 justify-content-start ";
    result += this.selectedItemId === id ? "selected-menu-item " : "menu-item ";
    result += parentId !== 0 ? "under-menu-item" : "py-1";
    return result;
  }

  beforeDestroy() {
    this.$eventHub.$off("toggle-menu");
  }

  onDisplayMiniClick() {
    this.isMini = !this.isMini;
    store.commit("user/setMenuIsVisible", this.isMini);
  }

  get items(): DrawerItem[] {
    // The list of items in the drawer. Key: 'IconName', 'Title', 'Link', 'Active', 'Child'
    return [
      {
        id: 1000,
        icon: "mdi-home",
        title: this.$t("home.title"),
        link: "/dashboard",
        active: true,
        parentId: 0,
        isParent: false,
      },
      ...(this.user.role >= UserRole.user ? this.getUserMenu() : []),
      ...(this.user.role === UserRole.admin ? this.getAdminOnlyMenu() : []),
      ...(this.user.role >= UserRole.admin ? this.getAdminMenu() : []),
      ...(this.user.role >= UserRole.superadmin ? this.getSuperAdminMenu() : []),
      {
        id: 1001,
        icon: "mdi-cog",
        title: this.$t("settings.title"),
        link: "/params",
        active: true,
        parentId: 0,
        isParent: false,
      },
    ];
  }

  /// USER MENU.
  getUserMenu(): DrawerItem[] {
    return [
      {
        id: 1,
        icon: "mdi-dolly",
        title: this.$t("reception.title"),
        link: "/reception/list",
        active: this.user.hasScope(AppScope.reception),
        parentId: 0,
        isParent: false,
      },
      {
        id: 2,
        icon: "mdi-cart",
        title: this.$t("order.title"),
        link: "/order/list",
        active: this.user.hasScope(AppScope.order),
        parentId: 0,
        isParent: false,
      },
      {
        id: 3,
        icon: "mdi-warehouse",
        title: this.$t("stock.title"),
        link: "",
        active: this.user.hasScope(AppScope.stock) || this.user.hasScope(AppScope.stockMovement),
        parentId: 0,
        isParent: true,
      },
      {
        id: 31,
        icon: "mdi-package-variant",
        title: this.$t("stock.list_page_title"),
        link: "/stock/list",
        active: this.user.hasScope(AppScope.stock),
        parentId: 3,
        isParent: false,
      },
      {
        id: 32,
        icon: "mdi-clipboard-flow",
        title: this.$t("stock.movement_page_title"),
        link: "/stock/movement",
        active: this.user.hasScope(AppScope.stockMovement),
        parentId: 3,
        isParent: false,
      },
      {
        id: 4,
        icon: "mdi-truck-delivery",
        title: this.$t("shipping-order.title"),
        link: "/shipping-order/list",
        active: this.user.hasScope(AppScope.transportation),
        parentId: 0,
        isParent: false,
      },
      /* {
                id: 33, icon: 'mdi-timeline-text-outline', title: 'En cours de stock', link: '/stock/ongoing',
                active: this.user.hasScope(AppScope.stock), parentId:3, isParent: false
            },
            {
                id: 5, icon: 'mdi-cube-send', title: 'O.F', link: '',
                active: true, parentId:0, isParent: true
            },
            {
                id: 51, icon: 'mdi-truck-fast', title: 'Ordre logistique', link: '/supplier-order/logistic',
                active: true, parentId:5, isParent: false
            },
            {
                id: 52, icon: 'mdi-package-variant', title: 'Ordre de production', link: '/supplier-order/manufacturing',
                active: true, parentId:5, isParent: false},
            {
                id: 6, icon: 'mdi-file-document', title: 'Facture', link: '/bill',
                active: this.user.hasScope(AppScope.bill), parentId:0, isParent: false
            }, */
      {
        id: 7,
        icon: "mdi-database",
        title: this.$t("repository.title"),
        link: "",
        active: this.user.hasScope([
          AppScope.product,
          AppScope.supplier,
          AppScope.recipient,
          AppScope.carrier,
        ]),
        parentId: 0,
        isParent: true,
      },
      // Supplier & recipient module is active for transport & logistic client.
      // Product & carrier module is active for logistic client only.
      {
        id: 71,
        icon: "mdi-bottle-wine",
        title: this.$t("product.name"),
        link: "/product/list",
        active: this.user.hasScope(AppScope.product),
        parentId: 7,
        isParent: false,
      },
      {
        id: 72,
        icon: "mdi-account-arrow-right",
        title: this.$t("supplier.name"),
        link: "/supplier/list",
        active: this.user.hasScope(AppScope.supplier),
        parentId: 7,
        isParent: false,
      },
      {
        id: 73,
        icon: "mdi-account-arrow-left",
        title: this.$t("recipient.name"),
        link: "/recipient/list",
        active: this.user.hasScope(AppScope.recipient),
        parentId: 7,
        isParent: false,
      },
      /*{
        id: 74,
        icon: "mdi-truck",
        title: this.$t("carrier.name"),
        link: "/carrier/list",
        // active: this.user.hasScope(AppScope.carrier),
        // Only Dartess can set carrier for customer and edit carriers
        active: this.user.company.code === "DARTE",
        parentId: 7,
        isParent: false,
      },*/
      /*            {
                id: 8, icon: 'mdi-folder-open', title: 'Document', link: '/document',
                active: this.user.hasScope(AppScope.document), parentId:0, isParent: false
            }, */
    ];
  }

  getAdminMenu() {
    return [];
  }

  getSuperAdminMenu() {
    return [
      {
        id: 74,
        icon: "mdi-truck",
        title: this.$t("carrier.name"),
        link: "/carrier/list",
        // active: this.user.hasScope(AppScope.carrier),
        // Only Dartess can set carrier for customer and edit carriers
        active: true,
        parentId: 7,
        isParent: false,
      },
      {
        id: 10,
        icon: "mdi-account",
        title: this.$t("company.title"),
        link: "/company/list",
        active: true,
        parentId: 0,
        isParent: false,
      },
      {
        id: 11,
        icon: "mdi-truck-fast-outline",
        title: this.$t("company.title_carrier"),
        link: "/company/carriers",
        active: true,
        parentId: 0,
        isParent: false,
      },
      {
        id: 999,
        icon: "mdi-trending-up",
        title: this.$t("stats.title"),
        link: "/stats",
        active: true,
        parentId: 0,
        isParent: false,
      },
    ];
  }

  getAdminOnlyMenu() {
    return [
      {
        id: 10,
        icon: "mdi-account",
        title: this.$t("client-user.title"),
        link: "/client-user/list",
        active: true,
        parentId: 0,
        isParent: false,
      },
    ];
  }
}

interface DrawerItem {
  id: number;
  icon: string;
  title: string | TranslateResult;
  link: string;
  active: boolean;
  parentId: number;
  isParent: boolean;
}
