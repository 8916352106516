



























import { Component, Vue } from "vue-property-decorator";
import { activityLogApiProvider } from "@/providers/activity-log-api-provider";
import { DxExport, DxFieldChooser, DxPivotGrid, DxStateStoring } from "devextreme-vue/pivot-grid";
import { loadMessages, locale } from "devextreme/localization";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import frMessages from "devextreme/localization/messages/fr.json";

// DevExtreme localization
locale(navigator.language);
loadMessages(frMessages);
@Component({
  name: "stats-page",
  components: {
    DxPivotGrid,
    DxFieldChooser,
    DxExport,
    DxStateStoring,
  },
})
export default class ParamsPage extends Vue {
  private stats = null;
  private statsJson = null;
  private loading = true;
  async beforeMount() {
    const response = await activityLogApiProvider.getData();
    this.loading = false;
    this.statsJson = response;
    this.stats = new PivotGridDataSource({
      fields: [
        {
          caption: "Code compagnie",
          dataField: "user.company.code",
          area: "row",
        },
        {
          caption: "Nombre",
          dataField: "id",
          area: "data",
        },
        {
          caption: "Type",
          width: 150,
          dataField: "type",
          area: "row",
        },
        {
          caption: "Page",
          dataField: "pageName",
          width: 150,
          area: "row",
        },
        {
          dataField: "createdAt",
          dataType: "date",
          area: "column",
        },
      ],
      store: response,
    });
  }

  jsonToCsv(jsonArray) {
    // Création de l'en-tête CSV
    const header = "Type;Page;Date;Compagnie;Nom;Prénom;Email";

    // Fonction pour formater une date en format ISO 8601
    function formatDate(dateString) {
      return new Date(dateString).toISOString();
    }

    // Fonction pour formater une ligne CSV à partir d'un objet JSON
    function formatCsvRow(item) {
      const { type, pageName, createdAt, user } = item;
      const { firstname, lastname, email } = user;
      const { name: companyName } = user.company;
      const date = formatDate(createdAt);
      return `${type};${pageName};${date};${companyName};${lastname};${firstname};${email}`;
    }

    // Construction du CSV à partir du tableau JSON
    const csvArray = jsonArray.map((item) => formatCsvRow(item));
    const csvRows = csvArray.join("\r\n");

    // Ajout de l'entête UTF-8 avec des caractères spéciaux pour assurer leur affichage correct
    const headerUtf8 = "\uFEFF" + header + "\r\n";
    return headerUtf8 + csvRows;
  }

  downloadCsv(filename) {
    const csv = this.jsonToCsv(this.statsJson);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}
