


















import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { Service } from "@/interfaces/service";
import { Company } from "@/interfaces/company";
import { CompanyCarrier } from "@/interfaces/company-carrier";
import { Contact } from "@/interfaces/contact";
import { companyApiProvider } from "@/providers/company-api-provider";

@Component({
  name: "grid-sevice-select-component",
  components: { AgGridVue },
})
export default class GridServiceSelectComponent extends Vue {
  company: Company;
  availableServices: Service[];
  servicesActives: Service[];

  data() {
    return {
      servicesActives: [],
    };
  }

  beforeMount() {
    const value = this.$data.params.value;
    this.company = value.company;
    this.availableServices = this.$data.params.availableServices;
    this.servicesActives = value.servicesActives;
  }

  async updateCompany() {
    this.company.carriers = [];
    this.servicesActives.forEach((service) => {
      const companyCarrier = new CompanyCarrier({
        service: service,
        company: new Company({ id: this.company.id }),
        carrier: this.findCarrierByService(service.id),
        segment: { id: "B2C", code: "B2C", name: "B2C" },
      });
      this.company.carriers.push(companyCarrier);
    });

    this.$data.params.api.refreshCells({ rowNodes: [this.$data.params.node] });
    const response = await companyApiProvider.updateCompany(this.company);
  }

  findCarrierByService(serviceId: string) {
    let findedCarrier: Contact;
    this.$data.params.carriers.forEach((carrier: Contact) => {
      carrier.services.forEach((service: Service) => {
        if (service.id === serviceId) {
          findedCarrier = carrier;
        }
      });
    });
    return findedCarrier;
  }
}
