


































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import AppExpansionPanelHeader from "@/components/_shared/panels/app-expansion-panel-header.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { productApiProvider } from "@/providers/product-api-provider";
import AddressFormComponent from "@/components/_shared/forms/address-form-component.vue";
import { Product } from "@/interfaces/product";
import ReceptionProductGrid from "@/components/reception/reception-product-grid.vue";
import ReceptionSelectedStockGrid from "@/components/reception/reception-selected-stock-grid.vue";
import ProductDetailModal from "@/components/_shared/modals/product-detail-modal.vue";
import ContactSearchComponent from "@/components/_shared/searchs/contact-search-component.vue";
import { receptionApiProvider } from "@/providers/reception-api-provider";
import { Reception, ReceptionStatus } from "@/interfaces/reception";
import store from "../../stores/store";
import { Warehouse } from "@/interfaces/warehouse";
import router from "@/router/router";
import { Stock } from "@/interfaces/stock";
import { Filter } from "@/providers/filter";
import { formValidation } from "@/utils/form-validation-helper";
import StockCreationModal from "@/components/reception/stock-creation-modal.vue";
import StockReceptionModal from "@/components/reception/stock-reception-modal.vue";
import { contactApiProvider } from "@/providers/contact-api-provider";
import { ContactType } from "@/interfaces/contact";
import DatePickerFormComponent from "@/components/_shared/forms/date-picker-form-component.vue";
import { DataSource } from "@/utils/grid/datasource";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";
import moment from "moment";
import { Company } from "@/interfaces/company";
import { companyApiProvider } from "@/providers/company-api-provider";
import { UserRole } from "@/interfaces/user";

@Component({
  name: "create-reception-page",
  components: {
    AppExpansionPanelHeader,
    AppIconTextButton,
    ReceptionProductGrid,
    ReceptionSelectedStockGrid,
    AddressFormComponent,
    ProductDetailModal,
    StockReceptionModal,
    ContactSearchComponent,
    StockCreationModal,
    DatePickerFormComponent,
  },
})
export default class CreateReceptionPage extends Vue {
  productDatasource: DataSource;
  rules = formValidation.getRules();

  private formSending = false;
  private currentStep = 1;
  private company: Company = store.getters["user/getUser"].company;
  private warehouseSelect: Warehouse[] = store.getters["user/getUser"].warehouses;
  private isLoading = true;
  private today = moment().toISOString().substring(0, 10);

  private get isModifyMode() {
    return (
      this.$route.params.reception_id != undefined &&
      this.reception.statusCode != ReceptionStatus.draft
    );
  }

  superAdmin = store.getters["user/getUser"].role == UserRole.superadmin;

  reception: Reception = new Reception();

  companySelect: Company[] = [];

  $refs: {
    productGrid: HTMLFormElement;
    cartGrid: HTMLFormElement;
    productDetailModal: HTMLFormElement;
    stockModal: HTMLFormElement;
    stockModalCreation: HTMLFormElement;
    receptionForm: HTMLFormElement;
  };

  breadcrumbs = [
    { text: this.$t("app.title") },
    { text: this.$t("reception.list_page_title"), href: "/reception/list" },
    { text: this.$t("reception.create_page_title") },
  ];

  /**
   * This methods was called when the page is open in modification mode.
   * On modifie le breadcrumb et le titre de la page.
   */
  modifyPageConfiguration(reception: Reception) {
    const title = this.$t("reception.modify_page_title", { code: reception.reference });
    this.breadcrumbs = [
      { text: this.$t("app.title") },
      { text: this.$t("reception.list_page_title"), href: "/reception/list" },
      { text: this.$t("reception.modify_page_title", { code: reception.reference }) },
    ];
    this.$eventHub.$emit("update-title", title);
  }

  async beforeMount() {
    // On récupère l'utilisateur (si les segments ont changés...)
    await store.dispatch("user/fetchUser");

    this.$route.meta.title = this.$t("reception.create_page_title");

    if (this.superAdmin) {
      this.companySelect = await companyApiProvider.getCompanies();
    } else {
      const user = store.getters["user/getUser"];
      this.reception.owner = user.company;
      // Par défaut l'entrepôt sélectionné est celui de l'utilisateur.
      this.reception.warehouse = user.warehouse;
    }

    // Initialisation de la datasource des produits.
    this.productDatasource = DataSource.from(productApiProvider).withFilters(async () => {
      const filters = [];

      // Remove hidden products
      filters.push(Filter.whereEquals("hidden", false));

      if (this.reception) {
        if (this.reception.warehouse) {
          filters.push(Filter.whereEquals("warehouse.uuid", this.reception.warehouse.id));
        }
        if (this.reception.owner) {
          filters.push(Filter.whereEquals("owner", this.reception.owner.id));
        }
      }
      return filters;
    });

    /** Edition mode ----------------------------- */
    if (this.$route.params.reception_id) {
      // Can I modify this reception ?
      const response = await receptionApiProvider.getReception(this.$route.params.reception_id);
      this.reception = response;
      if (response && this.reception.canModifyScope()) {
        this.modifyPageConfiguration(response);
      } else {
        await router.replace(`/reception/list`);
      }
      /** Creation mode ----------------------------- */
    } else {
      // If a reception is currently in creation we get it.
      if (store.getters["createReception/getCurrentReceptionId"]) {
        const response = await receptionApiProvider.getReception(
          store.getters["createReception/getCurrentReceptionId"]
        );
        if (response) {
          this.reception = response;
        }
        // Is a new reception.
      } else {
        // S'il n'y a qu'un entrepôt de disponible, on le sélectionne et on passe directement à l'étape 2.
        if (this.warehouseSelect.length == 1) {
          this.reception.warehouse = this.warehouseSelect[0];
        }
        // Si le client est mono segment.
        if (this.company.segments.length == 1) {
          this.reception.segment = this.company.segments[0];
        }
      }
    }
    // Lors de l'ajout d'un transporteur.
    if (this.$route.query.supplier_id) {
      // On modifie la réception.
      this.reception.supplier = await contactApiProvider.getContact(
        this.$route.query.supplier_id as string
      );
    }

    // Gestion de l'affichage.
    this.setCurrentStep();
    this.isLoading = false;
    this.$forceUpdate();
  }

  async mounted() {
    this.$eventHub.$on("add-product-to-cart", (data: Product) => this.onAddToCart(data));
    this.$eventHub.$on("remove-stock-from-cart", (data: Stock) => this.deleteStockFromCart(data));
    this.$eventHub.$emit("toggle-title-divider", false);

    // Autocomplete search field on new creation.
    // If we create a new product, we go on the 2nd steps.
    if (this.$route.params.product_id) {
      const product = await productApiProvider.getProductById(this.$route.params.product_id);
      // Wait for all children is loaded
      this.$nextTick(() => {
        if (this.$refs.productGrid) {
          if (product) this.$refs.productGrid.$refs.searchComponent.search = product.label;
        }
      });
    }
  }

  /**
   * On company change.
   * @param company
   */
  onCompanyChange(company: Company) {
    this.warehouseSelect = company.warehouses;
    this.reception.owner = company;
  }

  /**
   * Cette fonction détermine l'étape actuel à afficher lors du chargement de la page.
   */
  setCurrentStep() {
    // Si l'on ajoute un produit on retourne sur l'étape du panier.
    if (this.$route.params.product_id) {
      this.currentStep = 2;
      return;
    }
    if (this.reception.supplier) {
      this.currentStep = 3;
      return;
    }
    // Si l'entrepôt est renseigné et le segment également on passe à l'étape 2.
    if (this.reception.warehouse) {
      // Si activité mutualisé => bypass du segment.
      if (this.reception.segment || this.reception.owner?.isPooledActivity) {
        this.currentStep = 2;
      }
    }
  }

  async addStockToCart(stock: Stock, quantity: number) {
    const stockInCart = this.reception.stocks.find((s) => s.equals(stock));
    if (stockInCart != null) {
      stockInCart.expected = quantity;
    } else {
      stock.expected = quantity;
      this.reception.stocks.push(stock);
    }

    await this.saveReception();
    this.resetProductQuantity(stock.product);
    this.refreshCart();
  }

  // Delete a stock of the cart and update the draft reception in API.
  async deleteStockFromCart(stock: Stock) {
    const stockTemp = stock;
    const index = this.reception.stocks.findIndex((s: Stock) => s.equals(stock));
    this.reception.stocks.splice(index, 1);
    const response = await this.saveReception();
    if (response) {
      this.refreshCart();
      this.refreshProduct(stockTemp.product);
    }
  }

  // Refresh the product row in the grid.
  refreshProduct(product: Product) {
    const node = this.$refs.productGrid.gridOptions.api.getRowNode(product.id);
    this.$refs.productGrid.gridOptions.api.redrawRows({ rowNodes: [node] });
  }

  // Reset the quantity to 0 in the product grid.
  resetProductQuantity(product: Product) {
    const node = this.$refs.productGrid.gridOptions.api.getRowNode(product.id.toString());
    node.setDataValue("quantity", null);
  }

  // When we validate the step one, we reset the cart and show only the product of the current selected warehouse.
  stepOneValidate() {
    if (this.currentStep == 1 && this.reception.warehouse != null) {
      this.reception.stocks = [];
      this.$refs.productGrid.onFilterChange();
      this.currentStep = 2;
    }
  }

  stepTwoValidate() {
    if (this.currentStep == 2 && this.reception.stocks.length > 0) {
      this.currentStep = 3;
    }
  }

  // Validate the reception.
  async validateReception(validated = false) {
    let isValid = true;
    let msg: any;
    if (validated) {
      isValid = this.$refs.receptionForm.validate();
      if (isValid) {
        this.reception.statusCode = ReceptionStatus.created;
        msg = [
          { type: MessageType.success, text: this.$t("reception.create_validate_success_msg") },
        ];
      }
    } else {
      this.reception.statusCode = ReceptionStatus.draft;
      msg = [{ type: MessageType.success, text: this.$t("reception.create_draft_success_msg") }];
    }
    if (isValid) {
      this.formSending = true;
      const response = await this.saveReception(msg);
      if (response) {
        await store.dispatch("createReception/reset");
        await router.push(`/reception/list`);
      } else {
        this.formSending = false;
        this.reception.statusCode = ReceptionStatus.draft;
      }
    } else {
      // The front validator catcher.
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.warning,
        mainText: this.$t("form-validation.error"),
      });
    }
  }

  // Validate the reception.
  async saveReception(msg = null) {
    let response: Reception | null;
    if (this.reception.id) {
      response = await receptionApiProvider.updateReception(this.reception, msg);
      if (response) {
        this.reception = response;
      }
      // We create the reception when we add an item in.
    } else {
      this.reception.statusCode = ReceptionStatus.draft;
      response = await receptionApiProvider.createReception(this.reception, msg);
      if (response) {
        this.reception = response;
        await store.commit("createReception/setCurrentReceptionId", response.id);
      }
    }
    return response;
  }

  refreshCart() {
    this.$refs.cartGrid.gridOptions.api.redrawRows();
  }

  /*
   This method is called after the user choose all stocks to update/create for a product.
   We add each stock in the reception.
  */
  async onStockModalComplete(stock: Stock, quantity: number) {
    await this.addStockToCart(stock, quantity);
    if (stock.product) {
      this.resetProductQuantity(stock.product);
    }
  }

  /*
    This method is called when we click on 'add to cart' button.
     */
  async onAddToCart(product: Product) {
    if (product.manageMultipleStock) {
      if (product.getTodayStocks().length > 0) {
        // Link product to stock that will now be standalone
        product.stocks.forEach((stock: Stock) => (stock.product = product));
        this.openStockList(product);
      } else {
        this.openCreateNewStock(product);
      }
    } else {
      await this.addStockToCart(
        Stock.createForProduct(product, this.reception.segment),
        product.quantity
      );
      this.resetProductQuantity(product);
    }
  }

  openStockList(product: Product) {
    this.$refs.stockModal.setValues(product, product.stocks, product.quantity);
    this.$refs.stockModal.toggleModal(true);
  }

  openCreateNewStock(product: Product) {
    this.$refs.stockModalCreation.openModal(product, this.reception);
    this.$refs.stockModalCreation.toggleModal(true);
  }

  // Callback of create new stock.
  onNewStock(product: Product, stock: Stock) {
    this.refreshProduct(product);
    this.onStockModalComplete(stock, product.quantity);
  }

  // On click on new supplier.
  async onClickNewSupplier() {
    if (this.reception.warehouse) {
      // Save the current state of the screen.
      const response = await this.saveReception();
      store.commit("createReception/setCurrentReceptionId", response.id);
      if (response) {
        await this.$router.replace({
          name: "create-supplier",
          params: {
            origin: this.$route.path ?? "",
            warehouseId: this.reception.warehouse.id,
            type: ContactType.supplier,
          },
        });
      }
    }
  }

  async onClickNewProduct() {
    // Save the current state of the screen.
    const response = await this.saveReception();
    if (response) {
      await this.$router.replace({
        name: "create-product",
        params: {
          origin: this.$route.path ?? "",
          warehouseId: this.reception.warehouse.id,
        },
      });
    }
  }

  beforeDestroy() {
    this.$eventHub.$off("add-product-to-cart");
    this.$eventHub.$off("remove-stock-from-cart");
    this.$eventHub.$emit("toggle-title-divider", true);
  }
}
