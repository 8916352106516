


















import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { ICellEditorComp } from "ag-grid-community";
import { Attribute, AttributeValues } from "@/interfaces/attributes/attribute";
import { AttributeHelper } from "@/utils/attribute-helper";
import { formatImportGridDataToData } from "@/utils/grid/import-grid-helper";

@Component({
  name: "grid-import-select-editor-component",
  components: { AgGridVue },
})
export default class GridImportSelectEditorComponent extends Vue implements ICellEditorComp {
  selectValues: AttributeValues[];

  attributeHelper = new AttributeHelper();

  $refs: {
    importSelect: HTMLFormElement;
  };

  beforeMount() {
    const att = this.$data.params.colDef.cellRendererParams.attribute;
    this.selectValues = this.attributeHelper.allActiveItem(
      att,
      formatImportGridDataToData(this.$data.params.data)
    );
  }

  mounted() {
    const input = this.$refs.importSelect.$refs.input;
    input.addEventListener("focus", this.onFocus, true);
  }

  getGui(): HTMLElement {
    throw new Error("Method not implemented.");
  }

  getValue(): any {
    return this.$data.params.value;
  }

  onChange() {
    this.$data.params.api.stopEditing();
  }

  onFocus() {
    this.$refs.importSelect.isMenuActive = true;
  }
}
