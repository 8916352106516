import { ColorChipInfo } from "../grid/grid-helper";
import { Reception, ReceptionStatus } from "@/interfaces/reception";

export abstract class ColorReceptionStatus {
  static getColorFromCode(code: ReceptionStatus): ColorChipInfo {
    const text = Reception.stringFromStatus(code);
    switch (code) {
      case ReceptionStatus.draft:
        return { color: "kDartessNightBlue75", textColor: "white--text", text: text };
      case ReceptionStatus.created:
        return { color: "kDartessNightBlue", textColor: "white--text", text: text };
      case ReceptionStatus.inProgress:
        return { color: "kWineAmber", textColor: "white--text", text: text };
      case ReceptionStatus.closed:
        return { color: "kDartessGreen", textColor: "white--text", text: text };
      default:
        return { color: "white", textColor: "black--text", text: code };
    }
  }
}
