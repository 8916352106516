























import { Component, Vue } from "vue-property-decorator";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { User } from "@/interfaces/user";
import { userApiProvider } from "@/providers/user-api-provider";
import UserDetailModal from "@/components/user/user-detail-modal.vue";
import UserGrid from "@/components/user/user-grid.vue";
import ConfirmModal from "@/components/_shared/modals/confirm-modal.vue";
import { DataSource } from "@/utils/grid/datasource";

@Component({
  name: "user-list-page",
  components: { AppIconTextButton, UserGrid, UserDetailModal, ConfirmModal },
})
export default class UserListPage extends Vue {
  datasource: DataSource;

  $refs: {
    userGrid: HTMLFormElement;
    userDetailModal: HTMLFormElement;
  };

  breadcrumbs = [{ text: this.$t("app.title") }, { text: this.$t("client-user.list_page_title") }];

  beforeMount() {
    this.$route.meta.title = this.$t("client-user.my_users");
    this.datasource = DataSource.from(userApiProvider);
  }

  async deactivateUser(user: User) {
    user.active = false;
    await userApiProvider.updateUser(user);
    this.$refs.userGrid.onFilterChange();
  }

  async activateUser(user: User) {
    user.active = true;
    await userApiProvider.updateUser(user);
    this.$refs.userGrid.onFilterChange();
  }

  async onCloseDetailModal() {
    this.$refs.userGrid.onFilterChange();
  }

  async mounted() {
    this.$nextTick(() => {
      // Autocomplete search field on new creation.
      if (this.$route.params.search_filter && this.$refs.userGrid) {
        this.$refs.userGrid.$refs.searchComponent.search = this.$route.params.search_filter;
      }
    });
  }
}
