

































import { Component, Mixins, Prop } from "vue-property-decorator";
import ModalComponent from "../_shared/modals/modal-component.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import ProductCard from "@/components/_shared/cards/product-card.vue";
import store from "../../stores/store";
import router from "@/router/router";

@Component({
  name: "new-shipping-order-modal",
  components: { ModalComponent, AppIconTextButton, ProductCard },
})
export default class NewShippingOrderModal extends Mixins(ModalComponent) {
  async open() {
    this.toggleModal(true);
  }

  async onClickContinue() {
    this.toggleModal(false);
    await router.push("/shipping-order/create");
  }

  async onClickNew() {
    this.toggleModal(false);
    await store.dispatch("createShippingOrder/reset");
    await router.push("/shipping-order/create");
  }
}
