import { Reception } from "@/interfaces/reception";
import { Product } from "./product";
import { Warehouse } from "./warehouse";
import { Segment } from "./segment";

export interface Stock {
  id: string;
  code: string;
  quantity: number;
  available: number;
  batch: string;
  bbd: string;
  ubd: string;
  quality: string;
  product: Product;
  warehouse: Warehouse;
  segment: Segment;
  createdAt: string;

  // Pivot fields
  ordered: number;
  prepared: number;
  expected: number;
  received: number;
}

export class Stock {
  static qualities = ["STD", "NCO"];

  constructor(data: Partial<Stock>) {
    Object.assign(this, data);
    if (data.product) {
      this.product = new Product(data.product);
    }
    if (data.warehouse) {
      this.warehouse = new Warehouse(data.warehouse);
    }
    if (data.segment) {
      this.segment = new Segment(data.segment);
    }
  }

  encode(): Reception {
    const data: any = { ...this };
    data.id = this.id ? this.id : null;
    data.product = this.product?.id;
    data.warehouse = this.warehouse?.id; // Todo => enlever le nullable.
    data.segment = this.segment?.id;
    data.batch = this.batch ? this.batch : null;
    return data;
  }

  static createForProduct(product: Product, segment: Segment) {
    const p = new Product();
    Object.assign(p, product);
    delete p.stocks;
    return new Stock({
      warehouse: product.warehouse || new Warehouse({}),
      segment,
      product: p,
      quality: "STD",
    });
  }

  equals(stock: Stock) {
    if (this.id && stock.id) {
      return this.id == stock.id;
    }
    return (
      this.product?.id == stock.product?.id &&
      this.quality == stock.quality &&
      this.batch == stock.batch &&
      this.bbd == stock.bbd &&
      this.ubd == stock.ubd
    );
  }
}
