







































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import ModalComponent from "../_shared/modals/modal-component.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { Stock } from "@/interfaces/stock";
import { MessageType } from "../_shared/template/app-snackbar.vue";
import { Product } from "@/interfaces/product";
import moment from "moment";

@Component({
  name: "stock-reception-modal",
  components: { ModalComponent, AppIconTextButton },
})
export default class StockReceptionModal extends Mixins(ModalComponent) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() successCallback: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() openCreationCallback: Function;

  product: Product = new Product();
  stocks: Stock[] = [];
  selectedItem: Stock | null = null;
  quantity = 0;

  stockFilter = "";

  onValidate() {
    if (this.selectedItem) {
      this.selectedItem.product = this.product;
      this.successCallback(this.selectedItem, this.product.quantity);
      this.toggleModal(false);
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.information,
        mainText: this.$t("stock.no_stock_alert"),
      });
    }
  }

  setValues(product: Product, stocks: Array<Stock>, quantity: number) {
    this.product = product;
    this.stocks = product.getTodayStocks();
    this.quantity = quantity;
    this.selectedItem = stocks.length == 1 ? stocks[0] : null;
  }

  onClickCreation() {
    this.toggleModal(false);
    this.openCreationCallback(this.product);
  }
}
