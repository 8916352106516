
















































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import AppExpansionPanelHeader from "@/components/_shared/panels/app-expansion-panel-header.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { Contact, ContactType } from "@/interfaces/contact";
import { contactApiProvider } from "@/providers/contact-api-provider";
import router from "@/router/router";
import { formValidation } from "@/utils/form-validation-helper";
import AddressFormComponent from "@/components/_shared/forms/address-form-component.vue";
import store from "../../stores/store";
import { Warehouse } from "@/interfaces/warehouse";
import { UserRole } from "@/interfaces/user";
import ServiceFormComponent from "@/components/_shared/forms/service-form-component.vue";
import { companyApiProvider } from "@/providers/company-api-provider";
import { Company } from "@/interfaces/company";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";
import { Segment } from "@/interfaces/segment";

@Component({
  name: "create-contact-page",
  components: {
    AppExpansionPanelHeader,
    AppIconTextButton,
    AddressFormComponent,
    ServiceFormComponent,
  },
})
export default class CreateContactPage extends Vue {
  @Prop() type: ContactType;

  formSending = false;
  typeText: any;
  rules = formValidation.getRules();
  loading = true;

  newContact = new Contact({ type: this.type });
  private company: Company = store.getters["user/getUser"].company;
  superAdmin = store.getters["user/getUser"].role == UserRole.superadmin;
  companySelect: Company[] = [];

  $refs: {
    contactForm: HTMLFormElement;
  };

  breadcrumbs = [
    { text: this.$t("app.title") },
    {
      text: this.$t("contact.title", {
        type: (this.$t(`${this.type}.name`) as string).toLowerCase(),
      }),
      href: `/${this.type}`,
    },
    {
      text: this.$t("contact.create_contact", {
        type: (this.$t(`${this.type}.name`) as string).toLowerCase(),
      }),
    },
  ];

  warehouseSelect = store.getters["user/getUser"].warehouses;

  typeList = ContactType;

  async beforeMount() {
    this.typeText = this.$t(`${this.type}.name`);
    this.$route.meta.title = this.$t("contact.create_contact", {
      type: this.typeText.toLowerCase(),
    });

    if (this.superAdmin) {
      this.companySelect = await companyApiProvider.getCompanies();
    }

    // Getting the company from the route params and setting it as the owner of the new contact.
    if (this.$route.params.ownerId) {
      this.company = await companyApiProvider.getCompany(this.$route.params.ownerId);
      this.newContact.owner = this.company;
    }

    // If a warehouse is passed in the route params, we force the user to use it.
    if (this.$route.params.warehouseId) {
      this.newContact.warehouse = this.warehouseSelect.find(
        (value: Warehouse) => value.id == this.$route.params.warehouseId
      );
      // Si la personne n'a qu'un site, on le mets par défaut.
    } else if (this.warehouseSelect.length === 1) {
      this.newContact.warehouse = this.warehouseSelect[0];
    }

    // If a segment is passed in the route params, we force the user to use it.
    if (this.$route.params.segmentId) {
      this.newContact.segment = this.company.segments.find(
        (value: Segment) => value.id == this.$route.params.segmentId
      );
    }
    // Si le client est mono segment ou avec des activités mutualisées
    else if (this.type == "recipient" && this.company.segments.length == 1) {
      this.newContact.segment = this.company.segments[0];
    }
    // If we add a product from the reception page.
    this.generateBreadcrumb();
    this.loading = false;
  }

  async validateCreation() {
    if (this.$refs.contactForm.validate()) {
      this.formSending = true;
      const response = await contactApiProvider.createContact(this.newContact);
      if (response) {
        // If we create a product from the reception page.
        if (this.$route.params.origin && this.$route.params.type) {
          let query = {};
          switch (this.$route.params.type) {
            case ContactType.supplier:
              query = { supplier_id: response.id };
              break;
            case ContactType.carrier:
              query = { carrier_id: response.id };
              break;
            case ContactType.recipient:
              query = { recipient_id: response.id };
              break;
          }
          await router.replace({ path: this.$route.params.origin, query: query });
          //await router.replace({ path: this.$route.params.origin, params: params });
        } else {
          await router.push(`/${this.type}/list`);
        }
      } else {
        this.formSending = false;
      }
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.warning,
        mainText: this.$t("form-validation.error"),
      });
    }
  }

  // If we create a contact from the reception page / order page. We have to update the breadcrumb.
  private generateBreadcrumb() {
    const o = this.$route.params.origin;
    if (o) {
      if (o.includes("reception")) {
        this.breadcrumbs = [
          { text: this.$t("reception.list_page_title") as string, href: "/reception/list" },
          o.includes("modify")
            ? {
                text: this.$t("reception.modify_page_title", { code: o.split("/")[3] }) as string,
                href: o,
              }
            : { text: this.$t("reception.create_page_title") as string, href: o },
          {
            text: this.$t("contact.create_contact", {
              type: (this.$t(`${this.type}.name`) as string).toLowerCase(),
            }),
          },
        ];
      } else if (o.includes("order")) {
        this.breadcrumbs = [
          { text: this.$t("order.list_page_title"), href: "/order/list" },
          o.includes("modify")
            ? { text: this.$t("order.modify_page_title", { code: o.split("/")[3] }), href: o }
            : { text: this.$t("order.create_page_title"), href: o },
          {
            text: this.$t("contact.create_contact", {
              type: (this.$t(`${this.type}.name`) as string).toLowerCase(),
            }),
          },
        ];
      } else if (o.includes("shipping-order")) {
        this.breadcrumbs = [
          { text: this.$t("shipping-order.list_page_title"), href: "/shipping-order/list" },
          { text: this.$t("shipping-order.create_page_title"), href: "/shipping-order/create" },
          {
            text: this.$t("contact.create_contact", {
              type: (this.$t(`${this.type}.name`) as string).toLowerCase(),
            }),
          },
        ];
      }
    }
  }

  onClientChange() {
    this.warehouseSelect = this.newContact.owner.warehouses;
  }
}
