












































































































































import { Component, Mixins } from "vue-property-decorator";
import ModalComponent from "../_shared/modals/modal-component.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import ProductCard from "@/components/_shared/cards/product-card.vue";
import { ShippingOrder, ShippingOrderStatus } from "@/interfaces/shipping-order";
import { shippingOrderApiProvider } from "@/providers/shipping-order-api-provider";
import { ColorShippingOrderStatus } from "@/utils/color/shipping-order-status";
import { ColorChipInfo } from "@/utils/grid/grid-helper";
import DatePickerFormComponent from "@/components/_shared/forms/date-picker-form-component.vue";
import TimePickerFormComponent from "@/components/_shared/forms/time-picker-form-component.vue";
import moment from "moment";
import { MessageType } from "../_shared/template/app-snackbar.vue";

@Component({
  name: "shipping-order-update-modal",
  components: {
    ModalComponent,
    AppIconTextButton,
    ProductCard,
    DatePickerFormComponent,
    TimePickerFormComponent,
  },
})
export default class ShippingOrderUpdateModal extends Mixins(ModalComponent) {
  shippingOrder: ShippingOrder | null = null;

  private lastStatus: ColorChipInfo | null = null;
  private formSending = false;

  private pickupAtDate = "";
  private pickupAtHour = "";

  private deliveredAtDate = "";
  private deliveredAtHour = "";

  $refs: {
    modal: HTMLFormElement;
    shippingOrderUpdateForm: HTMLFormElement;
    deliveredTime: HTMLFormElement;
    deliveredDate: HTMLFormElement;
    pickupTime: HTMLFormElement;
    pickupDate: HTMLFormElement;
  };

  async onOpening(value: ShippingOrder) {
    const response = await shippingOrderApiProvider.getShippingOrder(value.id);

    if (response) {
      this.shippingOrder = response;
      this.lastStatus = this.shippingOrder.statusCode
        ? ColorShippingOrderStatus.getColorFromCode(this.shippingOrder.statusCode)
        : null;

      if (this.shippingOrder.pickedupAt) {
        this.pickupAtDate = moment(this.shippingOrder.pickedupAt).format("YYYY-MM-DD");
        this.pickupAtHour = moment(this.shippingOrder.pickedupAt).format("HH:mm");
      } else {
        this.pickupAtDate = "";
        this.pickupAtHour = "";
      }

      if (this.shippingOrder.deliveredAt) {
        this.deliveredAtDate = moment(this.shippingOrder.deliveredAt).format("YYYY-MM-DD");
        this.deliveredAtHour = moment(this.shippingOrder.deliveredAt).format("HH:mm");
      } else {
        this.deliveredAtDate = "";
        this.deliveredAtHour = "";
      }
    }

    this.toggleModal(true);

    this.$refs.modal.onClose = () => {
      this.$refs.pickupDate.resetValue();
      this.$refs.pickupTime.resetValue();
      this.$refs.deliveredDate.resetValue();
      this.$refs.deliveredTime.resetValue();
    };
  }

  get getTitle() {
    if (this.shippingOrder) {
      return this.$t("shipping-order.shipping_order_update_modal_title", {
        code: this.shippingOrder.reference,
      }).toString();
    }
    return "";
  }

  async update() {
    if (this.shippingOrder && this.$refs.shippingOrderUpdateForm.validate()) {
      if (this.pickupAtDate && this.pickupAtHour) {
        this.shippingOrder.pickedupAt = moment(
          `${this.pickupAtDate} ${this.pickupAtHour}`
        ).toISOString();
      }
      if (this.deliveredAtHour && this.deliveredAtDate) {
        this.shippingOrder.deliveredAt = moment(
          `${this.deliveredAtDate} ${this.deliveredAtHour}`
        ).toISOString();
      }

      this.formSending = true;
      const response = await shippingOrderApiProvider.updateShippingOrderStatus(this.shippingOrder);
      this.formSending = false;
      if (response) {
        this.toggleModal(false);
      } else {
        this.$eventHub.$emit("show-snackbar", {
          type: MessageType.warning,
          mainText: this.$t("form-validation.error"),
        });
      }
    }
  }

  get colorStatus(): ColorChipInfo | undefined {
    if (this.shippingOrder && this.shippingOrder.statusCode) {
      return ColorShippingOrderStatus.getColorFromCode(this.shippingOrder.statusCode);
    }
    return undefined;
  }

  get statusSelect() {
    const result: any = [];
    const status: any = ShippingOrderStatus;
    Object.keys(status).forEach((s: any) => {
      result.push({ code: status[s], text: ShippingOrder.stringFromStatus(status[s]) });
    });
    return result;
  }

  mounted() {
    this.$eventHub.$on("open-shipping-order-update", this.onOpening);
  }

  beforeDestroy() {
    this.$eventHub.$off("open-shipping-order-update");
  }
}
