import CreateProductPage from "../pages/product/create-product-page.vue";
import ProductListPage from "../pages/product/product-list-page.vue";
import { AppScope } from "@/utils/app-scope";
import { AttributeGroupType } from "@/interfaces/attributes/attribute";
import ImportProductCsvPage from "@/pages/product/import-product-csv-page.vue";

const productRoutes = [
  {
    path: "/product/create",
    name: "create-product",
    component: CreateProductPage,
    meta: {
      access: 1,
      scope: AppScope.product,
    },
  },
  {
    path: "/product/list",
    alias: "/product",
    name: "product-list",
    component: ProductListPage,
    meta: {
      access: 1,
      scope: AppScope.product,
    },
  },
  {
    path: "/product/modify/:product_id",
    name: "modify-product",
    component: CreateProductPage,
    meta: {
      withoutTitle: true,
      withoutTitleRefresh: true,
      access: 1,
      scope: AppScope.product,
    },
  },
  {
    path: "/product/import",
    name: "import-products",
    component: ImportProductCsvPage,
    props: {
      dataLabel: "product.name",
      attributeGroup: AttributeGroupType.product,
    },
    meta: {
      title: "Importer des produits",
      access: 1,
      scope: AppScope.product,
    },
  },
];

export default productRoutes;
