



















































import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { ImportGrid } from "@/utils/grid/import-grid-helper";
import ImportGridFooter from "@/components/_shared/import-csv/import-grid-footer.vue";
import GridBoolComponent from "@/components/_shared/grid-components/grid-cells/grid-bool-component.vue";
import ImportGridHeader from "@/components/_shared/import-csv/import-grid-header.vue";
import { Company } from "@/interfaces/company";
import store from "@/stores/store";
import { UserRole } from "@/interfaces/user";
import { formValidation } from "@/utils/form-validation-helper";
import { Segment } from "@/interfaces/segment";
import { Warehouse } from "@/interfaces/warehouse";
import { companyApiProvider } from "@/providers/company-api-provider";

@Component({
  name: "import-user-csv-page",
  components: {
    ImportGridHeader,
    ImportGridFooter,
    AgGridVue,
    GridBoolComponent,
  },
})
export default class ImportUserCsvPage extends ImportGrid {
  private dataLabel = `${this.$route.params.type}.name`;
  loading = true;
  rules = formValidation.getRules();

  // Select values.
  private superAdmin = store.getters["user/getUser"].role == UserRole.superadmin;
  private companySelect: Company[] = [];

  // Import form data.
  private owner: Company;

  $refs: {
    importContactForm: HTMLFormElement;
  };

  async beforeMount() {
    if (this.superAdmin) {
      this.companySelect = await companyApiProvider.getCompanies();
      this.owner = this.companySelect.find((c: Company) => c.id === this.$route.params.companyId);
    }
    await super.beforeMount();
  }

  async onValidate() {
    if (this.$refs.importContactForm.validate()) {
      const formData = new FormData();
      if (this.owner) formData.append("owner", this.owner.id);
      await super.onValidate(formData);
    }
  }
}
