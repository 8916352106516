import { Contact } from "@/interfaces/contact";
import { ApiProvider, PaginatedResult } from "@/providers/api-provider";
import { ResourceProvider } from "@/interfaces/resource-provider";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";
import { i18n } from "@/main";

class ContactApiProvider extends ApiProvider implements ResourceProvider {
  defaults = "address,warehouse,owner,owner.segments,services,segment";

  async exportAll(params: URLSearchParams, columnList: Array<string> = []) {
    params.append("output", "csv");

    // Bidouille pour mapper les colonnes du front sur les champs en back.
    if (columnList.indexOf("warehouse.name") != -1)
      columnList[columnList.indexOf("warehouse.name")] = "warehouse.code";

    await this.downloadFile(`/contacts?${params}&columns=${columnList.toString()}`);
  }

  async getAll(params: URLSearchParams): Promise<PaginatedResult<Contact>> {
    params.append("include", this.defaults);
    const response = await this.get(`/contacts?` + params);
    return new PaginatedResult<Contact>(response, Contact);
  }

  async createContact(contact: Contact): Promise<Contact | null> {
    const params = new URLSearchParams();
    params.append("include", this.defaults);
    const typeText = (i18n.t(`${contact.type}.name`) as string).toLowerCase();
    const response = await this.post(`/contacts?` + params, contact.encode(), [
      {
        type: MessageType.success,
        text: i18n.t("message.contact_created", { type: typeText }).toString(),
      },
      {
        type: MessageType.error,
        text: i18n.t("message.contact_created_error", { type: typeText }).toString(),
      },
    ]);
    return response ? new Contact(response.data) : null;
  }

  async updateContact(contact: Contact) {
    const params = new URLSearchParams();
    params.append("include", this.defaults);
    const response = await this.put(`/contacts/${contact.id}?` + params, contact.encode(), [
      { type: MessageType.success, text: i18n.t("message.data_updated").toString() },
    ]);
    return response ? new Contact(response.data) : null;
  }

  async getContact(id: string) {
    const params = new URLSearchParams();
    params.append("include", this.defaults);
    const response = await this.get(`/contacts/${id}?` + params);
    return response ? new Contact(response.data) : null;
  }
}

export const contactApiProvider = new ContactApiProvider();
