




































































































































































import { Component, Vue } from "vue-property-decorator";
import AppExpansionPanelHeader from "@/components/_shared/panels/app-expansion-panel-header.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { formValidation } from "@/utils/form-validation-helper";
import { User, UserRole } from "@/interfaces/user";
import store from "../../stores/store";
import { Warehouse } from "@/interfaces/warehouse";
import { userApiProvider } from "@/providers/user-api-provider";
import router from "@/router/router";
import { AppScope } from "@/utils/app-scope";
import { companyApiProvider } from "@/providers/company-api-provider";
import PrestationFormComponent from "@/components/_shared/forms/prestation-form-component.vue";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";

@Component({
  name: "create-client-user-page",
  components: { AppExpansionPanelHeader, AppIconTextButton, PrestationFormComponent },
})
export default class CreateClientUserPage extends Vue {
  rules = formValidation.getRules();

  formSending = false;

  newUser = new User({ role: "user", active: true });
  emailConfirmation = "";
  warehouseSelect: Warehouse[] = [];
  UserRole = UserRole;
  roleSelect: any[] = [];

  scopeSelect: Array<AppScope> = [];

  $refs: {
    clientUserForm: HTMLFormElement;
  };

  breadcrumbs: any = [
    { text: this.$t("app.title") },
    { text: this.$t("client-user.title"), href: "/client-user/list" },
    { text: this.$t("client-user.create_user") },
  ];

  async beforeMount() {
    this.$route.meta.title = this.$t("client-user.create_user");
    await this.initData();
  }

  async initData() {
    const user = new User(store.getters["user/getUser"]);

    const companyId = this.$route.params.companyId ?? user.company?.id;
    const company = await companyApiProvider.getCompany(companyId);

    if (user.role == UserRole.superadmin) {
      // If we are in superadmin, we get the companyUser from the url.
      if (company) {
        // The default generated breadcrumb is false when we are in admin. We have to set a new one manually.
        this.breadcrumbs = [
          { text: this.$t("client-user.customers"), href: "/user/list" },
          { text: `${company.name}`, href: `/client-user/${company.id}/list` },
          { text: this.$t("client-user.create_user") },
        ];
        this.$route.meta.title = this.$t("client-user.create_user_for", { company: company.name });
      } else {
        await router.push("404");
      }
    }

    if (company) {
      this.newUser.company = company;

      // Init modules scope to subset of company one
      this.scopeSelect = company.scope;
      this.newUser.scope = company.scope;

      // Init warehouses to subset of company one
      this.warehouseSelect = company.warehouses;
      this.newUser.warehouses = company.warehouses;
    }

    // List role more restrictive than user's one.
    this.roleSelect = user.getAvailableUserRoleForCreation();
  }

  async validateCreation() {
    if (this.$refs.clientUserForm.validate()) {
      this.formSending = true;
      const response = await userApiProvider.createUser(this.newUser);
      if (response) {
        if (this.$route.params.companyId) {
          await router.push(`/client-user/${this.$route.params.companyId}/list`);
        } else {
          await router.push(`/client-user/list`);
        }
      } else {
        this.formSending = false;
      }
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.warning,
        mainText: this.$t("form-validation.error"),
      });
    }
  }
}
