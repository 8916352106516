import { User } from "@/interfaces/user";
import { authApiProvider } from "@/providers/auth-api-provider";
import { Warehouse } from "@/interfaces/warehouse";
import { LanguageHelper } from "@/utils/language-helper";

const initialState = () => ({
  user: null,
  locale: "fr",
  // Menu local preferences.
  menuIsVisible: false,
  menuCurrentHoverId: 0,
  menuSelectedItemId: 0,
  selectedWarehouses: [],
});

const state: any = initialState();

const mutations = {
  reset(state: any) {
    const newState: any = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setUser(state: any, payload: User) {
    state.user = payload;
  },
  setMenuCurrentHover(state: any, payload: number) {
    state.menuCurrentHoverId = payload;
  },
  setMenuCurrentSelected(state: any, payload: number) {
    state.menuSelectedItemId = payload;
  },
  setMenuIsVisible(state: any, payload: boolean) {
    state.menuIsVisible = payload;
  },
  setLocale(state: any, payload: boolean) {
    state.locale = payload;
  },
  setNotifications(state: any, payload: Array<string>) {
    state.user.notificationChannels = payload;
  },
  setSelectedWarehouses(state: any, payload: Warehouse[]) {
    state.selectedWarehouses = payload;
  },
};

const getters = {
  getUser: (state: any) => state.user,
  getMenuCurrentHover: (state: any) => state.menuCurrentHoverId,
  getMenuCurrentSelected: (state: any) => state.menuSelectedItemId,
  getMenuIsVisible: (state: any) => state.menuIsVisible,
  getLocale: (state: any) => state.locale,
  getSelectedWarehouses: (state: any) => state.selectedWarehouses,
};

const actions = {
  reset({ commit }: any) {
    commit("reset");
  },
  async fetchUser({ commit }: any) {
    const user = await authApiProvider.getUser();
    if (user) {
      commit("setUser", user);
      commit("setLocale", LanguageHelper.getInstance().language(user));
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
