
















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { formValidation } from "@/utils/form-validation-helper";
import store from "@/stores/store";

@Component({
  name: "date-picker-form-component",
})
export default class DatePickerFormComponent extends Vue {
  @Prop() value: string; // YYYY-MM-DD
  @Prop({ default: "Date" }) title: string;
  @Prop({ default: false }) required: boolean;
  @Prop() min: string; // YYYY-MM-DD
  @Prop() max: string; // YYYY-MM-DD
  @Prop() hint: string;
  @Prop({ default: false }) persistentHint: boolean;

  rules = formValidation.getRules();
  private menu = false;
  private locale = store.getters["user/getLocale"];
  private text = "";

  $refs: {
    menu: HTMLFormElement;
  };

  @Watch("value")
  onValueChange(value: string, oldValue: string) {
    if (value) {
      this.text = this.value;
    }
  }

  beforeMount() {
    if (this.value) {
      this.text = this.value;
    }
  }

  onClick() {
    this.$emit("input", this.text);
    this.menu = false;
  }

  resetValue() {
    this.text = "";
  }
}
