














import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { ICellEditorComp } from "ag-grid-community";

@Component({
  name: "grid-import-boolean-editor-component",
  components: { AgGridVue },
})
export default class GridImportBooleanEditorComponent extends Vue implements ICellEditorComp {
  getGui(): HTMLElement {
    throw new Error("Method not implemented.");
  }

  getValue(): any {
    return this.$data.params.value;
  }

  onChange() {
    this.$data.params.api.stopEditing();
  }
}
