





































































































































































import { Component, Mixins } from "vue-property-decorator";
import ModalComponent from "../_shared/modals/modal-component.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import ProductCard from "@/components/_shared/cards/product-card.vue";
import { User, UserRole } from "@/interfaces/user";
import { Warehouse } from "@/interfaces/warehouse";
import store from "@/stores/store";
import { userApiProvider } from "@/providers/user-api-provider";
import { formValidation } from "@/utils/form-validation-helper";
import PrestationFormComponent from "@/components/_shared/forms/prestation-form-component.vue";
import { MessageType } from "../_shared/template/app-snackbar.vue";

@Component({
  name: "client-user-detail-modal",
  components: {
    ModalComponent,
    AppIconTextButton,
    ProductCard,
    PrestationFormComponent,
  },
})
export default class ClientUserDetailModal extends Mixins(ModalComponent) {
  formSending = false;

  rules = formValidation.getRules();

  user: User = new User({});
  warehouseSelect: Warehouse[] = [];
  scopeSelect: any[] = [];
  UserRole = UserRole;
  roleSelect: any[] = [];
  users: User[] = [];
  usersLoading = true;
  superAdmin = store.getters["user/getUser"].role == UserRole.superadmin;

  $refs: {
    userForm: HTMLFormElement;
    modal: HTMLFormElement;
  };

  async beforeMount() {
    // List role more restrictive than user one
    const authedUser = new User(store.getters["user/getUser"]);
    this.roleSelect = authedUser.getAvailableUserRoleForCreation();
  }

  async onOpening(value: User) {
    const response = await userApiProvider.getUser(value.id.toString());
    if (response) {
      this.user = response;
    }
    if (this.user.company) {
      // Init modules scope to subset of company one
      this.scopeSelect = this.user.company.scope;
      // Init warehouses to subset of company one
      this.warehouseSelect = this.user.company.warehouses;
    }
    this.toggleModal(true);

    // Get all users if superadmin (for user_child select)
    if (this.superAdmin) {
      userApiProvider.getUsers().then(async (result) => {
        this.users = result;
        this.usersLoading = false;
      });
    }
  }

  async validateEdition() {
    if (this.$refs.userForm.validate()) {
      this.formSending = true;
      const response = await userApiProvider.updateUser(this.user);
      this.formSending = false;
      if (response) {
        this.toggleModal(false);
      }
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.warning,
        mainText: this.$t("form-validation.error"),
      });
    }
  }

  mounted() {
    this.$eventHub.$on("open-client-user-detail-modal", this.onOpening);
  }

  beforeDestroy() {
    this.$eventHub.$off("open-client-user-detail-modal");
  }
}
