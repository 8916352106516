import CreateOrderPage from "../pages/order/create-order-page.vue";
import OrderDetailPage from "../pages/order/order-detail-page.vue";
import OrderListPage from "../pages/order/order-list-page.vue";
import { AppScope } from "@/utils/app-scope";

const orderRoutes = [
  {
    path: "/order/create",
    name: "create-order",
    component: CreateOrderPage,
    meta: {
      access: 1,
      scope: AppScope.order,
    },
  },
  {
    path: "/order/list",
    alias: "/order",
    name: "order-list",
    component: OrderListPage,
    meta: {
      access: 1,
      scope: AppScope.order,
    },
  },
  {
    path: "/order/detail/:order_id",
    name: "order-detail",
    component: OrderDetailPage,
    meta: {
      access: 1,
      scope: AppScope.order,
    },
  },
  {
    path: "/order/modify/:order_id",
    name: "modify-order",
    component: CreateOrderPage,
    meta: {
      access: 1,
      scope: AppScope.order,
    },
  },
];

export default orderRoutes;
