







































import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import { MainProductGrid } from "@/utils/grid/main-product-grid";
import { Product } from "@/interfaces/product";
import GridPreferencesComponent from "../_shared/grid-components/grid-preferences-component.vue";
import GridQuantityInfoComponent from "../_shared/grid-components/grid-products/grid-quantity-info-component.vue";
import ProductSearchComponent from "../_shared/searchs/product-search-component.vue";
import CommonSearchComponent from "../_shared/searchs/common-search-component.vue";
import GridQuantityComponent, {
  GridQuantityComponentParams,
} from "../_shared/grid-components/grid-products/grid-quantity-component.vue";
import GridColorChipComponent from "../_shared/grid-components/grid-cells/grid-color-chip-component.vue";
import { Warehouse } from "@/interfaces/warehouse";
import GridCartComponent, {
  GridCartComponentParams,
} from "../_shared/grid-components/grid-products/grid-cart-component.vue";
import { Stock } from "@/interfaces/stock";
import GridBoolComponent from "../_shared/grid-components/grid-cells/grid-bool-component.vue";

@Component({
  name: "reception-product-grid",
  components: {
    AgGridVue,
    GridIconRowComponent,
    GridQuantityComponent,
    AppIconTextButton,
    CommonSearchComponent,
    GridPreferencesComponent,
    GridQuantityInfoComponent,
    ProductSearchComponent,
    GridColorChipComponent,
    GridCartComponent,
    GridBoolComponent,
  },
})
export default class ReceptionMainProductGrid extends MainProductGrid {
  @Prop() rowData: Array<Product>;
  @Prop() cart: Array<Stock>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop({ default: null }) addProductCallback: Function;
  @Prop() warehouse: Warehouse;

  async beforeMount() {
    super.beforeMount();
  }

  $refs: {
    searchComponent: HTMLFormElement;
    config: HTMLFormElement;
    preferences: HTMLFormElement;
    productGridFilter: HTMLFormElement;
  };

  columns = [
    ...this.getCommonProductGridField(),
    {
      headerName: this.$t("reception.quantity"),
      field: "quantity",
      pinned: "right",
      maxWidth: 125,
      width: 125,
      minWidth: 125,
      sortable: false,
      filterFramework: null,
      resizable: false,
      suppressMovable: true,
      cellRendererFramework: "GridQuantityComponent",
      valueGetter: function (params: any): GridQuantityComponentParams {
        let result = 0;
        if (params.data && params.context.cart) {
          params.context.cart.forEach((stock: Stock) => {
            if (params.data.id == stock.product.id) {
              result += stock.expected;
            }
          });
        }
        return { max: 99999, quantity: result };
      },
    },
    {
      cellRendererFramework: "GridIconRowComponent",
      pinned: "right",
      width: 30,
      minWidth: 30,
      maxWidth: 30,
      cellClass: "cell-align-company",
      sortable: false,
      filterFramework: null,
      resizable: false,
      suppressMovable: true,
      valueGetter: function (params: any) {
        return { id: params.data?.id, active: [true] };
      },
      cellRendererParams: { icons: [{ icon: "mdi-magnify", event: "open-product-detail-modal" }] },
    },
    {
      cellRendererFramework: "GridCartComponent",
      pinned: "right",
      field: "cart",
      headerName: "Panier",
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      cellClass: "cell-align-company",
      sortable: false,
      filterFramework: null,
      resizable: false,
      suppressMovable: true,
      valueGetter: function (params: any): GridCartComponentParams {
        let result = 0;
        let disabled = false;
        if (params.data && params.context.cart) {
          params.context.cart.forEach((stock: Stock) => {
            if (params.data.id == stock.product.id) {
              result += stock.expected;
            }
          });
        }
        return { quantity: result, disabled: disabled };
      },
    },
  ];

  mounted() {
    this.$nextTick(() => {
      // Autocomplete search field on new creation.
      if (this.$route.params.search_filter) {
        this.$refs.searchComponent.search = this.$route.params.search_filter;
      }
    });
  }
}
