







import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";

@Component({
  name: "grid-bool-component",
  components: { AgGridVue },
})
export default class GridBoolComponent extends Vue {
  value: boolean;
  visibility = true;

  static isBoolean(v) {
    return typeof this.booleanValue(v) === "boolean";
  }

  static booleanValue(v) {
    if (typeof v === "string") {
      v = v.toLowerCase();
      if (v == "true" || v == "yes" || v == "oui" || v == "on" || v == "1") {
        return true;
      } else if (v == "false" || v == "no" || v == "off" || v == "non" || v == "0") {
        return false;
      } else {
        return undefined;
      }
    } else if (typeof v === "boolean") {
      return !!v;
    } else {
      return undefined;
    }
  }

  beforeMount() {
    // Classic grid mode.
    if (!this.$data.params.isImport) {
      this.value = this.$data.params.value;
      // Import grid mode : {validity: CellValidity; value: any}
      // The grid pass this attribute in the cellRendererParams.
    } else {
      this.value = GridBoolComponent.booleanValue(this.$data.params.value.value);
    }
    this.visibility = typeof this.value === "boolean";
  }
}
