




import { Component, Vue } from "vue-property-decorator";

@Component({ name: "app-title" })
export default class AppTitle extends Vue {
  title = "";

  beforeMount() {
    if (this.$route.meta.title) this.title = this.$route.meta.title;
    this.$eventHub.$on("update-title", (title: string | undefined) => {
      if (title) {
        this.title = title;
      }
      this.$forceUpdate();
    });
  }

  beforeDestroy() {
    this.$eventHub.$off("update-title");
  }
}
