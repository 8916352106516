import ShippingOrderListPage from "../pages/transportation/shipping-order-list-page.vue";
import CreateShippingOrderPage from "../pages/transportation/create-shipping-order-page.vue";
import ShippingOrderDetailPage from "../pages/transportation/shipping-order-detail-page.vue";
import { AppScope } from "@/utils/app-scope";

const shippingOrderRoutes = [
  {
    path: "/shipping-order/list",
    alias: "/shipping-order",
    name: "shipping-order-list",
    component: ShippingOrderListPage,
    meta: {
      access: 1,
      scope: AppScope.transportation,
    },
  },
  {
    path: "/shipping-order/create",
    name: "shipping-order-create",
    component: CreateShippingOrderPage,
    meta: {
      access: 1,
      scope: AppScope.transportation,
    },
  },
  {
    path: "/shipping-order/detail/:shippingOrder_id",
    name: "shipping-order-detail",
    component: ShippingOrderDetailPage,
    meta: {
      access: 1,
      scope: AppScope.transportation,
    },
  },
];

export default shippingOrderRoutes;
