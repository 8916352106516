export interface Service {
  id: string;
  code: string;
  name: string;
  isPickupPoint: boolean;
}

export class Service {
  constructor(data: Partial<Service> = {}) {
    Object.assign(this, data);
    this.isPickupPoint = !!data.isPickupPoint;
  }

  encode(): Service {
    const data: any = Object.assign({}, this);
    return data;
  }

  get text() {
    return `${this.code} - ${this.name}`;
  }
}
