


































import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { Stock } from "@/interfaces/stock";

@Component({
  name: "grid-quantity-component",
  components: { AgGridVue },
})
export default class GridQuantityInfoComponent extends Vue {
  stocks: Stock[];
  nbInCart = 0;

  beforeMount() {
    this.stocks = this.$data.params.value.stocks;
    this.nbInCart = this.$data.params.value.nbInCart;
  }

  getAllQuantity(): number {
    let result = 0;
    if (this.stocks) {
      this.stocks.forEach((stock) => {
        result += stock.available;
      });
    }
    return result - this.nbInCart;
  }
}
