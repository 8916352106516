const initialState = () => ({
  currentOrderId: null,
});

const state: any = initialState();

const mutations = {
  reset(state: any) {
    const newState: any = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setCurrentOrderId(state: any, payload: string) {
    state.currentOrderId = payload;
  },
};

const getters = {
  getCurrentOrderId: (state: any) => state.currentOrderId,
};

const actions = {
  reset({ commit }: any) {
    commit("reset");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
