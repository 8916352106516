import { IDatasource, IGetRowsParams } from "ag-grid-community";
import { ResourceProvider } from "@/interfaces/resource-provider";
import { Filter, QueryBuilder } from "@/providers/filter";

type FilterCallback = () => Promise<Array<Filter> | undefined>;

export class DataSource {
  // eslint-disable-next-line @typescript-eslint/ban-types
  filters: Function | Filter[];
  builder: QueryBuilder;

  constructor(public provider: ResourceProvider) {}

  static from(provider: ResourceProvider): DataSource {
    return new DataSource(provider);
  }

  withFilters(filters: Array<Filter> | FilterCallback) {
    this.filters = filters;
    return this;
  }

  /**
   * Export last query grid data as CSV file
   */
  async exportData(columnList: Array<string>) {
    //TODO: Find a better way to get last query params
    this.provider.exportAll(this.builder.build(), columnList);
  }

  /**
   * Get grid data rows
   */
  async getRows(params: IGetRowsParams) {
    params.context.gridOptions.api.showLoadingOverlay();

    // Copy builder with external filter builder
    this.builder = new QueryBuilder(params.context.externalFilters);

    // Add grid filters to builder
    for (const field in params.filterModel) {
      this.builder.set(field, new Filter(field, params.filterModel[field]));
    }

    // Add default datasource filters
    const filters = this.filters instanceof Function ? await this.filters() : this.filters;
    if (filters) {
      this.builder.addFilters(filters);
    }

    // Add filters URL parameters
    const requestParams = this.builder.build();

    // Add sorting URL parameters
    for (const sort of params.sortModel) {
      requestParams.append("sort", (sort.sort == "desc" ? "-" : "") + sort.colId);
    }

    // Add pagination URL parameters
    const page = 1 + Math.round(params.startRow / params.context.gridOptions.cacheBlockSize);
    requestParams.append("paginate", params.context.gridOptions.cacheBlockSize);
    requestParams.append("page", page.toString());

    //Execute request
    try {
      const result = await this.provider.getAll(requestParams);
      params.successCallback(result.data, result.total);
      if (result.total == 0) {
        params.context.gridOptions.api.showNoRowsOverlay();
      } else {
        params.context.gridOptions.api.hideOverlay();
      }
    } catch (e) {
      console.error(e);
      params.failCallback();
    }
  }
}
