import { render, staticRenderFns } from "./stats-page.vue?vue&type=template&id=031a08b2&"
import script from "./stats-page.vue?vue&type=script&lang=ts&"
export * from "./stats-page.vue?vue&type=script&lang=ts&"
import style0 from "./stats-page.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCol,VSkeletonLoader})
