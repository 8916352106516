import { Attribute, AttributeGroup } from "@/interfaces/attributes/attribute";
import { attributeApiProvider } from "@/providers/attribute-api-provider";

const initialState = () => ({
  attributes: null,
});

const state: any = initialState();

const mutations = {
  reset(state: any) {
    const newState: any = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  initAllAttribute(state: any, payload: Array<AttributeGroup>) {
    if (state.attributes == null) {
      const attributes: any = {};
      payload.forEach((attGr: AttributeGroup) => {
        const groups: any = {};
        attGr.attributes.map((att: Attribute) => {
          groups[att.id] = att;
        });
        attributes[attGr.id] = groups;
      });
      state.attributes = attributes;
    }
  },
};

const getters = {
  getAttribute: (state: any) => (group: string, id: string) => {
    return state.attributes[group][id];
  },
  getAttributeByLabel: (state: any) => (group: string, label: string) => {
    const attGroup = Object.values(state.attributes[group]);
    const index = attGroup.findIndex((e: any) => e.label === label);
    return index != -1 ? (attGroup[index] as Attribute) : null;
  },
  getAllAttribute: (state: any) => state.attributes,
};

const actions = {
  reset({ commit }: any) {
    commit("reset");
  },
  async fetchAllAttribute({ commit, state }: any) {
    if (state.attributes == null) {
      const attrs = await attributeApiProvider.getAllAttribute();
      commit("initAllAttribute", attrs);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
