


























import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { IFilterComp } from "ag-grid-community";
import { Condition } from "@/providers/filter";

@Component({
  name: "grid-boolean-filter-component",
  components: { AgGridVue },
})
export default class GridBooleanFilterComponent extends Vue implements IFilterComp {
  label: string;
  value: boolean | null;

  onClick(value: boolean) {
    if (this.value == value) {
      this.value = null;
    } else {
      this.value = value;
    }
    this.updateFilter();
    this.$forceUpdate();
  }

  beforeMount() {
    this.value = null;
    this.label = this.$data.params.colDef.headerName;
  }

  disableFilter() {
    this.value = null;
    this.$data.params.filterChangedCallback();
  }

  updateFilter() {
    this.$data.params.filterChangedCallback();
  }

  init(params: any) {
    throw new Error("Method not implemented.");
  }

  getGui(): HTMLElement {
    throw new Error("Method not implemented.");
  }

  isFilterActive() {
    return this.value != null;
  }

  doesFilterPass(params: any) {
    return this.$data.params.valueGetter(params.node) === this.value;
  }

  getModel() {
    if (this.isFilterActive() && this.value != null) {
      return Condition.whereEquals(this.value);
    } else {
      return null;
    }
  }

  setModel(model: any) {
    this.value = model ? model.filter : null;
  }
}
