






































































import { Component, Vue } from "vue-property-decorator";
import { Warehouse } from "@/interfaces/warehouse";
import LocaleSelect from "@/components/_shared/forms/locale-select.vue";
import AuthHelper from "@/utils/auth-helper";
import store from "@/stores/store";
import AppTitle from "@/components/_shared/template/app-title.vue";

@Component({
  name: "app-header",
  components: { AppTitle, LocaleSelect },
})
export default class AppHeader extends Vue {
  user = store.getters["user/getUser"];
  isImpersonate = store.getters["auth/isImpersonate"];
  selectedWarehouse: Warehouse | null;
  warehouses = new Array<Warehouse>();
  async onLogout() {
    await AuthHelper.logout();
  }

  /**
   * Login as another user
   * @param userId
   */
  async loginAs(userId: string) {
    await AuthHelper.loginAs(userId);
  }

  /**
   * Revert login as another user
   */
  async revertImpersonate() {
    await AuthHelper.revertImpersonate();
  }

  beforeMount() {
    this.selectedWarehouse = store.getters["user/getUser"].warehouse;
  }

  async onBurgerClick() {
    this.$eventHub.$emit("toggle-menu", true);
  }

  toggleFullscreen() {
    this.$eventHub.$emit("toggle-fullscreen");
  }

  beforeDestroy() {
    this.$eventHub.$off("toggle-drawer");
  }
}
