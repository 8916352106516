
















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { formValidation } from "@/utils/form-validation-helper";
import { Attribute } from "@/interfaces/attributes/attribute";
import { Product } from "@/interfaces/product";
import { AttributeHelper } from "@/utils/attribute-helper";
import { i18n } from "@/main";

@Component({
  name: "attribute-form-component",
})
export default class AttributeFormComponent extends Vue {
  @Prop() newProduct: Product;
  @Prop() attribute: Attribute;
  @Prop() isVisible: boolean;
  @Prop() isActive: boolean;
  rules = formValidation.getRules();
  helper = new AttributeHelper();

  /**
   * Event to update weight when capacity is updated
   */
  onAttributeChange(attributeId) {
    this.$emit("onAttributeChange", attributeId);
  }

  /**
   * When newProduct is updated by another field, whe use this watcher to refresh current attribute value
   */
  @Watch("newProduct", { deep: true })
  onPropertyChanged() {
    if (this.attribute.id === "weight") {
      this.$forceUpdate();
    }
  }

  getAllItems() {
    let items = this.helper.allActiveItem(this.attribute, this.newProduct.attributes);

    // Si l'unité est le cL on tri la liste dans l'ordre croissant.
    if (this.attribute.unit == "cl") {
      items = items.sort((a, b) => Number(a.label) - Number(b.label));
    }

    // Tri spécifique pour l'attribut "conditionnement"
    if (this.attribute.id.includes("pcb")) {
      items = items.sort((a, b) => Number(a.code) - Number(b.code));
    }

    return items;
  }

  getAllComboboxItem() {
    return this.getAllItems().map((item) => item.label);
  }

  getValidatorRules() {
    const result = [];
    if (this.attribute.required) result.push(this.rules.isRequired);
    if (this.attribute.unit == "°") result.push(this.rules.isDegree);
    if (this.attribute.regex) result.push(this.regexRules);
    return result;
  }

  regexRules(value: string) {
    const regex = new RegExp(this.attribute.regex);
    return !value || regex.test(value)
      ? true
      : i18n.t("form-validation.field_incorrect").toString();
  }
}
