import Vue from "vue";
import App from "./app.vue";
import vuetify from "./plugins/vuetify";

import VCalendar from "v-calendar";
import Fullscreen from "vue-fullscreen";
import CountryFlag from "vue-country-flag";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import VueTelInputVuetify from "vue-tel-input-vuetify";

import { EventHub } from "./plugins/event-hub";
import VueI18n from "vue-i18n";
import locales from "@/assets/locales/locales";
import store from "@/stores/store";
import router from "./router/router";
import VueRouter from "vue-router";

require("./assets/sass/vue-number-input.sass");
require("./assets/sass/main.sass");

if (process.env.VUE_APP_ENV === "production") {
  Vue.config.devtools = false;
  Vue.config.silent = true;
} else {
  Vue.config.devtools = true;
  Vue.config.silent = false;
}

Vue.use(EventHub);
Vue.use(CountryFlag);
Vue.use(Fullscreen);
Vue.use(VueNumberInput);

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(VCalendar, {
  componentPrefix: "vc",
});

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: store.getters["user/getLocale"],
  messages: locales,
  preserveDirectiveContent: true,
});

Vue.use(VueRouter);

const app = new Vue({
  i18n,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

import moment from "moment";
moment.locale(store.getters["user/getLocale"]);

Vue.filter("datetime", function (value: string) {
  if (value) {
    return moment(value).format("L LT");
  }
});

Vue.filter("date", function (value: string) {
  if (value) {
    return moment(value).format("L");
  }
});
