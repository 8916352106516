




























import { Component, Vue } from "vue-property-decorator";
@Component({ name: "export-csv-snackbar" })
export default class ExportCsvSnackbar extends Vue {
  private value = false;
  private timeout = null;
  private messageDownload = "";
  private errorDownload = "";
  private color = "success";
  private rowsQty;

  beforeMount() {
    this.$on("updateValue", (newVal: boolean) => {
      this.value = newVal;
    });
    // Display waiting messages with timeout on rows quantity based and the limit timeout
    this.$on("rowsQty", (rowsNumber: number) => {
      // Store row quantity
      this.rowsQty = rowsNumber;
      // Set timeout on rows quantity based
      this.timeout = rowsNumber * 20;
      // Information messages
      this.messageDownload = this.$t("common.export_file_message-step_one").toString();
      setTimeout(() => {
        this.messageDownload = this.$t("common.export_file_message-step_two").toString();
      }, this.timeout / 5);
      setTimeout(() => {
        this.messageDownload = this.$t("common.export_file_message-step_three").toString();
      }, this.timeout / 4);
      setTimeout(() => {
        this.messageDownload = this.$t("common.export_file_message-step_four").toString();
      }, this.timeout / 3);
      setTimeout(() => {
        this.messageDownload = this.$t("common.export_file_message-step_four").toString();
      }, this.timeout / 2);
      // Warning message based on the limit timeout reached
      setTimeout(() => {
        this.color = "warning";
        this.errorDownload = this.$t("common.export_file_error_2").toString();
      }, 300000);
      this.color = "success";
      this.errorDownload = "";
    });
    // Close snackbar modal when "downloaded" event appears
    this.$eventHub.$on("downloaded", () => {
      this.value = false;
    });
  }
}
