import { i18n } from "@/main";

export abstract class AppScope {
  public static all = "*";

  public static order = "order";

  public static reception = "reception";

  public static document = "document";

  public static stock = "stock";

  public static stockMovement = "stockMovement";

  public static transportation = "transportation";

  public static production = "production";

  // Repository
  public static product = "product";

  public static carrier = "carrier";

  public static supplier = "supplier";

  public static recipient = "recipient";

  public static values = [
    AppScope.reception,
    AppScope.order,
    AppScope.stock,
    AppScope.stockMovement,
    AppScope.transportation,
    AppScope.production,
    AppScope.product,
    AppScope.carrier,
    AppScope.supplier,
    AppScope.recipient,
  ];

  static getString(scope: string): string {
    switch (scope) {
      case this.stock:
        return i18n.t("stock.title").toString();
      case this.stockMovement:
        return i18n.t("stock-movement.title").toString();
      case this.document:
        return i18n.t("document.title").toString();
      case this.order:
        return i18n.t("order.title").toString();
      case this.reception:
        return i18n.t("reception.title").toString();
      case this.transportation:
        return i18n.t("shipping-order.title").toString();
      case this.production:
        return i18n.t("production.title").toString();
      case this.product:
        return i18n.t("product.name").toString();
      case this.carrier:
        return i18n.t("carrier.name").toString();
      case this.supplier:
        return i18n.t("supplier.name").toString();
      case this.recipient:
        return i18n.t("recipient.name").toString();
      default:
        return "";
    }
  }
}
