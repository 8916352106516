import { ApiProvider, PaginatedResult } from "@/providers/api-provider";
import { User } from "@/interfaces/user";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";
import { i18n } from "@/main";
import { ResourceProvider } from "@/interfaces/resource-provider";

class UserApiProvider extends ApiProvider implements ResourceProvider {
  async getUser(id: string): Promise<User> {
    const response = await this.get(
      `/users/${id}?include=warehouses,role,company,company.address,company.warehouses,company.carriers,children`
    );
    return response ? new User(response.data) : null;
  }

  async getUsers(): Promise<User[]> {
    const response = await this.get(`/users`);
    return response ? response.data : null;
  }

  async exportAll(params: URLSearchParams, columnList: Array<string> = []) {
    params.append("output", "csv");

    // Bidouille pour mapper les colonnes du front sur les champs en back.
    if (columnList.indexOf("name") !== -1) {
      columnList.splice(columnList.indexOf("name"), 1, "firstname");
      columnList.splice(columnList.indexOf("name"), 0, "lastname");
    }
    if (columnList.indexOf("role") != -1) columnList[columnList.indexOf("role")] = "role.slug";
    if (columnList.indexOf("warehouses") != -1)
      columnList[columnList.indexOf("warehouses")] = "warehouses.code";
    if (columnList.indexOf("company") != -1)
      columnList[columnList.indexOf("company")] = "company.code";

    await this.downloadFile(`/users?${params}&columns=${columnList.toString()}`);
  }

  async getAll(params: URLSearchParams): Promise<PaginatedResult<User>> {
    params.append("include", "warehouses,role,company,company.address");
    const response = await this.get(`/users?${params}`);
    return new PaginatedResult<User>(response, User);
  }

  async createUser(user: User) {
    const response = await this.post("/users", user.encode(), [
      { type: MessageType.success, text: i18n.t("message.user_created").toString() },
      { type: MessageType.error, text: i18n.t("message.user_created_error").toString() },
    ]);
    return response ? new User(response.data) : null;
  }

  async updateUser(user: User, withSuccessMsg = true) {
    const msg = withSuccessMsg
      ? [{ type: MessageType.success, text: i18n.t("message.user_updated").toString() }]
      : [];
    const response = await this.put(`/users/${user.id}`, user.encode(), msg);
    return response ? new User(response.data) : null;
  }

  async updateLocale(locale: string, user: User) {
    user.locale = locale;
    const response = await this.put(`/users/${user.id}`, user.encode(), [
      { type: MessageType.success, text: i18n.t("message.locale_updated").toString() },
    ]);
    return response ? new User(response.data) : null;
  }

  async deleteUser(user: User) {
    const response = await this.delete(`/users/${user.id}`, [
      { type: MessageType.success, text: i18n.t("message.user_deleted").toString() },
    ]);
    return response ? new User(response.data) : null;
  }

  // Check if email registered in DB, return email or empty string
  async getUserEmail(email: string) {
    return this.get(`/email/${email}`);
  }
}

export const userApiProvider = new UserApiProvider();
