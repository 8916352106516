import UserListPage from "../pages/user/user-list-page.vue";
import CreateUserPage from "../pages/user/create-user-page.vue";
import CommonImportCsvPage from "../pages/common-import-csv-page.vue";
import { AttributeGroupType } from "@/interfaces/attributes/attribute";
import ImportUserCsvPage from "@/pages/user/import-user-csv-page.vue";

const userRoutes = [
  {
    path: "/user/list",
    alias: "/user",
    name: "user-list",
    component: UserListPage,
    meta: {
      access: 10,
    },
  },
  {
    path: "/user/create",
    name: "create-user",
    component: CreateUserPage,
    meta: {
      access: 10,
    },
  },
  {
    path: "/user/import",
    name: "import-users",
    component: ImportUserCsvPage,
    props: {
      attributeGroup: AttributeGroupType.user,
    },
    meta: {
      title: "Importer des utilisateurs",
      access: 10,
    },
  },
];

export default userRoutes;
