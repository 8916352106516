
































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ name: "import-grid-header" })
export default class ImportGridHeader extends Vue {
  @Prop() nbInvalid: number;
  @Prop() nbData: number;
  @Prop() previousRoute: string;
  @Prop() title: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() validateCallback: Function;
  // Hide validation button case import users file with email already set in database
  beforeMount() {
    this.$eventHub.$on("email-used", () => {
      const validateButton = document.getElementById("validate");
      if (validateButton !== null) {
        validateButton.remove();
      }
    });
  }
}
