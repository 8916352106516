

































import { Component, Mixins, Prop } from "vue-property-decorator";
import ModalComponent from "./modal-component.vue";
import AppIconTextButton from "../buttons/app-icon-text-button.vue";
import ProductCard from "../cards/product-card.vue";
import { csvImportApiProvider } from "@/providers/csv-import-api-provider";
import { MessageType } from "../template/app-snackbar.vue";
import jschardet from "jschardet";

@Component({
  name: "import-csv-modal",
  components: { ModalComponent, AppIconTextButton, ProductCard },
})
export default class ImportCsvModal extends Mixins(ModalComponent) {
  @Prop() page: string;
  @Prop() template: string;
  @Prop() type: string;
  @Prop() title: string;
  @Prop() pageParams: object;

  csv: File | null = null;

  async downloadTemplate() {
    await csvImportApiProvider.getTemplate(this.template, this.type);
  }

  async onValid() {
    if (this.csv) {
      const input = await this.csv.text();
      let charset;
      try {
        charset = jschardet.detect(input);
      } catch (e) {
        console.warn("chardet warning => set UTF-8 by default");
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          this.$router.replace({
            name: this.page,
            params: {
              ...this.pageParams,
              csv: reader.result.toString(),
              template: this.template,
              type: this.type,
            },
          });
        } else {
          console.error(reader.readyState);
          this.showError("Lecture impossible du fichier");
        }
      };
      reader.onerror = () => {
        console.error(reader.readyState);
        this.showError("Lecture impossible du fichier");
      };
      reader.readAsText(this.csv, charset?.encoding ?? "UTF-8");
    } else {
      this.showError("Vous devez choisir un fichier pour réaliser l'importation");
    }
  }

  showError(error: string) {
    this.$eventHub.$emit("show-snackbar", { type: MessageType.error, mainText: error });
  }
}
