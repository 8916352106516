var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"elevation-5",staticStyle:{"border":"none !important"},attrs:{"mini-variant":_vm.isMini && !_vm.$vuetify.breakpoint.smAndDown,"mini-variant-width":55,"width":_vm.$vuetify.breakpoint.smAndDown ? _vm.$vuetify.breakpoint.width / 1.75 : 200,"color":"kDartessNightBlue","src":"/img/drawer-background.png","app":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-row',{staticClass:"pa-2",attrs:{"no-gutters":"","align":"center","justify":_vm.isMini ? 'center' : 'end'}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"pr-2",attrs:{"icon":"","ripple":false,"color":"transparent"},on:{"click":_vm.onDisplayMiniClick}},[_c('v-icon',{staticClass:"ml-2",attrs:{"size":"40","color":"kDartessWhite"}},[_vm._v(" "+_vm._s(_vm.isMini ? "mdi-chevron-double-right" : "mdi-chevron-double-left")+" ")])],1):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},[(_vm.isMini && !_vm.$vuetify.breakpoint.smAndDown)?_c('v-img',{attrs:{"alt":"Logo dartess","src":"/favicon.png","height":55}}):_c('v-img',{attrs:{"alt":"Logo dartess","src":"/img/logo-web-secondaire-b.png","height":_vm.$vuetify.breakpoint.smAndDown ? 80 : 55}}),_vm._l((_vm.items),function(ref,i){
var id = ref.id;
var icon = ref.icon;
var title = ref.title;
var link = ref.link;
var active = ref.active;
var parentId = ref.parentId;
var isParent = ref.isParent;
return _c('div',{key:id},[(active)?_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentHoverId === parentId || parentId === 0),expression:"currentHoverId === parentId || parentId === 0"}],key:i,class:_vm.generateMenuClass(id, parentId),attrs:{"link":"","to":link},on:{"click":function($event){return _vm.onItemClick(id, isParent, _vm.currentHoverId, parentId)}}},[_c('v-list-item-icon',{class:isParent || parentId === 0 || _vm.isMini ? 'mr-4' : 'mr-3 pl-5'},[(_vm.isMini)?_c('v-tooltip',{staticClass:"pa-0 ma-0",attrs:{"bottom":"","color":"kDartessNightBlue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0 ma-0 pb-3",attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"35","color":"kDartessWhite"}},[_vm._v(_vm._s(icon))])],1)]}}],null,true)},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('p',{staticClass:"pa-0 ma-0 text-body-1"},[_vm._v(_vm._s(title))])])],1):_c('v-icon',{attrs:{"size":isParent || parentId === 0 ? 35 : 20,"color":"kDartessWhite"}},[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',{class:isParent || parentId === 0 ? '' : 'mr-0'},[_c('v-list-item-title',{staticClass:"text-body-1 text-wrap kDartessWhite--text font-weight-regular",staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(title)+" ")])],1),(isParent)?_c('v-list-item-action',[_c('v-icon',{attrs:{"size":"20","color":"white"}},[_vm._v("mdi-dots-vertical")])],1):_vm._e()],1):_vm._e()],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }