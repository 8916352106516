




























































































































import { Component, Vue } from "vue-property-decorator";
import { formValidation } from "@/utils/form-validation-helper";
import { authApiProvider } from "@/providers/auth-api-provider";
import AuthHelper from "../utils/auth-helper";
import LocaleSelect from "@/components/_shared/forms/locale-select.vue";

@Component({
  name: "home-page",
  components: { LocaleSelect },
})
export default class LoginPage extends Vue {
  rules = formValidation.getRules();

  //LOGIN
  email = "";
  password = "";
  passwordVisibility = false;
  loginFormValid = true;

  //RESET PASSWORD
  resetPasswordEmail = "";
  isPasswordResetForm = false;

  formSending = false;

  $refs: {
    loginForm: HTMLFormElement;
    resetPasswordForm: HTMLFormElement;
  };

  async beforeMount() {
    this.$route.meta.title = this.$t("login.list_page_title");
  }

  async validateLoginForm(e) {
    e.preventDefault();
    if (this.$refs.loginForm.validate()) {
      this.formSending = true;
      await AuthHelper.connection({ email: this.email, password: this.password });
    }
    this.formSending = false;
  }

  async validateForgotPasswordForm(e) {
    e.preventDefault();
    if (this.$refs.resetPasswordForm.validate()) {
      this.formSending = true;
      await authApiProvider.forgotPassword({ email: this.resetPasswordEmail });
    }
    this.formSending = false;
  }
}
