




















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "app-icon-text-button" })
export default class AppIconTextButton extends Vue {
  @Prop() text: string;
  @Prop() icon: string;
  @Prop() to: string;
  @Prop({ default: "kDartessNightBlue" }) backgroundColor: string;
  @Prop({ default: "kDartessWhite" }) color: string;
  @Prop({ default: false }) isReverse: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop({ default: null }) click: Function;
}
