import { Attribute, AttributeValues } from "@/interfaces/attributes/attribute";

export class AttributeHelper {
  // We check for each item it has to appear or not.
  allActiveItem(attribute: Attribute, data: object): AttributeValues[] {
    const result: AttributeValues[] = [];
    if (attribute.values) {
      attribute.values.forEach((item) => {
        const i = attribute.id.lastIndexOf(".");
        const path = i === -1 ? null : attribute.id.substring(0, i);
        if (this.validRules(item, data, path)) {
          result.push(item);
        }
      });
    }
    return result;
  }

  // We check for each item it has to appear or not.
  validRules(item: AttributeValues | Attribute, data: any, path: string | null = null): boolean {
    let dataMatchWithFilter = true;
    if (item.rules != null) {
      // Pour chaque règles : famille, couleur, région...
      for (const key in item.rules) {
        // Si l'item n'est pas valide on stop la vérification.
        if (!dataMatchWithFilter) {
          return dataMatchWithFilter;
        }
        const rules = item.rules[key];
        const dataKey = path !== null ? path + "." + key : key; // Search for other attributes in the same namespace
        const currentData = data[dataKey];
        for (const operator in rules) {
          const value = rules[operator];
          switch (operator) {
            case ">":
              dataMatchWithFilter = dataMatchWithFilter && currentData > value;
              break;
            case ">=":
              dataMatchWithFilter = dataMatchWithFilter && currentData >= value;
              break;
            case "<":
              dataMatchWithFilter = dataMatchWithFilter && currentData < value;
              break;
            case "<=":
              dataMatchWithFilter = dataMatchWithFilter && currentData <= value;
              break;
            case "=":
              if (Array.isArray(value)) {
                dataMatchWithFilter = dataMatchWithFilter && value.includes(currentData);
              } else {
                dataMatchWithFilter = dataMatchWithFilter && currentData == value;
              }
              break;
            case "!=":
              if (Array.isArray(value)) {
                dataMatchWithFilter = dataMatchWithFilter && !value.includes(currentData);
              } else {
                dataMatchWithFilter = dataMatchWithFilter && currentData != value;
              }
              break;
          }
        }
      }
    }
    return dataMatchWithFilter;
  }

  /**
   * Local hard coded rules for specific attributes not managed by dynamic attribute rules
   * @param attribute
   * @param resource
   * @returns
   */
  validSpecificRules(attribute: Attribute, resource: any) {
    switch (attribute.id) {
      case "isBio":
        return resource.owner && resource.owner.isCertifiedOrganic;
      default:
        return true;
    }
  }
}
