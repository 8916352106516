













import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import { Stock } from "@/interfaces/stock";
import { StockGrid } from "@/utils/grid/stock-grid-helper";
import GridColorChipComponent from "../_shared/grid-components/grid-cells/grid-color-chip-component.vue";

@Component({
  name: "reception-selected-stock-grid",
  components: { AgGridVue, GridIconRowComponent, GridColorChipComponent },
})
export default class ReceptionSelectedStockGrid extends StockGrid {
  @Prop() rowData: Array<Stock>;
  @Prop({ default: false }) readonly: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop({ default: null }) deleteStockCallback: Function;

  async beforeMount() {
    super.beforeMount();
  }

  columns = [
    ...this.getCommonStockGridField(),
    {
      headerName: this.$t("reception.expectedQuantity"),
      field: "expected",
      pinned: "right",
      sortable: false,
      filterFramework: null,
      resizable: false,
      cellClass: "cell-align-right",
    },
    {
      headerName: this.$t("reception.receivedQuantity"),
      field: "received",
      pinned: "right",
      sortable: false,
      filterFramework: null,
      resizable: false,
      cellClass: "cell-align-right",
    },
  ];
}
