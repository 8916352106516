const initialState = () => ({
  apiToken: null,
  apiTokenDecode: null,
  apiTokenImpersonate: null,
});

const state: any = initialState();

const mutations = {
  reset(state: any) {
    const newState: any = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
    localStorage.setItem("API_TOKEN", "");
    localStorage.setItem("API_TOKEN_IMPERSONATE", "");
  },
  setApiToken(state: any, payload: string) {
    localStorage.setItem("API_TOKEN", payload);
    state.apiToken = payload;
  },
  setApiTokenImpersonate(state: any, payload: string) {
    localStorage.setItem("API_TOKEN_IMPERSONATE", payload);
    state.apiTokenImpersonate = payload;
  },
  setDecodeApiToken(state: any, payload: object) {
    state.apiTokenDecode = payload;
  },
};

const getters = {
  isImpersonate: (state: any) => !!state.apiTokenImpersonate,
  getApiToken: (state: any) =>
    state.apiTokenImpersonate ? state.apiTokenImpersonate : state.apiToken,
  getApiTokenImpersonate: (state: any) => state.apiTokenImpersonate,
  getApiTokenDecode: (state: any) => state.apiTokenDecode,
};

const actions = {
  reset({ commit }: any) {
    commit("reset");
  },
  decodeApiToken({ commit }: any) {
    const decode = {};
    commit("setDecodeApiToken", decode);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
