









import { Component, Vue } from "vue-property-decorator";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { companyApiProvider } from "@/providers/company-api-provider";
import CompanyDetailModal from "@/components/company/company-detail-modal.vue";
import CompanyGrid from "@/components/company/company-grid.vue";
import ConfirmModal from "@/components/_shared/modals/confirm-modal.vue";
import { DataSource } from "@/utils/grid/datasource";

@Component({
  name: "company-list-page",
  components: { AppIconTextButton, CompanyGrid, CompanyDetailModal, ConfirmModal },
})
export default class CompanyListPage extends Vue {
  datasource: DataSource;

  $refs: {
    companyGrid: HTMLFormElement;
    companyDetailModal: HTMLFormElement;
  };

  breadcrumbs = [{ text: this.$t("app.title") }, { text: this.$t("company.title") }];

  beforeMount() {
    this.$route.meta.title = this.$t("company.title");
    this.datasource = DataSource.from(companyApiProvider);
  }

  async onCloseDetailModal() {
    this.$refs.companyGrid.onFilterChange();
  }

  async mounted() {
    this.$nextTick(() => {
      // Autocomplete search field on new creation.
      if (this.$route.params.search_filter && this.$refs.companyGrid) {
        this.$refs.companyGrid.$refs.searchComponent.search = this.$route.params.search_filter;
      }
    });
  }
}
