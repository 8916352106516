






















































































import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { ImportGrid } from "@/utils/grid/import-grid-helper";
import ImportGridFooter from "@/components/_shared/import-csv/import-grid-footer.vue";
import GridBoolComponent from "@/components/_shared/grid-components/grid-cells/grid-bool-component.vue";
import ImportGridHeader from "@/components/_shared/import-csv/import-grid-header.vue";
import { Company } from "@/interfaces/company";
import store from "@/stores/store";
import { UserRole } from "@/interfaces/user";
import { formValidation } from "@/utils/form-validation-helper";
import { Segment } from "@/interfaces/segment";
import { Warehouse } from "@/interfaces/warehouse";
import { companyApiProvider } from "@/providers/company-api-provider";

@Component({
  name: "import-contact-csv-page",
  components: {
    ImportGridHeader,
    ImportGridFooter,
    AgGridVue,
    GridBoolComponent,
  },
})
export default class ImportContactCsvPage extends ImportGrid {
  private dataLabel = `${this.$route.params.type}.name`;
  loading = true;
  rules = formValidation.getRules();

  // Select values.
  private userCompany: Company = store.getters["user/getUser"].company;
  private superAdmin = store.getters["user/getUser"].role == UserRole.superadmin;
  private warehouseSelect = store.getters["user/getUser"].warehouses;
  private companySelect: Company[] = [];

  // Import form data.
  private owner: Company | null = null;
  private warehouse: Warehouse | null = null;
  private segment: Segment | null = null;

  $refs: {
    importContactForm: HTMLFormElement;
  };

  async beforeMount() {
    if (this.superAdmin) {
      this.companySelect = await companyApiProvider.getCompanies();
    }
    // Default values if only one choice exist
    if (this.userCompany.segments.length === 1) {
      this.segment = this.userCompany.segments[0];
    }
    if (this.warehouseSelect.length === 1) {
      this.warehouse = this.warehouseSelect[0];
    }

    await super.beforeMount();
  }

  async onValidate() {
    if (this.$refs.importContactForm.validate()) {
      const formData = new FormData();
      if (this.$route.params.type) formData.append("type", this.$route.params.type);
      if (this.owner) formData.append("owner", this.owner.id);
      if (this.segment) formData.append("segment", this.segment.id);
      if (this.warehouse) formData.append("warehouse", this.warehouse.id);
      await super.onValidate(formData);
    }
  }

  onClientChange() {
    this.warehouseSelect = this.owner.warehouses;
  }
}
