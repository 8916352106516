import { Warehouse } from "@/interfaces/warehouse";

import { AppScope } from "@/utils/app-scope";
import { i18n } from "@/main";
import { Company } from "@/interfaces/company";

export enum UserRole {
  user = 1,
  admin = 10,
  superadmin = 100,
}

export interface User {
  id: string;
  email: string;
  active: boolean;
  canSendTransmittingOrder: boolean;
  firstname: string;
  lastname: string;
  locale: string;
  role: UserRole | string;
  company: Company | null;
  warehouses: Warehouse[];
  notificationChannels: Array<string>;
  scope: Array<string>;
  children: User[];
}

export class User {
  constructor(data: Partial<User>) {
    Object.assign(this, data);
    this.company = data.company ? new Company(data.company) : new Company();
    this.role = typeof data.role == "string" ? User.getUserRoleFromString(data.role) : this.role;
    this.notificationChannels = data.notificationChannels ? data.notificationChannels : [];
    this.warehouses = data.warehouses ? data.warehouses.map((w: object) => new Warehouse(w)) : [];
    if (data.scope && data.scope.findIndex((v: string) => v == "*") != -1) {
      this.scope = AppScope.values;
    }
    this.children = data.children ? data.children.map((w: object) => new User(w)) : [];
  }

  encode(): User {
    const data: any = Object.assign({}, this);
    data.company = this.company ? this.company.id : null;
    data.role = User.getStringFromUserRole(this.role);
    data.warehouses = Warehouse.listToArrayId(data.warehouses);
    data.children = Warehouse.listToArrayId(data.children);
    return data;
  }

  static getStringFromUserRole(role: UserRole | string): string {
    switch (role) {
      case UserRole.user:
        return "user";
      case UserRole.admin:
        return "admin";
      case UserRole.superadmin:
        return "superadmin";
      default:
        return "";
    }
  }

  static getUserRoleFromString(role: string): UserRole {
    switch (role) {
      case "user":
        return UserRole.user;
      case "admin":
        return UserRole.admin;
      case "superadmin":
        return UserRole.superadmin;
      default:
        throw "Error role is undefined";
    }
  }

  /**
   * Renvoie la liste des roles qui sont inférieures à celui de l'utilisateur.
   */
  getAvailableUserRoleForCreation() {
    const roles = Object.keys(UserRole).filter(
      (r) => !isNaN(parseInt(r)) && parseInt(r) < this.role
    );
    return roles.map((role) => {
      role = User.getStringFromUserRole(parseInt(role));
      return {
        key: User.getUserRoleFromString(role),
        text: i18n.t("user.roles." + role),
      };
    });
  }

  /**
   * Vérifie si l'utilisateur possède un ou des droits.
   * @param module - Un module ou une liste de module à tester.
   */
  hasScope(module: string | string[]) {
    let result = false;
    if (typeof module == "string") {
      result = this.scope.includes("*") || this.scope.includes(module);
      // Si c'est un tableau, on vérifie pour chaque élément de celui-ci si l'un des modules corespond.
    } else if (Array.isArray(module)) {
      module.forEach((module: string) => {
        if (this.scope.includes(module)) {
          result = true;
        }
      });
    }
    return result;
  }
}

export enum NotificationType {
  email = "mail",
  push = "push",
}
