import store from "../stores/store";
import { User } from "@/interfaces/user";

/**
 * If the user is logged in, check if the user has the required role and scope to access the route
 * @param {any} to - The route that the user is trying to access
 * @param {any} from - The route the user is coming from
 * @param {any} next - The next function to be called.
 * @returns A function that takes 3 parameters.
 */
export async function isGranted(to: any, from: any, next: any) {
  const user: User = store.getters["user/getUser"];
  if (user) {
    if (user.role < to.meta.access) {
      return next("/403");
    } else if (to.matched[0].meta.scope) {
      // If the user has only a specific scope on the app
      const hasScope = user.scope.find((scope: any) => scope == to.matched[0].meta.scope);
      if (!hasScope) {
        return next("/403");
      }
    }
  }
}
