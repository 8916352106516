









































































import { Component, Vue } from "vue-property-decorator";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import AddressFormComponent from "@/components/_shared/forms/address-form-component.vue";
import { Reception } from "@/interfaces/reception";
import { receptionApiProvider } from "@/providers/reception-api-provider";
import ReceptionSelectedStockGrid from "@/components/reception/reception-selected-stock-grid.vue";
import ContactSearchComponent from "@/components/_shared/searchs/contact-search-component.vue";
import router from "@/router/router";

@Component({
  name: "reception-list-page",
  components: {
    ReceptionSelectedStockGrid,
    AppIconTextButton,
    AddressFormComponent,
    ContactSearchComponent,
  },
})
export default class ReceptionDetailPage extends Vue {
  reception: Reception | null = null;

  async beforeMount() {
    this.reception = await receptionApiProvider.getReception(this.$route.params.reception_id);
    if (this.reception) {
      const title = this.$t("reception.detail_page_title", { code: this.reception.reference });
      this.$eventHub.$emit("update-title", title);
      this.breadcrumbs.push({ text: title });
    } else {
      await router.push("/404");
    }
  }

  breadcrumbs = [
    { text: this.$t("app.title") },
    { text: this.$t("reception.list_page_title"), href: "/reception/list" },
  ];

  $refs: {
    orderDetailModal: HTMLFormElement;
  };
}
