import { PreferencesList } from "@/interfaces/preference";
import store from "../../stores/store";
import { AttributeGroupType, AttributeValues } from "@/interfaces/attributes/attribute";
import GridListFilterComponent from "../../components/_shared/grid-components/grid-filters/grid-list-filter-component.vue";
import { Grid } from "./grid-helper";
import GridNumericFilterComponent from "../../components/_shared/grid-components/grid-filters/grid-numeric-filter-component.vue";
import GridBooleanFilterComponent from "../../components/_shared/grid-components/grid-filters/grid-boolean-filter-component.vue";
import { UserRole } from "@/interfaces/user";

export abstract class MainProductGrid extends Grid {
  $refs: {
    config: HTMLFormElement;
    productGridFilter: HTMLFormElement;
  };

  defaultPreference = {
    slug: PreferencesList.product,
    columns: [
      "code",
      "label",
      "family.family.uuid",
      "attributes.family",
      "attributes.color",
      "attributes.capacity",
      "attributes.vintage",
      "attributes.pcb",
      "warehouse.name",
    ],
  };

  getCommonProductGridField(): object[] {
    const columns = [
      {
        headerName: this.$t("product.code"),
        field: "code",
        cellClass: "cell-align-left",
        width: 125,
        minWidth: 125,
      },
      {
        headerName: this.$t("product.label"),
        field: "label",
        cellClass: "cell-align-left",
        width: 300,
        minWidth: 300,
      },
      {
        headerName: this.$t("product.attributes.conditionning"),
        field: "attributes.conditionning",
        cellClass: "cell-align-center",
        sortable: false,
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: store.getters["attribute/getAttribute"](
            AttributeGroupType.alcohol,
            "conditionning"
          ).values.map((e: AttributeValues) => ({ key: e.code, value: e.label })),
        },
        valueFormatter: (params: any) => params.data?.attributes.conditionning ?? "-",
      },
      {
        headerName: this.$t("product.attributes.taxation"),
        field: "attributes.taxation",
        cellClass: "cell-align-center",
        sortable: false,
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: store.getters["attribute/getAttribute"](
            AttributeGroupType.alcohol,
            "taxation"
          ).values.map((e: AttributeValues) => ({ key: e.code, value: e.label })),
        },
        valueFormatter: (params: any) => params.data?.attributes.taxation ?? "-",
      },
      {
        headerName: this.$t("product.attributes.governing_rights.title"),
        field: "attributes.governing_rights",
        cellClass: "cell-align-center",
        sortable: false,
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: store.getters["attribute/getAttribute"](
            AttributeGroupType.alcohol,
            "governing_rights"
          ).values.map((e: AttributeValues) => ({ key: e.code, value: e.label })),
        },
        valueFormatter: (params: any) =>
          params.value ? this.$t(`product.attributes.governing_rights.${params.value}`) : "-",
      },
      {
        headerName: this.$t("warehouse.name"),
        field: "warehouse.name",
        flex: 1,
        cellClass: "cell-align-center",
        filterFramework: null,
      },
      {
        headerName: this.$t("family.name"),
        field: "family.family.uuid",
        cellClass: "cell-align-left",
        width: 125,
        minWidth: 125,
        valueFormatter: (params: any) =>
          params.data?.family.family.name ? params.data?.family.family.name : "-",
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: store.getters["productFamily/getAllFamiliesFilterLabeled"],
        },
        sortable: false,
      },
      {
        headerName: this.$t("family.sub_name"),
        field: "family.uuid",
        cellClass: "cell-align-left",
        valueFormatter: (params: any) =>
          params.data?.family.name ? params.data?.family.name : "-",
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: store.getters["productFamily/getAllSubFamiliesFilterLabeled"],
        },
      },
      {
        headerName: this.$t("product.attributes.family"),
        field: "attributes.family",
        sortable: false,
        valueFormatter: (params: any) =>
          params.data?.attributes.family ? params.data?.attributes.family : "-",
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: store.getters["attribute/getAttribute"](
            AttributeGroupType.alcohol,
            "family"
          ).values.map((e: AttributeValues) => ({ key: e.code, value: `${e.code} (${e.label})` })),
        },
        cellClass: "cell-align-center",
      },
      {
        headerName: this.$t("product.attributes.vintage"),
        field: "attributes.vintage",
        cellClass: "cell-align-center",
        sortable: false,
        valueFormatter: (params: any) => {
          return isNaN(params.data?.attributes.vintage) ? "-" : params.data?.attributes.vintage;
        },
        filterFramework: GridNumericFilterComponent,
      },
      {
        headerName: this.$t("product.attributes.color"),
        field: "attributes.color",
        sortable: false,
        resizable: false,
        cellRendererFramework: "GridColorChipComponent",
        cellClass: "cell-align-center",
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: store.getters["attribute/getAttribute"](
            AttributeGroupType.alcohol,
            "color"
          ).values.map((e: AttributeValues) => ({ key: e.code, value: e.label })),
        },
      },
      {
        headerName: this.$t("product.attributes.capacity"),
        field: "attributes.capacity",
        cellClass: "cell-align-right",
        sortable: false,
        width: 75,
        minWidth: 75,
        valueGetter: (params: any) => Number(params.data?.attributes.capacity),
        valueFormatter: (params: any) =>
          isNaN(params.data?.attributes.capacity) ? "-" : params.data?.attributes.capacity,
        filterFramework: GridNumericFilterComponent,
      },
      {
        headerName: this.$t("product.attributes.pcb"),
        field: "attributes.pcb",
        cellClass: "cell-align-right",
        width: 75,
        minWidth: 75,
        sortable: false,
        valueGetter: (params: any) => Number(params.data?.attributes?.pcb),
        valueFormatter: (params: any) =>
          isNaN(params.data?.attributes.pcb) ? "-" : params.data?.attributes.pcb,
        filterFramework: GridNumericFilterComponent,
      },
      {
        headerName: this.$t("product.hasBatch"),
        field: "hasBatch",
        cellClass: "cell-align-center",
        sortable: true,
        filterFramework: GridBooleanFilterComponent,
        cellRendererFramework: "GridBoolComponent",
      },
      {
        headerName: this.$t("product.hasBbd"),
        field: "hasUbd",
        cellClass: "cell-align-center",
        sortable: true,
        filterFramework: GridBooleanFilterComponent,
        cellRendererFramework: "GridBoolComponent",
      },
      {
        headerName: this.$t("product.hasUbd"),
        field: "hasBbd",
        cellClass: "cell-align-center",
        sortable: true,
        filterFramework: GridBooleanFilterComponent,
        cellRendererFramework: "GridBoolComponent",
      },
      {
        headerName: this.$t("product.attributes.isBio"),
        field: "attributes.isBio",
        cellClass: "cell-align-center",
        sortable: true,
        filterFramework: GridBooleanFilterComponent,
        cellRendererFramework: "GridBoolComponent",
      },
      { headerName: this.$t("supplier.name"), field: "supplier.name" },
    ];

    // Add company code on first col if superadmin user
    if (store.getters["user/getUser"].role == UserRole.superadmin) {
      columns.unshift({
        headerName: this.$t("company.code"),
        field: "owner.code",
        cellClass: "cell-align-left",
        width: 125,
        minWidth: 125,
      });
    }

    return columns;
  }
}
