




















import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";

@Component({
  name: "grid-quantity-component",
  components: { AgGridVue },
})
export default class GridQuantityComponent extends Vue {
  max: number;

  id: string;

  value: number | null;

  $refs: {
    numberInput: HTMLFormElement;
  };

  updateValue(newValue: number) {
    if (this.$data.params.data) {
      this.value = newValue;
      if (this.value < 0) {
        this.value = 0;
      }
      if (this.value > this.max) {
        this.value = this.max;
      }
      // Set the data for the row.
      this.$data.params.data.quantity = newValue;
      this.$data.params.api.refreshCells({ rowNodes: [this.$data.params.node], columns: ["cart"] });
    }
  }

  onEnterPressed() {
    this.$eventHub.$emit("add-product-to-cart", this.$data.params.data);
  }

  beforeMount() {
    if (this.$data.params.data) {
      this.value = this.$data.params.value.quantity ? this.$data.params.value.quantity : null;
      this.max = this.$data.params.value.max ? this.$data.params.value.max : 0;
    } else {
      this.value = null;
      this.max = 0;
    }
  }

  mounted() {
    this.$refs.numberInput.$refs.input.addEventListener("keyup", (e: any) => {
      if (e.key === "Enter") {
        this.onEnterPressed();
      } else {
        if (parseInt(e.target.value, 10) > parseInt(e.target.max, 10)) {
          e.target.value = e.target.max;
        }
        this.updateValue(parseInt(e.target.value, 10));
      }
    });
  }
}

export interface GridQuantityComponentParams {
  max: number;
  quantity?: number;
}
