import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const themes = {
  options: {
    customProperties: true,
  },
  themes: {
    light: {
      primary: "#1D2337", // NightBlue
      secondary: "#E73E25", // Orange
      // Graphical chart
      kDartessNightBlue: "#1D2337", // To rename
      kDartessNightBlue75: "#575B69", // To rename
      kDartessNightBlue40: "#A3A6AF", // To rename
      kDartessNightBlue10: "#EAEAEB", // To rename

      kDartessOrange: "#E73E25",
      kDartessOrange75: "#EE6F5C",
      kDartessOrange40: "#F6B3A8",
      kDartessOrange10: "#FDEDEA",

      kDartessLightBlue: "#21B5EA",
      kDartessGreen: "#06AA70",
      kDartessYellow: "#FFD854",
      kDartessLightGrey: "#BFC0C6",
      kDartessWhite: "#FFFFFF",

      // Generated from the graphical chart - MOTRIEUX Thomas
      kDartessRed: "#A20111",
      kDartessDarkGrey: "#3A3D41",

      kDartessDarkGrey20: "#3A3C33",
      kDartessLightGrey25: "#C7C7C8",

      // Wine color
      kWineRed: "#A20111",
      kWinePink: "#FF8DA1",
      kWineBrune: "#5B3C11",
      kWineAmber: "#F0C300",
      kWineBlanche: "#EFE7DB",
      kWineRousse: "#E27228",
      kWineBlonde: "#FAF0BE",
    },
  },
};

const opts = { theme: themes };

export default new Vuetify(opts);
