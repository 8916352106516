import { Family } from "@/interfaces/attributes/family";
import { Company } from "./company";
import { Contact } from "./contact";
import { Stock } from "./stock";
import { Warehouse } from "./warehouse";
import moment from "moment";

export interface Product {
  // Common attribute.
  id: string;
  code: string;
  label: string;
  supplier: Contact | null;
  attribute_group: string;
  family: Family | null;
  warehouse: Warehouse | null | undefined;
  hasUbd: boolean;
  hasBbd: boolean;
  hasBatch: boolean;
  comment: string;
  hidden: boolean;

  eanUnit: string | null;
  eanParcel: string | null;
  eanPallet: string | null;

  // Wine & spirit product.
  attributes: ProductAttribute;

  // Stock page
  stocks?: Stock[];
  hasStock: boolean;

  //Reception & Order page.
  quantity: number;
  preparedQuantity: number;
  expectedQuantity: number;
  owner: Company | null;
}

export class Product {
  constructor(data: Partial<Product> = {}) {
    Object.assign(this, data);
    this.supplier = data.supplier ? new Contact(data.supplier) : null;
    this.attributes = (data.attributes ? data.attributes : {}) as ProductAttribute;
    this.family = data.family ? new Family(data.family) : null;
    this.warehouse = data.warehouse ? new Warehouse(data.warehouse) : null;
    this.stocks = data.stocks ? data.stocks.map((s: any) => new Stock(s)) : [];
    this.owner = data.owner ? new Company(data.owner) : null;
  }

  encode(): Product {
    const data: any = Object.assign({}, this);
    data.supplier = this.supplier?.id;
    data.warehouse = this.warehouse?.id;
    data.family = this.family?.id;
    data.owner = this.owner?.id;
    return data;
  }

  static findById(list: Array<Product>, id: string): Product | null {
    const productInList: Product | undefined = list.find((p) => p.id == id);
    return productInList != undefined ? productInList : null;
  }

  get manageMultipleStock() {
    return this.hasBatch || this.hasBbd || this.hasUbd;
  }

  getTodayStocks() {
    const stocks = [];
    this.stocks.forEach((stock) => {
      const isToday = moment(stock.createdAt).isSame(moment().format("YYYY-MM-DD"));
      if (isToday || stock.createdAt == undefined) {
        stocks.push(stock);
      }
    });
    return stocks;
  }
}

interface ProductAttribute {
  color: string;
  year: string;
  alcoholDegree: string;
  capacity: number;

  family: string;
  regie: string;
  region: string;
  appellation: string;
  nomenclature: string;
  habillage: string;
  quantityByPackage: number;
  weight: number;
  conditionning: string;
  price: number;
  taxCode: string;
  isBio: boolean;
  eanBox: string;
  eanPallet: string;
  eanUnit: string;
  [key: string]: any;
}
