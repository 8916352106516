







































import { Component, Mixins, Prop } from "vue-property-decorator";
import ModalComponent from "../_shared/modals/modal-component.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { Warehouse } from "@/interfaces/warehouse";

@Component({
  name: "warehouse-modal",
  components: { ModalComponent, AppIconTextButton },
})
export default class WarehouseModal extends Mixins(ModalComponent) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() successCallback: Function;
  warehouse: Warehouse | null = null;
  items: Array<Warehouse> = [];

  onValidate() {
    if (this.warehouse != null) {
      this.successCallback(this.warehouse);
      this.toggleModal(false);
    }
  }
}
