import { Warehouse } from "@/interfaces/warehouse";
import { Product } from "./product";
import { Stock } from "@/interfaces/stock";

export interface StockMovement {
  code: string;
  quantity: number;
  batch: string;
  bbd: string;
  ubd: string;
  quality: string;
  reason: string;
  note: string;
  product: Product | null;
  stock: Stock | null;
  warehouse: Warehouse | null;
  direction: string;
}

export class StockMovement {
  constructor(data: Partial<StockMovement>) {
    Object.assign(this, data);
    this.stock = data.stock ? new Stock(data.stock) : null;
    this.product = data.stock && data.stock.product ? new Product(data.stock.product) : null;
    this.warehouse = data.warehouse ? new Warehouse(data.warehouse) : null;
  }
}

export enum StockMovementType {
  output = "output",
  input = "input",
}
