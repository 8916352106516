




















































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Address } from "@/interfaces/address";
import { formValidation } from "@/utils/form-validation-helper";
import store from "@/stores/store";
import { AttributeGroupType } from "@/interfaces/attributes/attribute";

@Component({
  name: "address-form-component",
})
export default class AddressFormComponent extends Vue {
  @Prop() value: Address;

  @Prop({ default: "Adresse" }) title: string;

  // @Prop({default: false}) requireAddress: boolean;
  @Prop({ default: false }) requireContact: boolean;

  @Prop({ default: false }) withExcise: boolean;

  @Prop({ default: "name,address,contact" }) fields: string;

  @Prop() isCompany: boolean;

  @Prop({ default: false }) isEmbedded: boolean; // Form with no title.

  rules = formValidation.getRules();

  countrySelect = store.getters["attribute/getAttribute"](
    AttributeGroupType.contacts,
    "address.country"
  ).values;

  phone = {
    number: "",
    valid: false,
    country: undefined,
  };

  mounted() {
    this.phone.valid = true;
    this.value.isCompany = this.isCompany;
  }

  onInput(formattedNumber: string, { number, valid, country }: PhoneInput) {
    this.phone.number = number.international;
    this.phone.valid = valid;
    this.phone.country = country && country.name;
  }

  validPhoneNumber() {
    return this.phone.valid || this.value.phone == "" ? true : this.$t("form.incorrect_phone_text");
  }

  @Watch("isCompany")
  onIsCompanyChange(newValue) {
    this.value.isCompany = newValue;
  }
}

interface PhoneInput {
  number: any;
  valid: boolean;
  country: any;
}
