


















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Service } from "@/interfaces/service";
import { formValidation } from "@/utils/form-validation-helper";

@Component({
  name: "service-form-component",
})
export default class ServiceFormComponent extends Vue {
  @Prop() services: Array<Service>;
  @Prop({ default: false }) readOnly: boolean;
  @Prop() buttonLabel: string;

  newService = new Service();
  rules = formValidation.getRules();

  addService() {
    this.services.push(this.newService);
    this.newService = new Service();
  }
}
