export enum PreferencesList {
  bill = "bill",
  userClient = "client-user",
  document = "document",
  order = "order",
  shippingOrder = "shipping-order",
  reception = "reception",
  product = "product",
  stock = "stock",
  movementStock = "stock-movement",
  user = "user",
  company = "company",

  carrier = "carrier",
  supplier = "supplier",
  recipient = "recipient",
}

export interface Preference {
  slug: string;
  columns: Array<string>;
}
