import store from "../stores/store";
import { userApiProvider } from "@/providers/user-api-provider";
import { User } from "@/interfaces/user";

export class LanguageHelper {
  private static instance: LanguageHelper;
  private constructor() {}

  public static getInstance(): LanguageHelper {
    if (!LanguageHelper.instance) {
      LanguageHelper.instance = new LanguageHelper();
    }
    return LanguageHelper.instance;
  }

  // fr-FR = language-COUNTRY
  public language(user: User): string | undefined {
    if (user) {
      return user.locale.split("-")[0];
    } else {
      return process.env.VUE_APP_DEFAULT_LANGUAGE;
    }
  }

  // Generate the fr-FR = language-COUNTRY;
  public generateLocale(value: string, user: User): string {
    if (user && user.company) {
      return `${value}-${user.company.address?.country}`;
    } else {
      return `${process.env.VUE_APP_DEFAULT_LANGUAGE}-${process.env.VUE_APP_DEFAULT_COUNTRY}`;
    }
  }

  public async updateLocale(code: string) {
    if (store.getters["user/getUser"]) {
      const user = new User(store.getters["user/getUser"]);
      const locale = this.generateLocale(code, user);
      const response = await userApiProvider.updateLocale(locale, user);
      if (response) {
        user.locale = locale;
        await store.commit("user/setUser", user);
        await store.dispatch("attribute/reset");
        await store.dispatch("productFamily/reset");
        await store.dispatch("attribute/fetchAllAttribute");
        await store.dispatch("productFamily/fetchAllFamilies");
      }
    }
    await store.commit("user/setLocale", code);
    document.location.reload(true);
  }
}
