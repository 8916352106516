












































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { formValidation } from "@/utils/form-validation-helper";
import { ShippingOrder, ShippingOrderStatus } from "@/interfaces/shipping-order";
import ContactSearchComponent from "@/components/_shared/searchs/contact-search-component.vue";
import AddressFormComponent from "@/components/_shared/forms/address-form-component.vue";
import DatePickerFormComponent from "@/components/_shared/forms/date-picker-form-component.vue";
import TimePickerFormComponent from "@/components/_shared/forms/time-picker-form-component.vue";
import { Address, TimeSlot } from "@/interfaces/address";
import { Company } from "@/interfaces/company";
import store from "../../stores/store";
import router from "@/router/router";
import { shippingOrderApiProvider } from "@/providers/shipping-order-api-provider";
import { Contact, ContactType } from "@/interfaces/contact";
import { contactApiProvider } from "@/providers/contact-api-provider";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";
import { UserRole } from "@/interfaces/user";
import { companyApiProvider } from "@/providers/company-api-provider";

@Component({
  name: "create-shipping-order-page",
  components: {
    ContactSearchComponent,
    AddressFormComponent,
    DatePickerFormComponent,
    TimePickerFormComponent,
  },
})
export default class CreateShippingOrderPage extends Vue {
  rules = formValidation.getRules();

  private formSending = false;
  private currentStep = 1;

  private shippingOrder = new ShippingOrder({ statusCode: ShippingOrderStatus.created });
  warehouseSelect = store.getters["user/getUser"].warehouses;

  // Superadmin special field
  isSuperAdmin = store.getters["user/getUser"].role == UserRole.superadmin;
  companySelect: Company[] = [];

  $refs: {
    receptionForm1: HTMLFormElement;
    receptionForm2: HTMLFormElement;
    receptionForm3: HTMLFormElement;
  };

  breadcrumbs = [
    { text: this.$t("app.title") },
    { text: this.$t("shipping-order.list_page_title"), href: "/shipping-order/list" },
    { text: this.$t("shipping-order.create_page_title") },
  ];

  async mounted() {
    this.$route.meta.title = this.$t("shipping-order.create_page_title");

    const cache = store.getters["createShippingOrder/getShippingOrder"];
    if (cache) {
      this.shippingOrder = new ShippingOrder(cache);
    } else {
      // Init default values
      this.autoCompletePickupAddress();

      if (this.shippingOrder.pickupAddress) {
        this.shippingOrder.pickupAddress.timeslots = TimeSlot.default();
      }
      if (this.shippingOrder.deliveryAddress) {
        this.shippingOrder.deliveryAddress.timeslots = TimeSlot.default();
      }
    }

    if (this.isSuperAdmin) {
      this.companySelect = await companyApiProvider.getCompanies();
    } else {
      const user = store.getters["user/getUser"];
      this.onCompanyChange(user.company);
      // Par défaut l'entrepôt sélectionné est celui de l'utilisateur.
      if (this.warehouseSelect.length == 1) {
        this.shippingOrder.warehouse = this.warehouseSelect[0];
      }
    }

    // If we create a new supplier, we go on the 3td steps.
    if (this.$route.query.recipient_id) {
      this.currentStep = 3;
      const response = await contactApiProvider.getContact(
        this.$route.query.recipient_id as string
      );
      if (response) {
        this.shippingOrder.recipient = response;
        this.onDeliveryAdd(response);
      }
    }

    // If we create a new carrier, we go on the 3td steps.
    if (this.$route.query.supplier_id) {
      this.currentStep = 2;
      const response = await contactApiProvider.getContact(this.$route.query.supplier_id as string);
      if (response) {
        this.shippingOrder.sender = response;
        this.onPickupAdd(response);
      }
    }
  }

  /**
   * On company change.
   * @param company
   */
  onCompanyChange(company: Company) {
    this.warehouseSelect = company.warehouses;
    this.shippingOrder.owner = company;
  }

  onPickupAdd(sender: Contact | Company) {
    if (sender) {
      this.shippingOrder.pickupAddress = new Address(Object.assign({}, sender.address));
      this.shippingOrder.pickupAddress.timeslots = TimeSlot.default();
    }
  }

  onDeliveryAdd(recipient: Contact) {
    if (recipient) {
      this.shippingOrder.deliveryAddress = new Address(Object.assign({}, recipient.address));
      this.shippingOrder.deliveryAddress.timeslots = TimeSlot.default();
    }
  }

  async stepOneValidate() {
    if (this.$refs.receptionForm1.validate()) {
      await store.commit("createShippingOrder/setShippingOrder", this.shippingOrder);
      this.currentStep = 2;
    }
  }

  async stepTwoValidate() {
    if (this.$refs.receptionForm2.validate()) {
      await store.commit("createShippingOrder/setShippingOrder", this.shippingOrder);
      this.currentStep = 3;
    }
  }

  async validateTransportOrder() {
    if (
      this.$refs.receptionForm1.validate() &&
      this.$refs.receptionForm2.validate() &&
      this.$refs.receptionForm3.validate()
    ) {
      this.formSending = true;
      const response = await shippingOrderApiProvider.createShippingOrder(this.shippingOrder);
      if (response) {
        await store.dispatch("createShippingOrder/reset");
        await router.push(`/shipping-order/list`);
      } else {
        this.formSending = false;
      }
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.warning,
        mainText: this.$t("form-validation.error"),
      });
    }
  }

  autoCompletePickupAddress() {
    const company: Company = store.getters["user/getUser"].company;
    if (company && this.shippingOrder.pickupAddress) {
      this.onPickupAdd(company);
    }
  }

  async onClickNewRecipient() {
    await this.newContact(ContactType.recipient);
  }

  async onClickNewSender() {
    await this.newContact(ContactType.supplier);
  }

  private async newContact(type: ContactType) {
    if (this.shippingOrder.warehouse) {
      await store.commit("createShippingOrder/setShippingOrder", this.shippingOrder);
      await this.$router.replace({
        name: `create-${type}`,
        params: {
          origin: this.$route.path ?? "",
          warehouseId: this.shippingOrder.warehouse.id,
          type: type,
        },
      });
    }
  }
}
