

















































import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import moment from "moment";
import { Reception, ReceptionStatus } from "@/interfaces/reception";
import { Grid } from "@/utils/grid/grid-helper";
import { PreferencesList } from "@/interfaces/preference";
import GridDateFilterComponent from "../_shared/grid-components/grid-filters/grid-date-filter-component.vue";
import CommonSearchComponent from "../_shared/searchs/common-search-component.vue";
import GridListFilterComponent from "../_shared/grid-components/grid-filters/grid-list-filter-component.vue";
import GridRightIconsComponent from "../_shared/grid-components/grid-right-icons-component.vue";
import GridStatusChipComponent from "../_shared/grid-components/grid-cells/grid-status-chip-component.vue";
import store from "../../stores/store";
import router from "@/router/router";
import NewReceptionModal from "./new-reception-modal.vue";

@Component({
  name: "reception-grid",
  components: {
    AgGridVue,
    GridIconRowComponent,
    GridRightIconsComponent,
    CommonSearchComponent,
    GridStatusChipComponent,
    NewReceptionModal,
  },
})
export default class ReceptionGrid extends Grid {
  @Prop() rowData: Array<Reception>;

  $refs: {
    config: HTMLFormElement;
    productGridFilter: HTMLFormElement;
    newReceptionModal: HTMLFormElement;
  };

  async onClickNewReception() {
    if (store.getters["createReception/getCurrentReceptionId"]) {
      this.$refs.newReceptionModal.open();
    } else {
      await router.push("/reception/create");
    }
  }

  async beforeMount() {
    super.beforeMount();
    this.gridOptions.getRowClass = function (params: any) {
      if (params.data && params.data.statusCode == ReceptionStatus.draft) {
        return "kDartessNightBlue40";
      }
    };

    // Add superadmin specific columns.
    if (this.isSuperAdmin) {
      this.columns.push({
        headerName: this.$t("owner.code"),
        field: "owner.code",
      } as any);
      this.defaultPreference.columns.unshift("owner.name");
      this.columns.push({
        headerName: this.$t("owner.name"),
        field: "owner.name",
        width: 150,
        minWidth: 150,
      } as any);
    }
  }

  defaultPreference = {
    slug: PreferencesList.reception,
    columns: [
      "reference",
      "createdAt",
      "expectedAt",
      "supplier.name",
      "expectedQuantity",
      "receivedQuantity",
      "warehouse.name",
      "statusCode",
    ],
  };

  columns = [
    {
      headerName: this.$t("reception.expectedAt"),
      field: "expectedAt",
      filterFramework: null,
      sortable: false,
      filterable: false,
      valueFormatter: function (params: any) {
        if (params.data && params.data.expectedAt) {
          return moment(params.data.expectedAt).format("L");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: this.$t("reception.reference"),
      field: "reference",
      cellClass: "cell-align-left",
      valueFormatter: (params: any) => {
        return params.data?.reference ?? "-";
      },
    },
    {
      headerName: this.$t("supplier.code"),
      field: "supplier.code",
    },
    {
      headerName: this.$t("supplier.name"),
      field: "supplier.name",
      width: 150,
      minWidth: 150,
      valueFormatter: (params: any) => {
        return params.data?.supplier?.name ?? "-";
      },
    },
    {
      headerName: this.$t("reception.expectedQuantity"),
      field: "expectedQuantity",
      filterFramework: null,
      cellClass: "cell-align-right",
      sortable: false,
      filterable: false,
    },
    {
      headerName: this.$t("reception.receivedQuantity"),
      field: "receivedQuantity",
      filterFramework: null,
      cellClass: "cell-align-right",
      sortable: false,
      filterable: false,
    },
    // Ce champ n'existe pas en back office.
    /* {headerName: this.$t('reception.cra'), field: 'cra', cellClass:'cell-align-center'}, */
    {
      headerName: this.$t("form.created_date"),
      field: "createdAt",
      sort: "desc",
      filterFramework: GridDateFilterComponent,
      valueFormatter: function (params: any) {
        if (params.data && params.data.createdAt) {
          return moment(params.data.createdAt).format("L");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: this.$t("warehouse.name"),
      field: "warehouse.name",
      flex: 1,
      cellClass: "cell-align-left",
      filterFramework: null,
    },
    {
      headerName: this.$t("form.statusCode"),
      field: "statusCode",
      cellRendererFramework: "GridStatusChipComponent",
      width: 120,
      minWidth: 120,
      sortable: true,
      cellRendererParams: { type: "reception" },
      filterFramework: GridListFilterComponent,
      filterParams: {
        values: Object.values(ReceptionStatus).map((e: ReceptionStatus) => {
          return { key: e, value: Reception.stringFromStatus(e) };
        }),
      },
    },
    {
      cellRendererFramework: "GridIconRowComponent",
      pinned: "right",
      sortable: false,
      filterFramework: null,
      resizable: false,
      suppressMovable: true,
      minWidth: 110,
      width: 110,
      cellClass: "cell-align-company",
      valueGetter: function (params: any) {
        return {
          id: params.data?.id,
          active: [
            Reception.canDeleteScope(params.data?.statusCode),
            Reception.canModifyScope(params.data?.statusCode),
            Reception.canShowScope(params.data?.statusCode),
          ],
        };
      },
      cellRendererParams: {
        icons: [
          { icon: "mdi-delete", event: "remove-reception", color: "kDartessRed" },
          { icon: "mdi-pen", to: "/reception/modify/?" },
          { icon: "mdi-magnify", to: "/reception/detail/?" },
        ],
      },
    },
    // Product attributes
    //{headerName: this.$t('product.attributes.taxation'), field: 'taxCode', width: 100, minWidth: 50},
  ];
}
