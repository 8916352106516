


















































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import ModalComponent from "../_shared/modals/modal-component.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { Stock } from "@/interfaces/stock";
import { MessageType } from "../_shared/template/app-snackbar.vue";
import { Product } from "@/interfaces/product";

@Component({
  name: "stock-order-modal",
  components: { ModalComponent, AppIconTextButton },
})
export default class StockOrderModal extends Mixins(ModalComponent) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() successCallback: Function;
  @Prop({ default: false }) restrictQuantity: boolean;

  product: Product = new Product();
  requiredQuantity = 0;
  result: Array<StockWithQuantity> = [];
  title = "";
  stockFilter = "";

  onValidate() {
    if (this.getSelected() == this.requiredQuantity) {
      this.result.forEach((value: StockWithQuantity, index: number) => {
        if (value.quantity <= 0) {
          this.result.splice(index, 1);
        }
      });
      this.successCallback(this.result, this.product);
      this.toggleModal(false);
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.information,
        mainText: "Attention : les produits n'ont pas tous été imputés.",
      });
    }
  }

  setValues(product: Product, items: Array<Stock>, requiredQuantity: number) {
    this.product = product;
    this.requiredQuantity = requiredQuantity;
    this.result = [];
    let quantity = 0;
    items.forEach((value: Stock) => {
      let stockQuantity = 0;
      if (value.available > 0) {
        const delta = this.requiredQuantity - (quantity + value.available);
        if (delta >= 0) {
          quantity += stockQuantity = value.available;
        } else {
          quantity += stockQuantity = value.available - Math.abs(delta);
        }
      }
      this.result.push({
        stock: value,
        quantity: stockQuantity,
      });
    });
    this.result.sort((a, b) => a.quantity - b.quantity);
  }

  getMin(index: number) {
    let result = this.requiredQuantity;
    this.result.forEach((value: StockWithQuantity, i: number) => {
      if (index != i) result -= value.stock.available;
    });
    if (result > this.result[index].stock.available) {
      return 0;
    } else {
      return result < 0 ? 0 : result;
    }
  }

  getSelected(): number {
    let result = 0;
    if (this.result.length > 0) {
      this.result.forEach((value: StockWithQuantity) => {
        result += value.quantity;
      });
    }
    return result;
  }

  getMax(s: StockWithQuantity) {
    if (this.requiredQuantity - this.getSelected() <= 0) {
      return s.quantity;
    }
    return s.stock.available;
  }

  onAddAll(index: number) {
    const delta =
      this.requiredQuantity -
      (this.getSelected() - this.result[index].quantity + this.result[index].stock.available);
    if (delta >= 0) {
      this.result[index].quantity = this.result[index].stock.available;
    } else {
      this.result[index].quantity = this.result[index].stock.available - Math.abs(delta);
    }
  }
}

export interface StockWithQuantity {
  stock: Stock;
  quantity: number;
}
