import CompanyListPage from "../pages/company/company-list-page.vue";
import CreateCompanyPage from "../pages/company/create-company-page.vue";
import { AttributeGroupType } from "@/interfaces/attributes/attribute";
import CommonImportCsvPage from "../pages/common-import-csv-page.vue";
import CompanyCarrierListPage from "@/pages/company/company-carrier-list-page.vue";

const companyRoutes = [
  {
    path: "/company/list",
    alias: "/company",
    name: "company-list",
    component: CompanyListPage,
    meta: {
      access: 10,
    },
  },
  {
    path: "/company/carriers",
    name: "company-carrier-list",
    component: CompanyCarrierListPage,
    meta: {
      access: 10,
    },
  },
  {
    path: "/company/create",
    name: "create-company",
    component: CreateCompanyPage,
    meta: {
      access: 10,
    },
  },
  {
    path: "/company/import",
    name: "import-companies",
    component: CommonImportCsvPage,
    props: {
      dataLabel: "company.title",
      attributeGroup: AttributeGroupType.company,
    },
    meta: {
      title: "Importer des comptes clients",
      access: 10,
    },
  },
];

export default companyRoutes;
