import Vue from "vue";
import VueRouter from "vue-router";

import appRoutes from "@/router/app-routes";
import orderRoutes from "@/router/order-routes";
import receptionRoutes from "@/router/reception-routes";
import contactRoutes from "@/router/contact-routes";
import stockRoutes from "@/router/stock-routes";
import userRoutes from "@/router/user-routes";
import clientUserRoutes from "@/router/client-user-routes";
import { isLogin } from "@/middleware/isLogin";
import { isGranted } from "@/middleware/isGranted";
import { pageTracking } from "@/middleware/pageTracking";
import shippingOrderRoutes from "@/router/shipping-order-routes";
import companyRoutes from "@/router/company-routes";
import { setIsMenuFromMetadata, setTitleFromMetadata } from "@/middleware/buildRouteMetadata";
import productRoutes from "@/router/product-routes";

const routes = [
  ...appRoutes,
  ...productRoutes,
  ...orderRoutes,
  ...receptionRoutes,
  ...contactRoutes,
  ...stockRoutes,
  ...clientUserRoutes,
  ...userRoutes,
  ...companyRoutes,
  ...shippingOrderRoutes,
];

const router = new VueRouter({ routes: routes, mode: "history" });

router.afterEach(async (to) => {
  Vue.nextTick(() => {
    setTitleFromMetadata(to);
    setIsMenuFromMetadata(to);
  });
});

// We check if the user is connected.
router.beforeEach(async (to, from, next) => {
  await isLogin(to, from, next);
  await isGranted(to, from, next);
  await pageTracking(to, from, next);
});

export default router;
