import { Preference } from "@/interfaces/preference";
import { preferencesApiProvider } from "@/providers/preferences-api-provider";

const initialState = () => ({
  preferences: null,
});

const state: any = initialState();

const mutations = {
  reset(state: any) {
    const newState: any = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setAllPreferences(state: any, payload: Array<Preference>) {
    if (state.preferences == null) state.preferences = {};
    payload.forEach((pref: Preference) => {
      if (pref) state.preferences[pref.slug] = pref;
    });
  },
  setPreference(state: any, pref: Preference) {
    if (state.preferences == null) state.preferences = {};
    if (pref) state.preferences[pref.slug] = pref;
  },
  resetPreference(state: any, slug: string) {
    if (state.preferences[slug]) delete state.preferences[slug];
  },
};

const getters = {
  getPreferenceByName: (state: any) => (name: string) => state.preferences[name],
  getAllAttribute: (state: any) => state.attributes,
};

const actions = {
  reset({ commit }: any) {
    commit("reset");
  },
  async fetchAllPreference({ commit, state }: any) {
    const prefs = await preferencesApiProvider.getAllPreferences();
    commit("setAllPreferences", prefs);
  },
  async fetchPreference({ commit, state }: any, payload: string) {
    const pref = await preferencesApiProvider.getPreferenceByModule(payload);
    commit("setPreference", pref);
  },
  async updatePreference({ commit, state }: any, payload: Preference) {
    const pref = await preferencesApiProvider.updatePreference(payload);
    commit("setPreference", pref);
  },
  async resetPreference({ commit, state }: any, payload: string) {
    await preferencesApiProvider.deletePreference(payload);
    commit("resetPreference", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
