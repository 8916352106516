import { AttributeGroup } from "@/interfaces/attributes/attribute";

export interface Family {
  id: string;
  code: string;
  name: string;
  families: Family[];
  attribute_group: AttributeGroup | null;

  //Parent family.
  family: Family | null;
}

export class Family {
  constructor(data: Partial<Family>) {
    Object.assign(this, data);
    this.attribute_group = data.attribute_group ? new AttributeGroup(data.attribute_group) : null;
    this.families = data.families ? data.families.map((e: object) => new Family(e)) : [];
  }
}
