













import { Component, Vue } from "vue-property-decorator";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { companyApiProvider } from "@/providers/company-api-provider";
import CompanyDetailModal from "@/components/company/company-detail-modal.vue";
import CompanyGrid from "@/components/company/company-grid.vue";
import ConfirmModal from "@/components/_shared/modals/confirm-modal.vue";
import { DataSource } from "@/utils/grid/datasource";
import CompanyCarrierGrid from "@/components/company/company-carrier-grid.vue";
import { QueryBuilder } from "@/providers/filter";
import { ContactType, Contact } from "@/interfaces/contact";
import { contactApiProvider } from "@/providers/contact-api-provider";
import GridRightIconsComponent from "@/components/_shared/grid-components/grid-right-icons-component.vue";

@Component({
  name: "company-list-page",
  components: {
    GridRightIconsComponent,
    CompanyCarrierGrid,
    AppIconTextButton,
    CompanyGrid,
    CompanyDetailModal,
    ConfirmModal,
  },
})
export default class CompanyListPage extends Vue {
  datasource: DataSource;
  carriers: Contact[] = [];

  $refs: {
    companyGrid: HTMLFormElement;
    companyDetailModal: HTMLFormElement;
  };

  breadcrumbs = [{ text: this.$t("app.title") }, { text: this.$t("company.title_carrier") }];

  async beforeMount() {
    this.$route.meta.title = this.$t("company.title");
    this.datasource = DataSource.from(companyApiProvider);

    // Get B2C carriers
    const params = QueryBuilder.whereEquals("type", ContactType.carrier)
      .whereEquals("segment", ["B2C", "null"])
      .build();
    params.append("paginate", "500");

    let result = await contactApiProvider.getAll(params);
    this.carriers = result.data;
  }

  async mounted() {
    this.$nextTick(() => {
      // Autocomplete search field on new creation.
      if (this.$route.params.search_filter && this.$refs.companyGrid) {
        this.$refs.companyGrid.$refs.searchComponent.search = this.$route.params.search_filter;
      }
    });
  }
}
