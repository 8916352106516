
























































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { ShippingOrder } from "@/interfaces/shipping-order";
import AddressFormComponent from "@/components/_shared/forms/address-form-component.vue";
import DatePickerFormComponent from "@/components/_shared/forms/date-picker-form-component.vue";
import TimePickerFormComponent from "@/components/_shared/forms/time-picker-form-component.vue";
import router from "@/router/router";
import { shippingOrderApiProvider } from "@/providers/shipping-order-api-provider";

@Component({
  name: "shipping-order-detail",
  components: { AddressFormComponent, DatePickerFormComponent, TimePickerFormComponent },
})
export default class ShippingOrderDetailPage extends Vue {
  shippingOrder: ShippingOrder | null = null;

  private tabs = 1;

  async beforeMount() {
    const response = await shippingOrderApiProvider.getShippingOrder(
      this.$route.params.shippingOrder_id
    );
    if (response) {
      this.shippingOrder = response;
      this.$eventHub.$emit(
        "update-title",
        this.$t("shipping-order.detail_page_title", { code: this.shippingOrder.reference })
      );
      this.breadcrumbs.push({
        text: this.$t("shipping-order.detail_page_title", { code: this.shippingOrder.reference }),
      });
    } else {
      await router.push("/404");
    }
  }

  breadcrumbs = [
    { text: this.$t("app.title") },
    { text: this.$t("shipping-order.list_page_title"), href: "/shipping-order/list" },
  ];
}
