








































































































































































































import { Component, Vue } from "vue-property-decorator";
import AppExpansionPanelHeader from "@/components/_shared/panels/app-expansion-panel-header.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { formValidation } from "@/utils/form-validation-helper";
import store from "../../stores/store";
import { Warehouse } from "@/interfaces/warehouse";
import { companyApiProvider } from "@/providers/company-api-provider";
import router from "@/router/router";
import { Company } from "@/interfaces/company";
import AddressFormComponent from "@/components/_shared/forms/address-form-component.vue";
import PrestationFormComponent from "@/components/_shared/forms/prestation-form-component.vue";
import { Permissions } from "@/interfaces/permissions";
import { Segment } from "@/interfaces/segment";
import CompanyCarrierComponent from "@/components/_shared/others/company-carrier-component.vue";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";

@Component({
  name: "create-company-page",
  components: {
    AppExpansionPanelHeader,
    AppIconTextButton,
    AddressFormComponent,
    PrestationFormComponent,
    CompanyCarrierComponent,
  },
})
export default class CreateCompanyPage extends Vue {
  rules = formValidation.getRules();

  formSending = false;

  newCompany = new Company();
  warehouseSelect: Warehouse[] = store.getters["user/getUser"].warehouses;
  segmentSelect = Segment.list;
  scopeSelect = store.getters["user/getUser"].scope;

  $refs: {
    companyForm: HTMLFormElement;
  };

  breadcrumbs = [
    { text: this.$t("app.title") },
    { text: this.$t("company.title"), href: "/company/list" },
    { text: this.$t("company.create_page_title") },
  ];

  async beforeMount() {
    this.$route.meta.title = this.$t("company.create_page_title");
    this.newCompany.permissions = new Permissions();
  }

  async validateCreation() {
    if (this.$refs.companyForm.validate()) {
      this.formSending = true;
      const response = await companyApiProvider.createCompany(this.newCompany);
      if (response) {
        await router.push(`/company/list`);
      } else {
        this.formSending = false;
      }
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.warning,
        mainText: this.$t("form-validation.error"),
      });
    }
  }
}
