















































import { Component, Prop, Vue } from "vue-property-decorator";
import { Segment } from "@/interfaces/segment";
import { Contact } from "@/interfaces/contact";
import { Service } from "@/interfaces/service";
import ContactSearchComponent from "../searchs/contact-search-component.vue";
import { formValidation } from "@/utils/form-validation-helper";
import { MessageType } from "../template/app-snackbar.vue";

@Component({
  name: "service-selector-component",
  components: {
    ContactSearchComponent,
  },
})
export default class ServiceSelectorComponent extends Vue {
  @Prop() contacts: Contact[];
  @Prop() type: string;
  @Prop() segment: Segment;

  selectedContact: Contact = null;
  selectedService: Service = null;

  rules = formValidation.getRules();

  $refs: {
    contactServiceForm: HTMLFormElement;
  };

  /**
   * Validate the form.
   * @param e
   */
  addCarrierAndService(e: Event) {
    e.preventDefault();
    if (this.$refs.contactServiceForm.validate()) {
      const data = {
        contact: this.selectedContact,
        service: this.selectedService,
        segment: this.segment,
      };
      this.$emit("input", data);
      // Reset the form after the update.
      this.selectedService = null;
      this.selectedContact = null;
      this.$refs.contactServiceForm.reset();
      this.$forceUpdate();
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.warning,
        mainText: this.$t("form-validation.error"),
      });
    }
  }
}
