import StockListPage from "../pages/stock/stock-list-page.vue";
import StockMovementListPage from "../pages/stock/stock-movement-list-page.vue";
import { AppScope } from "@/utils/app-scope";

const stockRoutes = [
  {
    path: "/stock/list",
    alias: "/stock",
    name: "stock-list",
    component: StockListPage,
    meta: {
      access: 1,
      scope: AppScope.stock,
    },
  },
  {
    path: "/stock/movement",
    name: "stock-movement-list",
    component: StockMovementListPage,
    meta: {
      access: 1,
      scope: AppScope.stockMovement,
    },
  },
];

export default stockRoutes;
