import { ColorChipInfo } from "../grid/grid-helper";
import { ShippingOrder, ShippingOrderStatus } from "@/interfaces/shipping-order";

export abstract class ColorShippingOrderStatus {
  static getColorFromCode(code: ShippingOrderStatus): ColorChipInfo {
    const text = ShippingOrder.stringFromStatus(code);
    switch (code) {
      case ShippingOrderStatus.draft:
        return { color: "kDartessNightBlue75", textColor: "white--text", text };
      case ShippingOrderStatus.created:
        return { color: "kDartessNightBlue", textColor: "white--text", text };
      case ShippingOrderStatus.planified:
        return { color: "kWineAmber", textColor: "white--text", text };
      case ShippingOrderStatus.delivered:
        return { color: "kDartessGreen", textColor: "white--text", text };
      default:
        return { color: "white", textColor: "black--text", text: code };
    }
  }
}
