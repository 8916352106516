




















import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";

@Component({
  name: "grid-icon-row-component",
  components: { AgGridVue },
})
export default class GridIconRowComponent extends Vue {
  icons: Array<object> = [];

  beforeMount() {
    if (this.$data.params.value) {
      this.$data.params.icons.forEach((icon: Params, i: number) => {
        this.icons.push({
          icon: icon.icon,
          to: icon.to != undefined ? icon.to.replace("?", this.$data.params.value.id) : null,
          click: icon.event,
          color: icon.color != undefined ? icon.color : "black",
          active: this.$data.params.value.active[i],
        });
      });
    }
  }

  onClick(event: string) {
    this.$eventHub.$emit(event, this.$data.params.data);
  }
}

interface Params {
  icon: string;
  to: string;
  event: string;
  color: string;
  isActivable: boolean;
}
