

















import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";

@Component({
  name: "grid-icon-row-component",
  components: { AgGridVue },
})
export default class GridTextBtnComponent extends Vue {
  active: boolean;
  text: string;
  to: string;
  event: string;
  data: string;
  contextSearchValue: string;

  beforeMount() {
    const value = this.$data.params.value;
    this.text = value.text;
    this.event = value.event;
    this.to = value.to;
    this.active = value.active;
    if (value.data) this.data = value.data;
    if (value.contextSearchValue) this.contextSearchValue = value.contextSearchValue;
  }

  onClick() {
    let eValue = this.$data.params.data;
    if (this.data) {
      eValue = this.$data.params.data[this.data];
    }
    this.$eventHub.$emit(this.event, eValue);
  }

  onContextMenu() {
    if (this.contextSearchValue) {
      this.$eventHub.$emit("search", this.contextSearchValue);
    }
  }
}
