import { ApiProvider } from "@/providers/api-provider";
import { User } from "@/interfaces/user";
import store from "@/stores/store";

class TrackingApiProvider extends ApiProvider {
  async registerEvent(event: TrackingEvent): Promise<null> {
    // We don't register events for impersonates users (admin)
    const isImpersonate = store.getters["auth/isImpersonate"];
    if (!isImpersonate) {
      // Add userId to event
      const user: User = store.getters["user/getUser"];
      event.userId = user.id;
      return await this.post(`/activity-logs`, event);
    }
  }
}

export interface TrackingEvent {
  type: string;
  pageName?: string;
  userId?: string;
}

export const trackingApiProvider = new TrackingApiProvider();
