









import { Component, Vue } from "vue-property-decorator";

@Component({ name: "not-found-page" })
export default class ForbiddenPage extends Vue {
  beforeMount() {
    this.$eventHub.$emit("toggle-title-divider", false);
  }

  beforeDestroy() {
    this.$eventHub.$emit("toggle-title-divider", true);
  }
}
