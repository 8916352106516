















import { Component, Vue } from "vue-property-decorator";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import ReceptionGrid from "@/components/reception/reception-grid.vue";
import { receptionApiProvider } from "@/providers/reception-api-provider";
import { Reception } from "@/interfaces/reception";
import { DataSource } from "@/utils/grid/datasource";
import ConfirmModal from "@/components/_shared/modals/confirm-modal.vue";
import NewReceptionModal from "@/components/reception/new-reception-modal.vue";
import store from "../../stores/store";

@Component({
  name: "reception-list-page",
  components: { ReceptionGrid, AppIconTextButton, ConfirmModal, NewReceptionModal },
})
export default class OrderListPage extends Vue {
  datasource: DataSource;

  $refs: {
    receptionGrid: HTMLFormElement;
  };

  breadcrumbs = [{ text: this.$t("app.title") }, { text: this.$t("reception.list_page_title") }];

  async beforeMount() {
    this.$route.meta.title = this.$t("reception.list_page_title");
    this.datasource = DataSource.from(receptionApiProvider);
  }

  async removeReception(data: Reception) {
    await receptionApiProvider.deleteReception(data);
    if (data.id == store.getters["createReception/getCurrentReceptionId"]) {
      await store.dispatch("createReception/reset");
    }
    this.$refs.receptionGrid.onFilterChange();
  }

  async mounted() {
    this.$nextTick(() => {
      // Autocomplete search field on new creation.
      if (this.$route.params.search_filter && this.$refs.receptionGrid) {
        this.$refs.receptionGrid.$refs.searchComponent.search = this.$route.params.search_filter;
      }
    });
  }
}
