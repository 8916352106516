import { Reception, ReceptionStatus } from "@/interfaces/reception";
import { ApiProvider, PaginatedResult } from "@/providers/api-provider";
import { ResourceProvider } from "@/interfaces/resource-provider";

class ReceptionApiProvider extends ApiProvider implements ResourceProvider {
  defaults =
    "warehouse,segment,owner,owner.segments,supplier,supplier.address,stocks.warehouse,stocks.product,stocks.product.family,stocks.product.attributes";

  async exportAll(params: URLSearchParams, columnList: Array<string> = []) {
    params.append("output", "csv");

    // Bidouille pour mapper les colonnes du front sur les champs en back.
    if (columnList.indexOf("warehouse.name") != -1)
      columnList[columnList.indexOf("warehouse.name")] = "warehouse.code";

    await this.downloadFile(`/receptions?${params}&columns=${columnList.toString()}`);
  }

  async getAll(params: URLSearchParams): Promise<PaginatedResult<Reception>> {
    params.append("include", "supplier, supplier.address, owner, warehouse");

    const response = await this.get(`/receptions?` + params);
    return new PaginatedResult<Reception>(response, Reception);
  }

  async getAllWithStatus(
    params: URLSearchParams,
    status: ReceptionStatus
  ): Promise<PaginatedResult<Reception>> {
    params.append("include", "stocks");
    params.set("filter[statusCode]", status);
    const response = await this.get(`/orders?` + params);
    return new PaginatedResult<Reception>(response, Reception);
  }

  async getReception(id: string): Promise<Reception | null> {
    const response = await this.get(`/receptions/${id}?include=${this.defaults}`);
    return response ? new Reception(response.data) : null;
  }

  async createReception(reception: Reception, msg: [] | null = null): Promise<Reception | null> {
    const response = await this.post(
      `/receptions?include=${this.defaults}`,
      reception.encode(),
      msg
    );
    return response ? new Reception(response.data) : null;
  }

  async updateReception(reception: Reception, msg: [] | null = null): Promise<Reception | null> {
    const response = await this.put(
      `/receptions/${reception.id}?include=${this.defaults}`,
      reception.encode(),
      msg
    );
    return response ? new Reception(response.data) : null;
  }

  async deleteReception(reception: Reception): Promise<Reception | null> {
    return await this.delete(`/receptions/${reception.id}`);
  }
}

export const receptionApiProvider = new ReceptionApiProvider();
