













import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import { Stock } from "@/interfaces/stock";
import { StockGrid } from "@/utils/grid/stock-grid-helper";
import GridColorChipComponent from "../_shared/grid-components/grid-cells/grid-color-chip-component.vue";

@Component({
  name: "order-selected-stock-grid",
  components: { AgGridVue, GridIconRowComponent, GridColorChipComponent },
})
export default class OrderSelectedStockGrid extends StockGrid {
  @Prop() rowData: Array<Stock>;

  @Prop({ default: false }) readonly: boolean;

  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop({ default: null }) deleteStockCallback: Function;

  async beforeMount() {
    super.beforeMount();
  }

  columns = [
    ...this.getCommonStockGridField(),
    {
      headerName: this.$t("stock.available"),
      field: "availableQuantity",
      valueFormatter: (params: any) =>
        params.data?.availableQuantity - parseInt(params.data?.ordered),
    },
    {
      headerName: this.$t("stock.ordered"),
      field: "ordered",
      pinned: "right",
      sortable: false,
      filterFramework: null,
      resizable: false,
      cellClass: "cell-align-right",
    },
    {
      headerName: this.$t("stock.prepared"),
      field: "prepared",
      pinned: "right",
      sortable: false,
      filterFramework: null,
      resizable: false,
      cellClass: "cell-align-right",
    },
    !this.readonly
      ? {
          width: 40,
          minWidth: 40,
          maxWidth: 40,
          sortable: false,
          filterFramework: null,
          resizable: false,
          pinned: "right",
          cellRendererFramework: "GridIconRowComponent",
          cellClass: "cell-align-company",
          valueGetter(params: any) {
            return { id: params.data?.id, active: [true] };
          },
          cellRendererParams: {
            icons: [{ icon: "mdi-delete-outline", event: "remove-stock-from-cart" }],
          },
        }
      : {},
  ];
}
