




























import { Component, Vue } from "vue-property-decorator";

export interface ShowSnackbarParams {
  type: MessageType;
  mainText: string;
  secondText: string;
  timeout: number;
}

export enum MessageType {
  error = "error",
  information = "information",
  success = "success",
  warning = "warning",
}

@Component({ name: "app-snackbar" })
export default class AppSnackbar extends Vue {
  timeout = 4000;

  color = "kDartessDarkGrey";

  mainText = "";

  secondText = "";

  visible = false;

  private currentProgress = 100;

  private countdown: any;

  // Type: MessageType.error, MessageType.information, MessageType.success
  showSnackbar(params: ShowSnackbarParams) {
    // Reset the snackbar.
    this.onClose();
    // Set the snackbar params.
    this.mainText = params.mainText;
    this.secondText = params.secondText ? params.secondText : "";
    this.timeout = params.timeout ? params.timeout : this.timeout;
    this.currentProgress = 100;
    this.visible = true;
    // Set background color.
    switch (params.type) {
      case MessageType.error:
        this.color = "kDartessRed";
        break;
      case MessageType.information:
        this.color = "kDartessDarkGrey";
        break;
      case MessageType.success:
        this.color = "kDartessGreen";
        break;
      case MessageType.warning:
        this.color = "orange";
        break;
      default:
        this.color = "kDartessNightBlue75";
        break;
    }
    this.startCountdown();
  }

  startCountdown() {
    let current = this.timeout;
    this.countdown = setInterval(() => {
      current -= 50;
      this.currentProgress = (current / this.timeout) * 100;
      if (this.currentProgress < 1) {
        this.onClose();
      }
    }, 50);
  }

  onClose() {
    clearInterval(this.countdown);
    this.visible = false;
  }
}
