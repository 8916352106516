




















import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmModal from "@/components/_shared/modals/confirm-modal.vue";
import { LanguageHelper } from "@/utils/language-helper";
import store from "@/stores/store";

@Component({
  name: "locale-select",
  components: { ConfirmModal },
})
export default class LocaleSelect extends Vue {
  isOpen = false;

  get country() {
    return store.getters["user/getLocale"] === "fr" ? "fr" : "gb";
  }

  helper = LanguageHelper.getInstance();

  async update(value: string) {
    await this.helper.updateLocale(value);
  }
}
