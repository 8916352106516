

















import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { IFilterComp } from "ag-grid-community";
import { Condition } from "@/providers/filter";

@Component({
  name: "grid-text-filter-component",
  components: { AgGridVue },
})
export default class GridTextFilterComponent extends Vue implements IFilterComp {
  label: string;
  value: string | null;
  debounceTimer: any;

  beforeMount() {
    this.value = null;
    this.label = this.$data.params.colDef.headerName;
  }

  updateFilter() {
    if (this.value == "") {
      this.value = null;
    }
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    if (this.value == null) {
      this.$data.params.filterChangedCallback();
    } else {
      this.debounceTimer = setTimeout(() => this.$data.params.filterChangedCallback(), 500);
    }
  }

  init(params: any) {
    throw new Error("Method not implemented.");
  }

  getGui(): HTMLElement {
    throw new Error("Method not implemented.");
  }

  isFilterActive() {
    return this.value != null && this.value.length > 0;
  }

  doesFilterPass(params: any) {
    return this.$data.params.valueGetter(params.node).includes(this.value);
  }

  getModel() {
    if (this.isFilterActive() && this.value != null) {
      return Condition.contains(this.value);
    } else {
      return null;
    }
  }

  setModel(model: any) {
    this.value = model.filter ?? null;
  }
}
