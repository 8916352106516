
































































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import ModalComponent from "../_shared/modals/modal-component.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { contactApiProvider } from "@/providers/contact-api-provider";
import { Contact, ContactType } from "@/interfaces/contact";
import AddressFormComponent from "@/components/_shared/forms/address-form-component.vue";
import store from "../../stores/store";
import { formValidation } from "@/utils/form-validation-helper";
import { UserRole } from "@/interfaces/user";
import ServiceFormComponent from "@/components/_shared/forms/service-form-component.vue";
import { companyApiProvider } from "@/providers/company-api-provider";
import { Company } from "@/interfaces/company";
import { MessageType } from "../_shared/template/app-snackbar.vue";

@Component({
  name: "contact-detail-modal",
  components: { ModalComponent, AppIconTextButton, AddressFormComponent, ServiceFormComponent },
})
export default class ContactDetailModal extends Mixins(ModalComponent) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() updateCallback: Function;
  contact = new Contact();

  warehouseSelect = store.getters["user/getUser"].warehouses;
  superAdmin = store.getters["user/getUser"].role == UserRole.superadmin;

  rules = formValidation.getRules();

  typeList = ContactType;
  companySelect: Company[] = [];

  typeText = "";

  $refs: {
    contactForm: HTMLFormElement;
    modal: HTMLFormElement;
  };

  async beforeMount() {
    if (this.superAdmin) {
      this.companySelect = await companyApiProvider.getCompanies(true);
    }
  }

  async onOpening(value: Contact) {
    const response = await contactApiProvider.getContact(value.id);
    if (response) {
      this.contact = response;
      this.typeText = this.$t(`${this.contact.type}.name`).toString().toLowerCase();
    }
    this.toggleModal(true);
  }

  /**
   * Get company detail. (segment & permissions).
   * Only use in superadmin mode.
   */
  async onCompanyChange(id: string) {
    this.contact.owner = await companyApiProvider.getCompany(id);
  }

  async validateEdition() {
    if (this.$refs.contactForm.validate()) {
      const response = await contactApiProvider.updateContact(this.contact);
      this.updateCallback(response);
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.warning,
        mainText: this.$t("form-validation.error"),
      });
    }
  }

  mounted() {
    this.$eventHub.$on("open-contact-detail-modal", this.onOpening);
  }

  beforeDestroy() {
    this.$eventHub.$off("open-contact-detail-modal");
  }
}
