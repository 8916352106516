








































































import { Component, Mixins, Prop } from "vue-property-decorator";
import ModalComponent from "../_shared/modals/modal-component.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { Stock } from "@/interfaces/stock";
import { Product } from "@/interfaces/product";
import { formValidation } from "@/utils/form-validation-helper";
import DatePickerFormComponent from "@/components/_shared/forms/date-picker-form-component.vue";
import { Reception } from "@/interfaces/reception";

@Component({
  name: "stock-creation-modal",
  components: { ModalComponent, AppIconTextButton, DatePickerFormComponent },
})
export default class StockCreationModal extends Mixins(ModalComponent) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() successCallback: Function;

  product: Product = new Product();
  newStock: Stock = new Stock({});
  title = "";

  rules = formValidation.getRules();

  $refs: {
    modal: HTMLFormElement;
    stockCreationForm: HTMLFormElement;
  };

  openModal(product: Product, reception: Reception) {
    this.product = product;
    this.newStock = Stock.createForProduct(product, reception.segment);
    this.toggleModal(true);
  }

  onValidate() {
    if (this.$refs.stockCreationForm.validate()) {
      const exists = this.product.stocks?.find(
        (stock) =>
          stock.batch == this.newStock.batch &&
          stock.bbd == this.newStock.bbd &&
          stock.ubd == this.newStock.ubd &&
          stock.quality == this.newStock.quality
      );
      if (exists) {
        this.successCallback(this.product, exists);
      } else {
        this.product.stocks.push(this.newStock);
        this.successCallback(this.product, this.newStock);
      }
      this.toggleModal(false);
    }
  }
}
