































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Column } from "ag-grid-community";
import store from "../../../stores/store";
import { Preference } from "@/interfaces/preference";
import ConfirmModal from "../modals/confirm-modal.vue";

@Component({
  name: "grid-preferences-component",
  components: { ConfirmModal },
})
export default class GridPreferencesComponent extends Vue {
  @Prop() preference: Preference;

  gridApi: any;
  columnApi: any;

  isOpen = false;

  setGridApis(api: any, columnApi: any): void {
    this.gridApi = api;
    this.columnApi = columnApi;
  }

  getColumnDefs(): Array<any> {
    return this.gridApi != undefined ? this.gridApi.getColumnDefs() : [];
  }

  getColumnList(): Array<ColumnState> {
    const result: Array<ColumnState> = [];
    const defs = this.getColumnDefs();
    defs.forEach((def) => {
      if (def.pinned == undefined) {
        const newPrefItem: ColumnState = {
          name: def.headerName,
          field: def.field,
          value: def.hide != true,
        };
        result.push(newPrefItem);
      }
    });
    return result;
  }

  onClickHideColumn(field: string, val: boolean | null) {
    this.columnApi.setColumnVisible(field, val == true);
    this.gridApi.sizeColumnsToFit();
  }

  openConfirmationModal = () => this.$eventHub.$emit("confirm-reset-grid-preferences", true);

  async resetPreference() {
    await store.dispatch("preference/resetPreference", this.preference.slug);
    document.location.reload();
  }

  async updatePreferences() {
    this.isOpen = false;
    const result: Array<string> = [];
    const columns = this.columnApi.getAllDisplayedColumns();
    columns.forEach((column: Column) => {
      if (column.getPinned() == undefined && column.isVisible()) {
        result.push(column.getColId());
      }
    });
    this.preference.columns = result;
    await store.dispatch("preference/updatePreference", this.preference);
  }
}

interface ColumnState {
  name: string;
  field: string;
  value: boolean;
}
