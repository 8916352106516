/**
 * Automatically imports all the modules and exports as a single module object.
 */
const requireLocale = require.context(".", false, /\.json$/);
const locales: any = {};

requireLocale.keys().forEach((filename) => {
  // create the module name from fileName
  let moduleName = filename;
  // Rename the module. ex : ./fr.json => fr
  moduleName = moduleName.replace("./", "");
  moduleName = moduleName.replace(".json", "");
  locales[moduleName] = requireLocale(filename).default || requireLocale(filename);
});

export default locales;
