export interface Warehouse {
  id: string;
  code: string;
  name: string;
}

export class Warehouse {
  constructor(data: Partial<Warehouse> = {}) {
    Object.assign(this, data);
  }

  static listToArrayId(list: Array<Warehouse>): Array<string> {
    const result: Array<string> = [];
    list.forEach((value: Warehouse) => {
      result.push(value.id);
    });
    return result;
  }
}
