























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "modal-component",
})
export default class ModalComponent extends Vue {
  @Prop({ default: "" }) title: string;
  @Prop({ default: 750 }) length: number;
  @Prop({ default: true }) closable: boolean;
  @Prop({ default: "mdi-info" }) titleIcon: string;
  @Prop({ default: false }) scrollable: boolean;
  // Outside modal company on close callback.
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop({ default: null }) onCloseCallback: Function;

  public visible = false;

  $refs: {
    modal: HTMLFormElement;
  };

  toggleModal(value: boolean) {
    if (!this.$refs.modal) {
      this.visible = value;
    } else {
      this.$refs.modal.visible = value;
    }
    if (!value) {
      this.closeModal();
    }
  }

  /** Local modal close callback. */
  public onClose = function (this) {
    // To overload.
  };

  closeModal() {
    this.onClose();
    if (this.onCloseCallback != null) this.onCloseCallback();
  }
}
