/* eslint-disable */

import {i18n} from "@/main";
import {PostalCodeRegex} from "./regex/postalcode-regex";

class FormValidation {
  private static instance: FormValidation;
  private constructor() {}

  public static getInstance(): FormValidation {
    if (!FormValidation.instance) {
      FormValidation.instance = new FormValidation();
    }
    return FormValidation.instance;
  }

  static emailRgx = new RegExp(
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
  );
  static degreeRegex = new RegExp("^\\d{1,2}([.]\\d{1,2}?)?$");

  isRequired(value: string): boolean | string {
    return value != "" && value !== undefined && value !== null
      ? true
      : i18n.t("form-validation.field_required").toString();
  }

  isEmail(value: string): boolean | string {
    return !value || FormValidation.emailRgx.test(value)
      ? true
      : i18n.t("form-validation.field_email").toString();
  }

  isPostalCode(value: string, country: string): boolean | string {
    return !value || PostalCodeRegex.getPostalCodeRegexByCountryCode(country).test(value)
      ? true
      : i18n.t("form-validation.field_postal").toString();
  }

  isSamePassword(value1: string, value2: string): boolean | string {
    return (!value1 && !value2) || value1 === value2
      ? true
      : i18n.t("form-validation.field_same_password").toString();
  }

  isSameEmail(value1: string, value2: string): boolean | string {
    return value1 === value2 ? true : i18n.t("form-validation.field_same_email").toString();
  }

  isGreaterThan(value: number, min: number) {
    return value === undefined || value > min
      ? true
      : i18n.t("form-validation.field_greater_than", { min: min }).toString();
  }

  isInteger(value: number) {
    return value === undefined || Math.round(value) == value
      ? true
      : i18n.t("form-validation.field_integer").toString();
  }

  isDegree(value: string): boolean | string {
    return !value || FormValidation.degreeRegex.test(value)
      ? true
      : i18n.t("form-validation.field_incorrect").toString();
  }

  isExcise(value: string, country: string): boolean | string {
    return !value || (value.length == 13 && value.startsWith(country))
      ? true
      : i18n.t("form-validation.field_excise", { country: country }).toString();
  }

  isCode(value: string): boolean | string {
    const regexp = /^[0-9A-Z-/_]+$/;
    return !value || value.match(regexp) ? true : i18n.t("form-validation.field_code").toString();
  }

  /**
   * Validité d'un numéro de SIRET
   * Le numéro SIRET est composé de 14 chiffres, dont un chiffre de contrôle (le dernier) qui permet de vérifier la validité du numéro de SIRET (SIREN + NIC).
   * La clef de contrôle utilisée pour vérifier l'exactitude d'un identifiant est une clé "1-2", suivant l'algorithme de Luhn.
   * Le principe est le suivant : on multiplie les chiffres de rang impair à partir de la droite par 1, ceux de rang pair par 2.
   * La somme des chiffres obtenus doit être congrue à zéro modulo 10, c'est-à-dire qu'elle doit être multiple de 10.
   * @param value
   */
  isValidSiret(value: string): boolean | string {
    // Un siret fait 14 caractères.
    if (value && value.length == 14 && !isNaN(Number(value))) {
      //On vérifie la validité du numéro.
      let total = 0;
      for (let i = 14; i > 0; i--) {
        let number = Number(value[value.length - i]); // On récupère un chiffre.
        number = i % 2 == 0 ? number * 2 : number; // En fonction de sa position on le multiplie par 1 / 2.
        if (number >= 10) {
          // Si > 10 on ajoute chaque unité séparément.
          total += Number(String(number)[0]);
          total += Number(String(number)[1]);
        } else {
          total += number;
        }
      }
      // Le total doit être un multiple de 10.
      if (total % 10 == 0) {
        return true;
      } else {
        return i18n.t("form-validation.field_siret").toString();
      }
    } else {
      return i18n.t("form-validation.field_incorrect").toString();
    }
  }

  maxLength(value: string, length: number): boolean | string {
    return !value || value.length <= length ? true : i18n.t("form-validation.max_length", { max: length }).toString();
  }

  isAlphaNumeric(value: string): boolean | string {
    return !(/[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g.test(value)) ? true : i18n.t("form-validation.field_alphanumeric").toString();
  }

  getRules(): ValidationRules {
    return {
      isRequired: this.isRequired,
      isEmail: this.isEmail,
      isPostalCode: this.isPostalCode,
      isSamePassword: this.isSamePassword,
      isSameEmail: this.isSameEmail,
      isGreaterThan: this.isGreaterThan,
      isInteger: this.isInteger,
      isDegree: this.isDegree,
      isExcise: this.isExcise,
      isCode: this.isCode,
      isValidSiret: this.isValidSiret,
      maxLength: this.maxLength,
      isAlphaNumeric: this.isAlphaNumeric,
    };
  }
}

interface ValidationRules {
  isRequired: Function;
  isEmail: Function;
  isPostalCode: Function;
  isSamePassword: Function;
  isSameEmail: Function;
  isGreaterThan: Function;
  isInteger: Function;
  isDegree: Function;
  isExcise: Function;
  isCode: Function;
  isValidSiret: Function;
  maxLength: Function;
  isAlphaNumeric: Function;
}

export const formValidation = FormValidation.getInstance();
