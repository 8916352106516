


















































































































































































import { Component, Mixins } from "vue-property-decorator";
import ModalComponent from "@/components/_shared/modals/modal-component.vue";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import ProductCard from "@/components/_shared/cards/product-card.vue";
import { Company } from "@/interfaces/company";
import { formValidation } from "@/utils/form-validation-helper";
import AddressFormComponent from "@/components/_shared/forms/address-form-component.vue";
import { Warehouse } from "@/interfaces/warehouse";
import store from "../../stores/store";
import PrestationFormComponent from "@/components/_shared/forms/prestation-form-component.vue";
import { Segment } from "@/interfaces/segment";
import CompanyCarrierComponent from "../_shared/others/company-carrier-component.vue";
import { companyApiProvider } from "@/providers/company-api-provider";
import { MessageType } from "../_shared/template/app-snackbar.vue";

@Component({
  name: "company-detail-modal",
  components: {
    ModalComponent,
    AppIconTextButton,
    ProductCard,
    AddressFormComponent,
    PrestationFormComponent,
    CompanyCarrierComponent,
  },
})
export default class CompanyDetailModal extends Mixins(ModalComponent) {
  formSending = false;

  company: Company = new Company({});
  warehouseSelect: Warehouse[] = [];
  scopeSelect = [];
  segmentSelect = Segment.list;

  rules = formValidation.getRules();

  $refs: {
    companyForm: HTMLFormElement;
    modal: HTMLFormElement;
  };

  async onOpening(value: Company) {
    const response = await companyApiProvider.getCompany(value.id);
    if (response) {
      this.company = response;
    }
    this.toggleModal(true);

    this.warehouseSelect = store.getters["user/getUser"].warehouses;
    this.scopeSelect = store.getters["user/getUser"].scope;
  }

  async validateEdition() {
    if (this.$refs.companyForm.validate()) {
      this.formSending = true;
      const response = await companyApiProvider.updateCompany(this.company);
      this.formSending = false;
      if (response) {
        this.company = response;
        this.toggleModal(false);
      }
    } else {
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.warning,
        mainText: this.$t("form-validation.error"),
      });
    }
  }

  mounted() {
    this.$eventHub.$on("open-company-detail-modal", this.onOpening);
  }

  beforeDestroy() {
    this.$eventHub.$off("open-company-detail-modal");
  }
}
