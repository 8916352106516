import { ApiProvider } from "@/providers/api-provider";
import { Dashboard } from "@/interfaces/dashboard";

class DashboardApiProvider extends ApiProvider {
  async getDashboardInformations(): Promise<Dashboard | null> {
    const response = await this.get(`/dashboard`);
    return response ? new Dashboard(response.data) : null;
  }
}

export const dashboardApiProvider = new DashboardApiProvider();
