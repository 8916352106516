
















































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Warehouse } from "@/interfaces/warehouse";
import store from "../stores/store";
import AddressFormComponent from "@/components/_shared/forms/address-form-component.vue";
import { NotificationType, User, UserRole } from "@/interfaces/user";
import { formValidation } from "@/utils/form-validation-helper";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";
import { authApiProvider } from "@/providers/auth-api-provider";
import { AttributeGroupType } from "@/interfaces/attributes/attribute";
import { companyApiProvider } from "@/providers/company-api-provider";

@Component({
  name: "params-page",
  components: { AddressFormComponent },
})
export default class ParamsPage extends Vue {
  rules = formValidation.getRules();

  private countrySelect = store.getters["attribute/getAttribute"](
    AttributeGroupType.contacts,
    "address.country"
  ).values;

  private formSending = false;

  private userFormSending = false;

  private isPushNotification = false;

  private isEmailNotification = false;

  private pushValue = NotificationType.push;

  private emailValue = NotificationType.email;

  // private password = '';
  // private passwordConfirmation = '';

  user: User; // & { password?: string; password_confirmation?: string };

  $refs: {
    userForm: HTMLFormElement;
    companyForm: HTMLFormElement;
  };

  async beforeMount() {
    this.user = Object.assign(new User({}), store.getters["user/getUser"]);
    // Set notification switches.
    this.isEmailNotification =
      this.user.notificationChannels.find((type: string) => type == NotificationType.email) != null;
    this.isPushNotification =
      this.user.notificationChannels.find((type: string) => type == NotificationType.push) != null;
  }

  async onChangeNotification(type: string, value: boolean) {
    if (value) {
      this.user.notificationChannels.push(type);
    } else {
      const index = this.user.notificationChannels.findIndex((t: string) => type == t);
      if (index != -1) this.user.notificationChannels.splice(index, 1);
    }
    const response = await authApiProvider.updateUser(new User(this.user), false);
    if (response) {
      await store.commit("user/setNotifications", this.user.notificationChannels);
      this.$eventHub.$emit("show-snackbar", {
        type: MessageType.success,
        mainText: "Votre changement a été pris en compte.",
      });
    }
  }

  async validateUserEdition() {
    if (this.$refs.userForm.validate() && this.user) {
      // Thomas - 09/03/2022 - On empêche de modifier son mot de passe. => process normal = mot de passe oublié
      /* if(this.password && this.passwordConfirmation) {
                this.user.password = this.password;
                this.user.password_confirmation = this.passwordConfirmation;
            } else {
                delete this.user.password;
                delete this.user.password_confirmation;
            } */
      this.userFormSending = true;
      const response = await authApiProvider.updateUser(this.user);
      this.userFormSending = false;
      if (response) {
        await store.dispatch("user/fetchUser");
      }
      this.$refs.userForm.resetValidation();
    }
  }

  async validateCompanyEdition() {
    if (this.$refs.companyForm.validate() && this.user.company) {
      this.formSending = true;
      const response = await companyApiProvider.updateCompany(this.user.company);
      this.formSending = false;
      if (response) {
        await store.dispatch("user/fetchUser");
      }
    }
  }

  get isUser(): boolean {
    return this.user.role == UserRole.user;
  }
}
