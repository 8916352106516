











import { Component, Mixins, Prop } from "vue-property-decorator";
import ModalComponent from "./modal-component.vue";
import AppIconTextButton from "../buttons/app-icon-text-button.vue";
import ProductCard from "../cards/product-card.vue";

@Component({
  name: "grid-list-detail-modal",
  components: { ModalComponent, AppIconTextButton, ProductCard },
})
export default class GridListDetailModal extends Mixins(ModalComponent) {
  @Prop() values: string[];
  @Prop() icon: string;
  @Prop() withIcon: boolean;
}
