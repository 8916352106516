import { ApiProvider } from "@/providers/api-provider";
import { User } from "@/interfaces/user";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";
import { i18n } from "@/main";

class AuthApiProvider extends ApiProvider {
  defaults = "?include=warehouses,company,company.address,company.segments,children";

  async getUser(): Promise<User | null> {
    const response = await this.get(`/auth/user${this.defaults}`);
    return response ? new User(response.data) : null;
  }

  async updateUser(
    user: User & { password?: string; password_confirmation?: string },
    withSuccessMsg = true
  ): Promise<User | null> {
    const msg = withSuccessMsg
      ? [{ type: MessageType.success, text: i18n.t("message.user_updated").toString() }]
      : [];
    const response = await this.put("/auth/user", user.encode(), msg);
    return response ? new User(response.data) : null;
  }

  async connection(body: { email: string; password: string }): Promise<string> {
    const response = await this.post("/auth/token", body, [
      { type: MessageType.error, text: i18n.t("message.invalid_credentials").toString() },
    ]);
    return response ? response.access_token : null;
  }

  async loginAs(userId: string) {
    const response = await this.get(`/auth/login-as/${userId}`, [
      { type: MessageType.error, text: i18n.t("message.invalid_credentials").toString() },
    ]);
    return response ? response.access_token : null;
  }

  async setPassword(body: {
    password: string;
    password_confirmation: string;
    email: string | (string | null)[];
    token: string | (string | null)[];
  }) {
    const response = await this.post("/auth/password/reset", body);
    return response ? response.access_token : null;
  }

  async forgotPassword(body: { email: string }) {
    await this.post("/auth/password/email", body, [
      { type: MessageType.success, text: i18n.t("message.reset_email_sent").toString() },
    ]);
  }
}

export interface InitPasswordBody {
  password: string;
  password_confirmation: string;
  email: string;
  token: string | (string | null)[];
}

export const authApiProvider = new AuthApiProvider();
