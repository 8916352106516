import store from "../stores/store";
import { trackingApiProvider } from "@/providers/tracking-api-provider";
import { User } from "@/interfaces/user";

/**
 * Register tracking event when user change page
 * @param {any} to - The route that the user is trying to access.
 * @param {any} from - The route that the user is coming from.
 * @param {any} next - a function that you must call to resolve the hook.
 * @returns The next() function is being returned.
 */
export async function pageTracking(to: any, from: any, next: any) {
  const user: User = store.getters["user/getUser"];
  const exludedPages = ["stats", "login"];

  if (user && !exludedPages.includes(to.name)) {
    trackingApiProvider.registerEvent({
      type: "pageView",
      pageName: to.name,
    });
  }

  return next();
}
