

























































































































































































































import { Component, Mixins } from "vue-property-decorator";
import ModalComponent from "./modal-component.vue";
import AppIconTextButton from "../buttons/app-icon-text-button.vue";
import ProductCard from "../cards/product-card.vue";
import { Product } from "@/interfaces/product";
import { Attribute, AttributeGroupType, AttributeValues } from "@/interfaces/attributes/attribute";
import { attributeApiProvider } from "@/providers/attribute-api-provider";
import store from "../../../stores/store";
import { productApiProvider } from "@/providers/product-api-provider";
import { UserRole } from "@/interfaces/user";

@Component({
  name: "product-detail-modal",
  components: { ModalComponent, AppIconTextButton, ProductCard },
})
export default class ProductDetailModal extends Mixins(ModalComponent) {
  attributes = [] as Attribute[];

  superAdmin = store.getters["user/getUser"].role == UserRole.superadmin;

  product: Product | null = null;

  async onOpening(value: Product) {
    const response = await productApiProvider.getProductById(value.id);
    if (response) {
      this.product = response;
    }
    this.toggleModal(true);
    this.translateCodeToLabel();
    await this.drawProductAttribute();
  }

  async drawProductAttribute() {
    if (this.product) {
      if (this.product.family && this.product.family.family) {
        this.attributes = await attributeApiProvider.getAttributeForFamily(
          this.product.family.family.id
        );
      }
    }
  }

  // We get the label value for each product attribute code. If the label is null, we can't translate the code.
  translateCodeToLabel() {
    if (this.product) {
      for (const key in this.product.attributes) {
        const att: Attribute = store.getters["attribute/getAttribute"](
          AttributeGroupType.alcohol,
          key
        );
        if (att.values) {
          const value = (this.product.attributes as any)[key];
          const label = att.values.find((val: AttributeValues) => val.code == value)?.label;
          if (label) {
            (this.product.attributes as any)[key] = label;
          }
        }
      }
    }
  }

  get getTitle(): string {
    if (this.product) {
      return `Article n°${this.product.code}`;
    }
    return "Article n°";
  }

  mounted() {
    this.$eventHub.$on("open-product-detail-modal", this.onOpening);
  }

  beforeDestroy() {
    this.$eventHub.$off("open-product-detail-modal");
  }
}
