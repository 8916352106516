import store from "../stores/store";

/**
 * If the user is not logged in and the page they are trying to access is not a public page, redirect
 * them to the login page
 * @param {any} to - The route that the user is trying to access.
 * @param {any} from - The route that the user is coming from.
 * @param {any} next - a function that you must call to resolve the hook.
 * @returns The next() function is being returned.
 */
export async function isLogin(to: any, from: any, next: any) {
  const publicPages = ["/login", "/new-user", "/reset-password"];
  const authRequired = !publicPages.includes(to.path);
  const token = await store.getters["auth/getApiToken"];
  const loggedIn = token != "" && token != null;

  if (to.path === "/login" && loggedIn) {
    return next("/dashboard");
  }

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  return next();
}
