import { ApiProvider } from "@/providers/api-provider";
import { Preference } from "@/interfaces/preference";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";
import { i18n } from "@/main";

class PreferencesApiProvider extends ApiProvider {
  async getAllPreferences(): Promise<Array<Preference>> {
    const response = await this.get("/preferences");
    return response ? (response.data as Array<Preference>) : [];
  }

  async getPreferenceByModule(module: string): Promise<Preference | null> {
    const response = await this.get(`/preferences/${module}`);
    return response ? (response.data as Preference) : null;
  }

  /**
   * It updates a preference
   * @param {Preference} pref - Preference - the preference object to be updated
   * @returns A preference object
   */
  async updatePreference(pref: Preference) {
    const response = await this.put(`/preferences/${pref.slug}`, pref, [
      { type: MessageType.success, text: i18n.t("common.preference_saved").toString() },
    ]);
    return response.data as Preference;
  }

  async deletePreference(module: string) {
    await this.delete(`/preferences/${module}`, [
      { type: MessageType.success, text: i18n.t("common.preference_reseted").toString() },
    ]);
  }
}

export const preferencesApiProvider = new PreferencesApiProvider();
