import { Contact } from "@/interfaces/contact";
import { Service } from "@/interfaces/service";
import { Segment } from "@/interfaces/segment";
import { Company } from "@/interfaces/company";

export interface CompanyCarrier {
  id: string;
  segment: Segment;
  carrier: Contact;
  service: Service;
  company: Company;
}

export class CompanyCarrier {
  constructor(data: Partial<CompanyCarrier> = {}) {
    Object.assign(this, data);
    this.carrier = data.carrier ? new Contact(data.carrier) : null;
    this.service = data.service ? new Service(data.service) : null;
    this.segment = data.segment ? new Segment(data.segment) : null;
    this.company = data.company ? new Company(data.company) : null;
  }

  encode(): CompanyCarrier {
    const data: any = Object.assign({}, this);
    data.carrier = data.carrier.id;
    data.segment = data.segment.code;
    data.service = data.service ? data.service.id : null;
    data.company = data.company.id;
    return data;
  }
}
