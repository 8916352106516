/**
 * Automatically imports all the modules and exports as a single module object.
 */
const requireModule = require.context(".", false, /\.store\.ts$/);
const modules: any = {};

requireModule.keys().forEach((filename) => {
  // create the module name from fileName
  let moduleName = filename;
  // Get all '-' indices.
  const indices = [];
  for (let i = 0; i < moduleName.length; i++) {
    if (moduleName[i] === "-") indices.push(i);
  }
  // UpperCase the letter after each '-'
  // Delete the '-'
  const moduleNameArray: Array<string> = moduleName.split("");
  indices.forEach((index: number, i: number) => {
    moduleNameArray[index + 1 - i] = moduleNameArray[index + 1 - i].toUpperCase();
    moduleNameArray.splice(index - i, 1);
  });
  moduleName = moduleNameArray.join("");
  // Remove '.store.ts' & Uppercase the first letter
  moduleName = moduleName.replace(/(\.\/|\.store\.ts)/g, "");
  modules[moduleName] = requireModule(filename).default || requireModule(filename);
});

export default modules;
