

























import { Component, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";

@Component({
  name: "grid-cart-component",
  components: { AgGridVue },
})
export default class GridCartComponent extends Vue {
  quantity: number;
  disabled: boolean;

  beforeMount() {
    this.quantity = this.$data.params.value.quantity;
    this.disabled = this.$data.params.value.disabled;
  }

  onClick() {
    this.$eventHub.$emit("add-product-to-cart", this.$data.params.data);
  }
}

export interface GridCartComponentParams {
  quantity: number;
  disabled: boolean;
}
