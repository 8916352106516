import { ApiProvider, PaginatedResult } from "@/providers/api-provider";
import { Company } from "@/interfaces/company";
import { MessageType } from "@/components/_shared/template/app-snackbar.vue";
import { ResourceProvider } from "@/interfaces/resource-provider";
import { User } from "@/interfaces/user";
import { i18n } from "@/main";
import { Contact } from "@/interfaces/contact";

class CompanyApiProvider extends ApiProvider implements ResourceProvider {
  defaults = "address,warehouses,segments,permissions,carriers";
  defaultsLight = "warehouses,segments"; // Utilisé pour réduire la charge api.

  async exportAll(params: URLSearchParams, columnList: Array<string> = []) {
    params.append("output", "csv");

    // Bidouille pour mapper les colonnes du front sur les champs en back.
    if (columnList.indexOf("address.displayAddress") !== -1) {
      columnList.splice(columnList.indexOf("address.country"), 1, "address.country");
      columnList.splice(columnList.indexOf("address.country"), 0, "address.street");
      columnList.splice(columnList.indexOf("address.country"), 0, "address.postcode");
      columnList.splice(columnList.indexOf("address.country"), 0, "address.city");
    }

    await this.downloadFile(`/companies?${params}&columns=${columnList.toString()}`);
  }

  async getAll(params: URLSearchParams): Promise<PaginatedResult<Company>> {
    params.append("include", this.defaults);
    const response = await this.get(`/companies?` + params);
    return new PaginatedResult<Company>(response, Company);
  }

  async getCompanies(isLight = false): Promise<Array<Company>> {
    const response = await this.get(
      `/companies?sort=code&include=${isLight ? this.defaultsLight : this.defaults}`
    );
    return response ? response.data.map((e: object) => new Company(e)) : [];
  }

  async getCompany(idCompany: string) {
    const response = await this.get(`/companies/${idCompany}?include=${this.defaults}`);
    return response ? new Company(response.data) : null;
  }

  async createCompany(company: Company) {
    const response = await this.post(`/companies?include=${this.defaults}`, company.encode(), [
      { type: MessageType.success, text: i18n.t("message.account_created").toString() },
      { type: MessageType.error, text: i18n.t("message.account_created_error").toString() },
    ]);
    return response ? new Company(response.data) : null;
  }

  async updateCompany(company: Company) {
    const response = await this.put(
      `/companies/${company.id}?include=${this.defaults}`,
      company.encode(),
      [{ type: MessageType.success, text: i18n.t("message.data_updated").toString() }]
    );
    return response ? new Company(response.data) : null;
  }

  async getAdminUserForCompany(companyId: string): Promise<User | null> {
    const response = await this.get(
      `/users?include=warehouses,role&filter[company.uuid]=${companyId}&filter[role.slug]=admin`
    );
    return response.data[0] ? new User(response.data[0]) : null;
  }

  async addPermissionTo(company: Company, resourceType: string, resourceId: string): Promise<null> {
    const response = await this.put(`/companies/${company.id}/${resourceType}/${resourceId}`, {});
    return response.data;
  }

  async removePermissionTo(
    company: Company,
    resourceType: string,
    resourceId: string
  ): Promise<null> {
    const response = await this.delete(`/companies/${company.id}/${resourceType}/${resourceId}`);
    return response.data;
  }

  // Load all carriers affected to company on a specific segment
  async getCarriers(idCompany: string, segment: string) {
    const response = await this.get(`/companies/${idCompany}/${segment}/carriers`);
    return response.map((c) => new Contact(c));
  }

  // Load all recip
}

export const companyApiProvider = new CompanyApiProvider();
