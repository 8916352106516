








































































import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import { Grid } from "@/utils/grid/grid-helper";
import GridListComponent from "../_shared/grid-components/grid-cells/grid-list-component.vue";
import { Warehouse } from "@/interfaces/warehouse";
import { PreferencesList } from "@/interfaces/preference";
import CommonSearchComponent from "../_shared/searchs/common-search-component.vue";
import GridRightIconsComponent from "../_shared/grid-components/grid-right-icons-component.vue";
import { User } from "@/interfaces/user";
import ImportCsvModal from "../_shared/modals/import-csv-modal.vue";

@Component({
  name: "client-user-grid",
  components: {
    AgGridVue,
    GridIconRowComponent,
    ImportCsvModal,
    GridListComponent,
    GridRightIconsComponent,
    CommonSearchComponent,
  },
})
export default class UserGrid extends Grid {
  @Prop() rowData: Array<User>;

  async beforeMount() {
    super.beforeMount();
    this.gridOptions.getRowClass = function (params: any) {
      if (params.data && !params.data.active) {
        return "kDartessRed25";
      }
    };
  }

  defaultPreference = {
    slug: PreferencesList.user,
    columns: ["company.name", "name", "email", "warehouses"],
  };

  columns = [
    { headerName: this.$t("form.account"), field: "company.name" },
    { headerName: this.$t("form.lastname") + " & " + this.$t("form.firstname"), field: "name" },
    { headerName: this.$t("form.email"), field: "email" },
    {
      headerName: this.$tc("form.warehouse", 2),
      field: "warehouses",
      maxWidth: 300,
      minWidth: 300,
      width: 300,
      filterFramework: null,
      sortable: false,
      autoHeight: true,
      cellClass: "cell-align-company",
      cellRendererFramework: "GridListComponent",
      valueFormatter: function (params: any) {
        return {
          values: params.data?.warehouses
            ? params.data?.warehouses.map((e: Warehouse) => e.name)
            : [],
          icon: "mdi-warehouse",
          withIcon: true,
          justify: "start",
          maxShowed: 3,
        };
      },
    },
    {
      cellRendererFramework: "GridIconRowComponent",
      pinned: "right",
      minWidth: 80,
      width: 80,
      maxWidth: 80,
      cellClass: "cell-align-company",
      sortable: false,
      filterFramework: null,
      suppressMovable: true,
      valueGetter: function (params: any) {
        return { active: [params.data?.active, !params.data?.active, params.data?.active] };
      },
      cellRendererParams: {
        icons: [
          { icon: "mdi-pen", event: "open-user-detail-modal" },
          { icon: "mdi-shield-off", event: "activate-user", color: "kDartessRed" },
          { icon: "mdi-shield-check", event: "deactivate-user", color: "kDartessGreen" },
        ],
      },
    },
  ];
}
