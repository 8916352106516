









import { Component, Vue } from "vue-property-decorator";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import StockGrid from "@/components/stock/stock-grid.vue";
import { stockApiProvider } from "@/providers/stock-api-provider";
import ProductDetailModal from "@/components/_shared/modals/product-detail-modal.vue";
import { DataSource } from "@/utils/grid/datasource";

@Component({
  name: "stock-list-page",
  components: { StockGrid, AppIconTextButton, ProductDetailModal },
})
export default class StockListPage extends Vue {
  datasource: DataSource;

  breadcrumbs = [{ text: this.$t("app.title") }, { text: this.$t("stock.list_page_title") }];

  async beforeMount() {
    this.$route.meta.title = this.$t("stock.list_page_title");
    this.datasource = DataSource.from(stockApiProvider);
    /* .withFilters(async () => {
                return [Filter.where('available', Operator.greaterThan, 0)]
       });
    */
  }
}
