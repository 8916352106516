






































































import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { Product } from "@/interfaces/product";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import { MainProductGrid } from "@/utils/grid/main-product-grid";
import GridPreferencesComponent from "../_shared/grid-components/grid-preferences-component.vue";
import CommonSearchComponent from "../_shared/searchs/common-search-component.vue";
import GridColorChipComponent from "../_shared/grid-components/grid-cells/grid-color-chip-component.vue";
import GridRightIconsComponent from "../_shared/grid-components/grid-right-icons-component.vue";
import ImportCsvModal from "../_shared/modals/import-csv-modal.vue";
import GridBoolComponent from "../_shared/grid-components/grid-cells/grid-bool-component.vue";
import GridBooleanFilterComponent from "@/components/_shared/grid-components/grid-filters/grid-boolean-filter-component.vue";

@Component({
  name: "product-grid",
  components: {
    AgGridVue,
    GridIconRowComponent,
    ImportCsvModal,
    GridBoolComponent,
    GridColorChipComponent,
    CommonSearchComponent,
    GridPreferencesComponent,
    GridRightIconsComponent,
  },
})
export default class ProductGrid extends MainProductGrid {
  @Prop() cart: Array<Product>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop({ default: null }) addProductCallback: Function;

  async beforeMount() {
    super.beforeMount();
  }

  $refs: {
    config: HTMLFormElement;
    searchComponent: HTMLFormElement;
    productGridFilter: HTMLFormElement;
    importCsvModal: HTMLFormElement;
  };

  columns = [
    ...this.getCommonProductGridField(),
    {
      key: "hidden",
      headerName: this.$t("product.hidden"),
      field: "hidden",
      cellClass: "cell-align-center",
      sortable: true,
      filterFramework: GridBooleanFilterComponent,
      cellRendererFramework: "GridBoolComponent",
    },
    {
      maxWidth: 110,
      minWidth: 110,
      width: 110,
      cellRendererFramework: "GridIconRowComponent",
      pinned: "right",
      cellClass: "cell-align-company",
      sortable: false,
      filterFramework: null,
      suppressMovable: true,
      resizable: false,
      valueGetter: function (params: any) {
        if (params.data) {
          return {
            id: params.data?.id,
            active: [
              !params.data.hasStock,
              true,
              !params.data?.hidden && !params.data.hasStock,
              params.data?.hidden,
            ],
          };
        }
      },
      cellRendererParams: {
        icons: [
          { icon: "mdi-pen", to: "/product/modify/?" },
          { icon: "mdi-magnify", event: "open-product-detail-modal" },
          { icon: "mdi-eye", event: "hide-product", color: "kDartessGreen" },
          { icon: "mdi-eye-off", event: "show-product", color: "kDartessRed" },
        ],
      },
    },
  ];
}
