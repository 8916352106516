









































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { formValidation } from "@/utils/form-validation-helper";
import store from "@/stores/store";

@Component({
  name: "time-picker-form-component",
})
export default class TimePickerFormComponent extends Vue {
  @Prop() value: string;

  @Prop({ default: "Horaire" }) title: string;

  @Prop({ default: false }) required: boolean;

  @Prop({ default: true }) only30Interval: boolean;

  @Prop({ default: `${process.env.VUE_APP_START_WORKHOUR}:00` }) minHour: string;

  @Prop({ default: `${process.env.VUE_APP_END_WORKHOUR}:00` }) maxHour: string;

  rules = formValidation.getRules();

  private menu = false;

  private locale = store.getters["user/getLocale"];

  private localValue = "";

  private text = "";

  $refs: {
    menu: HTMLFormElement;
  };

  beforeMount() {
    this.localValue = this.value;
    this.onClick();
  }

  @Watch("value")
  onValueChange(value: string) {
    if (value) {
      this.localValue = this.value;
      this.text = this.localValue;
    }
  }

  onClick() {
    this.text = this.localValue;
    this.$emit("input", this.localValue);
    this.menu = false;
  }

  onClear() {
    this.text = null;
    this.$emit("input", null);
  }

  private getAllowedMinutes(value: number) {
    if (this.only30Interval) {
      return value == 0 || value == 30;
    }
    return true;
  }

  resetValue() {
    this.localValue = "";
    this.text = "";
    this.menu = false;
  }
}
