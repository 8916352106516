import NotFoundPage from "../pages/not-found-page.vue";
import LoginPage from "@/pages/login-page.vue";
import SetPasswordPage from "@/pages/set-password-page.vue";
import ParamsPage from "../pages/params-page.vue";
import StatsPage from "../pages/stats-page.vue";
import store from "@/stores/store";
import { UserRole } from "@/interfaces/user";
import DashboardAdminPage from "../pages/dashboard-page.vue";
import PrivacyPolicyPage from "../pages/privacy-policy-page.vue";
import ForbiddenPage from "@/pages/forbidden-page.vue";

function dashboardPage() {
  const role = store.getters["user/getUser"].role;
  return {
    path: "/dashboard",
    name: "dashboard",
    //@TODO: Uncomment for switch the dashboard.
    //company: role == UserRole.superadmin ? DashboardSuperadminPage : DashboardAdminPage,
    component: DashboardAdminPage,
    meta: {
      access: role == UserRole.superadmin ? 100 : 1,
      withoutBreadcrumb: true,
    },
  };
}

const appRoutes = [
  ...(store.getters["user/getUser"]
    ? [
        dashboardPage(),
        {
          path: "*",
          redirect: "/dashboard",
        },
      ]
    : [
        {
          path: "*",
          redirect: "/login",
        },
      ]),
  {
    path: "/404",
    name: "page-not-found",
    component: NotFoundPage,
    meta: {
      withoutBreadcrumb: true,
      title: "Page non trouvé",
      access: 1,
    },
  },
  {
    path: "/403",
    name: "forbidden",
    component: ForbiddenPage,
    meta: {
      withoutBreadcrumb: true,
      title: "Accès non autorisé",
      access: 1,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    meta: {
      withoutBreadcrumb: true,
      withoutMenu: true,
      access: 0,
    },
  },
  {
    path: "/new-user",
    name: "new-user",
    component: SetPasswordPage,
    meta: {
      withoutBreadcrumb: true,
      withoutMenu: true,
      access: 0,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: SetPasswordPage,
    meta: {
      withoutBreadcrumb: true,
      withoutMenu: true,
      access: 0,
    },
  },
  {
    path: "/params",
    name: "params",
    component: ParamsPage,
    meta: {
      withoutBreadcrumb: true,
      access: 1,
    },
  },
  {
    path: "/stats",
    name: "stats",
    component: StatsPage,
    meta: {
      withoutBreadcrumb: true,
      access: 1,
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyPolicyPage,
    meta: {
      withoutBreadcrumb: true,
      access: 0,
    },
  },
];

export default appRoutes;
