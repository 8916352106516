




















import { Component, Vue } from "vue-property-decorator";
import router from "@/router/router";

@Component({
  name: "app-footer",
})
export default class AppFooter extends Vue {
  get companyName() {
    return process.env.VUE_APP_COMPANY;
  }
  get version() {
    return process.env.VUE_APP_VERSION;
  }
  get environment() {
    return process.env.VUE_APP_ENV;
  }

  async privacyPolicy() {
    await router.push("/privacy");
  }
}
