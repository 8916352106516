import { Address } from "@/interfaces/address";
import { Warehouse } from "@/interfaces/warehouse";
import { Company } from "@/interfaces/company";
import { Service } from "./service";
import { Segment } from "./segment";

export interface Contact {
  id: string;
  code: string;
  type: ContactType;
  address: Address;
  segment: Segment | null;
  warehouse: Warehouse | null;
  serviceCode: string;
  services: Array<Service>;
  owner: Company | null;
}

export enum ContactType {
  supplier = "supplier",
  recipient = "recipient",
  carrier = "carrier",
}

export class Contact {
  constructor(data: Partial<Contact> = {}) {
    Object.assign(this, data);
    this.segment = data.segment ? new Segment(data.segment) : null;
    this.address = data.address ? new Address(data.address) : new Address();
    this.warehouse = data.warehouse ? new Warehouse(data.warehouse) : null;
    this.services = data.services ? data.services.map((e: Service) => new Service(e)) : [];
    this.owner = data.owner ? new Company(data.owner) : null;
  }

  encode(): Contact {
    const data: any = { ...this };
    data.warehouse = this.warehouse?.id;
    data.owner = this.owner?.id;
    data.segment = this.segment?.id;
    return data;
  }

  get name() {
    return this.address.company ?? this.address.name ?? this.address.lastname ?? "";
  }

  get text() {
    if (this.code !== this.name && this.name) {
      return `${this.code} - ${this.name}`;
    }
    return `${this.code}`;
  }
}
