import { ApiProvider } from "@/providers/api-provider";
import { Attribute, AttributeGroup } from "@/interfaces/attributes/attribute";

class AttributeApiProvider extends ApiProvider {
  async getAttributeForFamily(id: string): Promise<Array<Attribute>> {
    const response = await this.get(`/product-families/${id}/attributes`);
    return response ? response.data.map((e: object) => new Attribute(e)) : [];
  }

  async getAllAttribute(): Promise<Array<AttributeGroup>> {
    const response = await this.get("/attribute-groups");
    return response ? response.data.map((e: object) => new AttributeGroup(e)) : [];
  }

  async getAttributeById(group: string, id: string): Promise<Attribute> {
    const response = await this.get(`/attribute-groups/${group}/attributes/${id}`);
    return new Attribute(response.data.shift());
  }
}

export const attributeApiProvider = new AttributeApiProvider();
