

























import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import { Grid } from "@/utils/grid/grid-helper";
import GridListComponent from "../_shared/grid-components/grid-cells/grid-list-component.vue";
import { PreferencesList } from "@/interfaces/preference";
import CommonSearchComponent from "../_shared/searchs/common-search-component.vue";
import GridRightIconsComponent from "../_shared/grid-components/grid-right-icons-component.vue";
import { Company } from "@/interfaces/company";
import ImportCsvModal from "../_shared/modals/import-csv-modal.vue";
import { Contact } from "@/interfaces/contact";
import GridServiceSelectComponent from "@/components/_shared/grid-components/grid-cells/grid-service-select-component.vue";
import { CompanyCarrier } from "@/interfaces/company-carrier";
import CompanyCarrierGrid from "@/components/company/company-carrier-grid.vue";

@Component({
  name: "client-company-grid",
  components: {
    CompanyCarrierGrid,
    AgGridVue,
    GridServiceSelectComponent,
    GridIconRowComponent,
    ImportCsvModal,
    GridListComponent,
    GridRightIconsComponent,
    CommonSearchComponent,
  },
})
export default class CompanyGrid extends Grid {
  @Prop() rowData: Array<Company>;
  @Prop() carriers: Array<Contact>;

  async beforeMount() {
    super.beforeMount();
    this.gridOptions.rowHeight = 75;
    this.gridOptions.getRowClass = function (params: any) {
      if (params.data && !params.data.active) {
        return "kDartessRed25";
      }
    };

    this.carriers.forEach((carrier) => {
      this.columns.push({
        headerName: carrier.name,
        field: carrier.id,
        cellClass: "cell-align-left",
        filter: false,
        sortable: false,
        minWidth: 350,
        cellRenderer: "GridServiceSelectComponent",
        cellRendererParams: {
          carriers: this.carriers,
          availableServices: carrier.services,
        },
        valueGetter: function (rowValue) {
          return {
            company: rowValue.data,
            servicesActives: rowValue.data?.carriers.map(
              (carrier: CompanyCarrier) => carrier.service
            ),
          };
        },
      });

      this.defaultPreference.columns.push(carrier.id);
    });
  }

  defaultPreference = {
    slug: PreferencesList.company,
    columns: ["code", "name"],
  };

  columns: any = [
    {
      headerName: this.$t("form.code"),
      field: "code",
      cellClass: "cell-align-left",
      maxWidth: 100,
    },
    { headerName: this.$t("form.company"), field: "name", cellClass: "cell-align-left" },
  ];
}
