



































import { Component, Vue } from "vue-property-decorator";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { User, UserRole } from "@/interfaces/user";
import store from "@/stores/store";
import ClientUserGrid from "@/components/client-user/client-user-grid.vue";
import { userApiProvider } from "@/providers/user-api-provider";
import ClientUserDetailModal from "@/components/client-user/client-user-detail-modal.vue";
import router from "@/router/router";
import ConfirmModal from "@/components/_shared/modals/confirm-modal.vue";
import { Company } from "@/interfaces/company";
import { DataSource } from "@/utils/grid/datasource";
import { Filter } from "@/providers/filter";
import { companyApiProvider } from "@/providers/company-api-provider";

@Component({
  name: "client-user-list-page",
  components: { AppIconTextButton, ClientUserGrid, ClientUserDetailModal, ConfirmModal },
})
export default class ClientUserListPage extends Vue {
  datasource: DataSource;

  $refs: {
    clientUserGrid: HTMLFormElement;
    clientUserDetailModal: HTMLFormElement;
  };

  breadcrumbs: any = [
    { text: this.$t("app.title") },
    { text: this.$t("client-user.list_page_title") },
  ];

  async beforeMount() {
    this.datasource = DataSource.from(userApiProvider).withFilters(async () => {
      if (store.getters["user/getUser"].role == UserRole.superadmin) {
        const company: Company | null = await companyApiProvider.getCompany(
          this.$route.params.companyId
        );
        if (company) {
          return [Filter.whereEquals("company.uuid", company.id)];
        } else {
          await router.push("404");
        }
      }
    });
    if (store.getters["user/getUser"].role == UserRole.superadmin) {
      await this.superadminPageConfiguration();
    } else {
      this.$route.meta.title = this.$t("client-user.my_users");
    }
  }

  /**
   * This methods was called when the page is open by a superadmin user.
   */
  async superadminPageConfiguration() {
    const company: Company | null = await companyApiProvider.getCompany(
      this.$route.params.companyId
    );
    if (company) {
      this.breadcrumbs = [
        { text: this.$t("app.title") },
        { text: this.$t("company.title"), href: "/company/list" },
        { text: `${company.name}` },
      ];
      this.$eventHub.$emit("update-title", `${company.name} - ` + this.$t("client-user.title"));
    } else {
      await router.push("404");
    }
  }

  async deleteUser(user: User) {
    await userApiProvider.deleteUser(user);
    this.$refs.clientUserGrid.onFilterChange();
  }

  async deactivateUser(user: User) {
    user.active = false;
    await userApiProvider.updateUser(user);
    this.$refs.clientUserGrid.onFilterChange();
  }

  async activateUser(user: User) {
    user.active = true;
    await userApiProvider.updateUser(user);
    this.$refs.clientUserGrid.onFilterChange();
  }

  async createUserClick() {
    if (store.getters["user/getUser"].role == UserRole.superadmin) {
      await router.push(`/client-user/${this.$route.params.companyId}/create`);
    } else {
      await router.push("/client-user/create");
    }
  }

  async onCloseDetailModal() {
    this.$refs.clientUserGrid.onFilterChange();
  }

  async mounted() {
    this.$nextTick(() => {
      // Autocomplete search field on new creation.
      if (this.$route.params.search_filter && this.$refs.clientUserGrid) {
        this.$refs.clientUserGrid.$refs.searchComponent.search = this.$route.params.search_filter;
      }
    });
  }
}
