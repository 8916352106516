









import { Component, Prop, Vue } from "vue-property-decorator";
import AppIconTextButton from "@/components/_shared/buttons/app-icon-text-button.vue";
import { contactApiProvider } from "@/providers/contact-api-provider";
import { Contact, ContactType } from "@/interfaces/contact";
import { DataSource } from "@/utils/grid/datasource";
import ContactDetailModal from "@/components/contact/contact-detail-modal.vue";
import ContactGrid from "@/components/contact/contact-grid.vue";
import { Filter } from "@/providers/filter";

@Component({
  name: "contact-list-page",
  components: { ContactGrid, AppIconTextButton, ContactDetailModal },
})
export default class ContactListPage extends Vue {
  datasource: DataSource;
  @Prop() type: ContactType;

  $refs: {
    contactDetailModal: HTMLFormElement;
    contactGrid: HTMLFormElement;
  };

  breadcrumbs = [
    { text: this.$t("app.title") },
    { text: this.$t("contact.title", { type: this.$t(this.type + ".name") }) },
  ];

  async beforeMount() {
    this.$route.meta.title = this.$t("contact.list_page_title", {
      type: this.$t(this.type + ".name"),
    });
    this.datasource = DataSource.from(contactApiProvider).withFilters(async () => {
      return [Filter.whereEquals("type", this.type)];
    });
  }

  onEditingComplete(newValue: Contact) {
    this.$refs.contactDetailModal.toggleModal(false);
    const node = this.$refs.contactGrid.gridOptions.api.getRowNode(newValue.id.toString());
    node.setData(newValue);
    this.$refs.contactGrid.gridOptions.api.redrawRows({ rowNodes: [node] });
  }

  async mounted() {
    this.$nextTick(() => {
      // Autocomplete search field on new creation.
      if (this.$route.params.search_filter && this.$refs.contactGrid) {
        this.$refs.contactGrid.$refs.searchComponent.search = this.$route.params.search_filter;
      }
    });
  }
}
