




































































































import { Component, Vue } from "vue-property-decorator";
import { formValidation } from "@/utils/form-validation-helper";
import router from "@/router/router";
import store from "@/stores/store";
import AuthHelper from "../utils/auth-helper";
import LocaleSelect from "@/components/_shared/forms/locale-select.vue";

@Component({
  name: "new-user-page",
  components: { LocaleSelect },
})
export default class SetPasswordPage extends Vue {
  passwordVisibility = false;

  confirmPasswordVisibility = false;

  title = "";

  subtitle = "";

  formSending = false;

  // LOGIN FORM
  email: string | (string | null)[] = "";

  password = "";

  passwordConfirmation = "";

  rules = formValidation.getRules();

  $refs: {
    newUserForm: HTMLFormElement;
  };

  async beforeMount() {
    await store.dispatch("reset");
    if (this.$route.name === "new-user") {
      this.$route.meta.title = this.$t("login.first_login_title");
      this.$route.meta.subtitle = this.$t("login.first_login_subtitle");
    } else {
      this.$route.meta.title = this.$t("login.update_password_title");
      this.$route.meta.subtitle = this.$t("login.update_password_subtitle");
    }

    if (this.$route.query.token == undefined) {
      await router.push("/login");
    } else if (store.getters["auth/getApiToken"]) {
      await store.dispatch("reset");
    }

    this.email = this.$route.query.email;
    this.title = this.$route.meta.title;
    this.subtitle = this.$route.meta.subtitle;
  }

  async validateForm(e) {
    e.preventDefault();
    if (this.$refs.newUserForm.validate()) {
      this.formSending = true;
      const body = {
        email: this.email,
        password: this.password,
        // eslint-disable-next-line camelcase
        password_confirmation: this.passwordConfirmation,
        token: this.$route.query.token,
      };
      await AuthHelper.initPassword(body);
      this.formSending = false;
    }
  }
}
