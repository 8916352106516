








































































import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { Bill } from "@/interfaces/bill";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import { Grid } from "@/utils/grid/grid-helper";
import GridListComponent from "../_shared/grid-components/grid-cells/grid-list-component.vue";
import { Warehouse } from "@/interfaces/warehouse";
import { PreferencesList } from "@/interfaces/preference";
import CommonSearchComponent from "../_shared/searchs/common-search-component.vue";
import GridRightIconsComponent from "../_shared/grid-components/grid-right-icons-component.vue";
import store from "../../stores/store";
import { User } from "@/interfaces/user";
import ImportCsvModal from "../_shared/modals/import-csv-modal.vue";

@Component({
  name: "client-user-grid",
  components: {
    AgGridVue,
    GridIconRowComponent,
    ImportCsvModal,
    GridListComponent,
    GridRightIconsComponent,
    CommonSearchComponent,
  },
})
export default class ClientUserGrid extends Grid {
  @Prop() rowData: Array<Bill>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() createCallback: Function;

  async beforeMount() {
    super.beforeMount();
    this.gridOptions.getRowClass = function (params: any) {
      if (params.data && !params.data.active) {
        return "kDartessRed25";
      }
    };
  }

  defaultPreference = {
    slug: PreferencesList.userClient,
    columns: ["name", "role", "email", "warehouses"],
  };

  columns = [
    {
      headerName: this.$t("form.lastname") + " & " + this.$t("form.firstname"),
      field: "name",
      cellClass: "cell-align-left",
    },
    {
      headerName: this.$t("form.role"),
      field: "role",
      valueGetter: (params: any) => {
        return params?.data
          ? this.$t("user.roles." + User.getStringFromUserRole(params?.data.role))
          : "-";
      },
    },
    { headerName: this.$t("form.email"), field: "email" },
    {
      headerName: this.$tc("form.warehouse", 2),
      field: "warehouses",
      minWidth: 300,
      width: 300,
      sortable: false,
      autoHeight: true,
      cellClass: "cell-align-company",
      filterFramework: null,
      cellRendererFramework: "GridListComponent",
      valueGetter: function (params: any) {
        return {
          values: params.data?.warehouses
            ? params.data?.warehouses.map((e: Warehouse) => e.name)
            : [],
          icon: "mdi-warehouse",
          withIcon: true,
          justify: "start",
          maxShowed: 3,
        };
      },
    },
    {
      cellRendererFramework: "GridIconRowComponent",
      pinned: "right",
      minWidth: 110,
      width: 110,
      maxWidth: 110,
      sortable: false,
      filterFramework: null,
      suppressMovable: true,
      cellClass: "cell-align-company",
      valueGetter: function (params: any) {
        const user = store.getters["user/getUser"];
        return {
          id: params.data?.company.id,
          active: [
            params.data?.active && params.data?.id !== user.id,
            !params.data?.active && params.data?.id !== user.id,
            params.data?.active && params.data?.id !== user.id,
            params.data?.id !== user.id,
          ],
        };
      },
      cellRendererParams: {
        icons: [
          { icon: "mdi-pen", event: "open-client-user-detail-modal" },
          { icon: "mdi-shield-off", event: "activate-user", color: "kDartessRed" },
          { icon: "mdi-shield-check", event: "deactivate-user", color: "kDartessGreen" },
          { icon: "mdi-account-remove", event: "delete-user", color: "kDartessRed" },
        ],
      },
    },
  ];
}
