

















import { Component, Prop, Vue } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import { ColorAttribute } from "@/interfaces/attributes/color";
import { ColorChipInfo } from "@/utils/grid/grid-helper";

@Component({
  name: "grid-color-chip-component",
  components: { AgGridVue },
})
export default class GridColorChipComponent extends Vue {
  @Prop() color: string;

  colorChipInfo: ColorChipInfo | null = null;

  beforeMount() {
    // If the company is in an ag-grid.
    if (this.$data.params.value) {
      this.colorChipInfo = ColorAttribute.getColorFromCode(this.$data.params.value);
    } else if (this.color) {
      this.colorChipInfo = ColorAttribute.getColorFromCode(this.color);
    }
  }
}
